import { AppBar } from '@mui/material';
import React from 'react';

// TODOs:
// - Create event button (for DM only)

/**
 * Timeline app toolbar
 */
export function Toolbar(): React.ReactElement {
	return (
		<AppBar
			id="timeline-toolbar"
			position="static"
			color="default"
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'row-reverse', // Change if more items are added
				alignItems: 'center',
				padding: '5px',
			}}
		></AppBar>
	);
}
