import { Container, Typography } from '@mui/material';
import React from 'react';

/**
 * Displays a psuedo error message to the user indicating that the selected contact is either
 * not known by their characters, or does not exist.
 */
export function InvalidContactSelection(): React.ReactElement {
	return (
		<Container
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
				overflow: 'auto',
				padding: '15px',
			}}
		>
			<Typography variant="body1" align="left">
				The selected contact was not found. This likely means there is a typo in the url
				search term.
				<br />
				<br />
				If you believe this is an error, contact your GM 🙂
			</Typography>
		</Container>
	);
}
