import { getEnumValues, urlFormat } from '@datapad/utilities';

/**
 * Represents a signed-in player.
 */
export interface Player {
	/**
	 * Unique user ID
	 */
	userName: string;

	/**
	 * {@inheritDoc PlayerKind}
	 */
	playerKind: PlayerKind;

	/**
	 * User's nickname. Will be displayed in place of {@link Player.userName} if specified.
	 */
	nickname?: string;

	/**
	 * Name of the player's default character.
	 * Will be the character they are signed in as initially.
	 *
	 * @defaultValue The first in the list of the player's characters.
	 */
	defaultCharacter?: string;
}

/**
 * Kind of player in the campaign
 */
export enum PlayerKind {
	DungeonMaster = 'DungeonMaster',
	Player = 'Player',
	Guest = 'Guest',
}

/**
 * Pre-populated list of all options for {@link PlayerKind}
 */
export const playerKindOptions = getEnumValues(PlayerKind);

/**
 * Creates a guest {@link Player} object with the provided `userName`.
 */
export function createGuestPlayer(userName: string): Player {
	return {
		userName: userName,
		playerKind: PlayerKind.Guest,
	};
}

/**
 * Gets the player's nickname if one is set, otherwise their userName.
 */
export function getNicknameOrUserName(player: Player): string {
	return player.nickname ?? player.userName;
}

/**
 * Player key for URL search params
 */
const playerUrlSearchKey = 'player';

/**
 * Gets the appropriate player name in URL format (if any) from the current URL search component
 * @param searchParams - URL search string
 */
export function urlFormattedPlayerNameFromUrlSearch(
	searchParams: URLSearchParams,
): string | undefined {
	let playerNameUrlString: string | undefined;
	searchParams.forEach((value, key) => {
		if (key.toLocaleLowerCase() === playerUrlSearchKey) {
			playerNameUrlString = value;
		}
	});
	return playerNameUrlString;
}

/**
 * Builds the URL search parameters for the selected player
 */
export function urlSearchFromPlayer(player?: Player): string {
	return urlSearchFromPlayerName(player?.userName);
}

/**
 * Builds the URL search parameters for the selected player
 */
export function urlSearchFromPlayerName(playerName?: string): string {
	if (playerName === undefined) {
		return '';
	}
	const playerUrlString = urlFormat(playerName);
	return `${playerUrlSearchKey}=${playerUrlString}`;
}
