import type { CanGetCharacter, HasCalendarEvents } from '@datapad/common-props';
import { type CalendarDate, urlSearchFromCharacterName } from '@datapad/interfaces';
import { Timeline } from '@datapad/timeline';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DatapadAppStateContext, ProfileContext } from '../utilities';
import { urlSearchForDate } from './Calendar';
import { AppId } from './Datapad/AppId';

/**
 * {@link RoutedTimeline} input props.
 */
export type RoutedTimelineProps = HasCalendarEvents & CanGetCharacter;

/**
 * Timeline app component with URL routing
 */
export function RoutedTimeline(props: RoutedTimelineProps): React.ReactElement {
	const { events, getCharacter } = props;

	const history = useHistory();

	const { profile, changeSignedInCharacter } = assertNotUndefined(
		React.useContext(ProfileContext),
	);

	const { changeAppSelection, showMenu } = assertNotUndefined(
		React.useContext(DatapadAppStateContext),
	);

	return (
		<Timeline
			profile={profile}
			events={events}
			getCharacter={getCharacter}
			// TODO: dedupe this logic with other uses
			handleCharacterLink={(characterName: string) => {
				const isPlayerCharacter = profile.doesCharacterNameBelongToPlayer(characterName);
				if (isPlayerCharacter) {
					changeSignedInCharacter(characterName);
					changeAppSelection(AppId.Profile);
					showMenu();
				} else {
					changeAppSelection(AppId.Contacts, urlSearchFromCharacterName(characterName));
				}
			}}
			handleCalendarLink={(date: CalendarDate) => {
				const urlPathname = 'calendar';
				const urlSearch = urlSearchForDate(date);
				history.push({
					pathname: urlPathname,
					search: urlSearch,
				});
			}}
		/>
	);
}

export default RoutedTimeline;
