import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setDatapadMenuState, setFluidContainer } from './Actions';
import { AppId } from './AppId';
import { Datapad, defaultAppSelection } from './Components/Datapad';
import type { AppState } from './State';

// TODO: should this logic go in `onComponentDidMount` in datapad instead?

/**
 * Interface that the wrapping Redux store must satisfy (structurally) to use the DatapadApp.
 */
export interface ReducerWithDatapadState {
	datapad: AppState;
}

/**
 * Input props for {@link RoutedDatapad}
 */
export interface Props {
	/**
	 * Name of the signed-in user.
	 */
	userName: string;

	/**
	 * Function for signing the user out of the application.
	 */
	logoutFunction: () => void;

	/**
	 * Function to invoke to refresh all app state
	 */
	onRefreshAppState: () => void;
}

/**
 * Datapad app.
 * Displays various sub-apps, and offers a menu for navigating them.
 */
const ConnectedDatapad = connect((reduxState) => (reduxState as ReducerWithDatapadState).datapad, {
	setDatapadMenuState,
	setFluidContainer,
})(Datapad);

/**
 * {@link ConnectedDatapad} with URL routing
 */
export default function RoutedDatapad(props: Props): React.ReactElement {
	const location = useLocation();
	const appSelection = appIdFromPathname(location.pathname);

	const history = useHistory();

	// Redirect to default app selection so that url reflects the default
	if (appSelection === undefined) {
		history.replace({ pathname: pathnameFromAppId(defaultAppSelection) });
		return <></>;
	}

	return (
		<ConnectedDatapad
			userName={props.userName}
			logoutFunction={props.logoutFunction}
			onRefreshAppState={props.onRefreshAppState}
			appSelection={appSelection}
			onChangeAppSelection={(appId, search) =>
				history.push({ pathname: pathnameFromAppId(appId), search })
			}
		/>
	);
}

/**
 * Converts the pathname component of the URL to the corresponding {@link AppId}
 */
function appIdFromPathname(pathname: string): AppId | undefined {
	const sanitizedLocation = pathname.toLocaleLowerCase();
	switch (sanitizedLocation) {
		case '/profile':
			return AppId.Profile;
		case '/galaxymap':
			return AppId.GalaxyMap;
		case '/contacts':
			return AppId.Contacts;
		case '/calendar':
			return AppId.Calendar;
		case '/timeline':
			return AppId.Timeline;
		case '/players':
			return AppId.Players;
		case '/campaigns':
			return AppId.Campaigns;
		default:
			return undefined;
	}
}

/**
 * Converts an {@link AppId} to a URL pathname
 */
function pathnameFromAppId(appId: AppId): string {
	switch (appId) {
		case AppId.Profile:
			return '/profile';
		case AppId.GalaxyMap:
			return '/galaxymap';
		case AppId.Contacts:
			return '/contacts';
		case AppId.Calendar:
			return '/calendar';
		case AppId.Timeline:
			return '/timeline';
		case AppId.Players:
			return '/players';
		case AppId.Campaigns:
			return '/campaigns';
		default:
			throw new Error(`Unrecognized AppId value: "${appId}"`);
	}
}
