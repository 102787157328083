import { ListFormDialogue } from '@datapad/form-components';
import type { Character } from '@datapad/interfaces';
import { urlFormat } from '@datapad/utilities';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { CharacterInfoCard } from './CharacterDataCard.js';
import type { EditableCharacterProfileBaseProps } from './CommonProps.js';

const divStyle = {
	width: '100%',
	padding: '10px',
};

export class CharacterTitles extends CharacterInfoCard<EditableCharacterProfileBaseProps> {
	/**
	 * {@inheritDoc CharacterInfoCard.headerText}
	 */
	public readonly headerText = 'Titles';

	public constructor(props: EditableCharacterProfileBaseProps) {
		super(props);
	}

	async onSubmitTitleChanges(newTitlesList: string[] | undefined): Promise<void> {
		const characterUpdate: Character = {
			...this.props.character,
			titles: newTitlesList,
		};

		await this.submitEdits(characterUpdate);
	}

	public renderBodyContents(): React.ReactElement {
		const titles = this.props.character.titles;
		if (!titles || titles.length === 0) {
			return this.renderNoTitles();
		}
		return this.renderTitles(titles);
	}

	private renderTitles(titles: string[]): React.ReactElement {
		const titleEntries = titles.map((title) => {
			return (
				<TableRow key={`title-${urlFormat(title)}`}>
					<TableCell align="left">
						<Typography variant="body2">{title}</Typography>
					</TableCell>
				</TableRow>
			);
		});
		return (
			<Table>
				<TableBody>{titleEntries}</TableBody>
			</Table>
		);
	}

	private renderNoTitles(): React.ReactElement {
		return (
			<div style={divStyle}>
				<p>No reported titles</p>
			</div>
		);
	}

	public renderEditForm(): React.ReactElement {
		return (
			<ListFormDialogue
				formTitle="Edit Titles"
				entryLabel="Title"
				initialValues={this.props.character.titles ?? []}
				onSubmit={(newTitlesList) => this.onSubmitTitleChanges(newTitlesList)}
				onCancel={() => this.cancelEdit()}
			/>
		);
	}
}
