import { TextField } from '@mui/material';
import React, { type ChangeEvent } from 'react';
import { DataEntry, type DataEntryProps } from './DataEntry.js';

/**
 * Input props for {@link NumberEntry}
 */
export interface NumberEntryProps extends DataEntryProps<number> {
	/**
	 * See {@link NumberEntry.min}.
	 * undefined =\> {@link Number.NEGATIVE_INFINITY}
	 */
	min?: number;

	/**
	 * See {@link NumberEntry.min}.
	 * undefined =\> {@link Number.POSITIVE_INFINITY}
	 */
	max?: number;

	/**
	 * {@inheritDoc NumberEntry.min}
	 */
	decimals: boolean;

	/**
	 * {@inheritDoc NumberEntry.required}
	 */
	required: boolean;
}

/**
 * DataEntry for numbers.
 */
export class NumberEntry extends DataEntry<number> {
	/**
	 * Minimal allowed value
	 */
	public readonly min: number;

	/**
	 * Maximal allowed value
	 */
	public readonly max: number;

	/**
	 * Whether or not non-integer types are allowed
	 */
	public readonly decimals: boolean;

	/**
	 * Indicates whether or not a value is required to be submitted for this entry.
	 */
	public readonly required: boolean;

	public constructor(props: NumberEntryProps) {
		super(props);

		this.required = props.required;
		this.min = props.min ?? Number.NEGATIVE_INFINITY;
		this.max = props.max ?? Number.POSITIVE_INFINITY;
		this.decimals = props.decimals;
	}

	public errorMessage(value: number | undefined): string | undefined {
		if (this.required && value === undefined) {
			return 'Value is required.';
		}

		// If not required, then undefined is always valid
		if (value === undefined) {
			return undefined;
		}

		if (value < this.min) {
			return `Value must >= ${this.min}`;
		}
		if (value > this.max) {
			return `Value must <= ${this.max}`;
		}
		if (!this.decimals && !Number.isInteger(value)) {
			return 'Value must be an integer';
		}
		return undefined;
	}

	public hasValueChanged(value: number | undefined): boolean {
		return value !== this.initialValue;
	}

	/**
	 * {@inheritDoc DataEntry.renderForm}
	 */
	public renderForm(
		currentValue: number | undefined,
		onChange: (newValue: number | undefined) => void,
	): React.ReactElement {
		const error = !this.isValueValid(currentValue);
		const errorMessage = this.errorMessage(currentValue);
		return (
			<TextField
				defaultValue={currentValue}
				label={this.label}
				id={this.elementId}
				variant="outlined"
				color="primary"
				type="number"
				error={error}
				helperText={errorMessage}
				onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
					onChange(Number.parseFloat(event.target.value));
				}}
				disabled={this.locked}
			/>
		);
	}
}
