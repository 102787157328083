import { type Character, isPlayerCharacter } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import {
	deleteNonPlayerCharacter,
	deletePlayerCharacter,
	insertNewNonPlayerCharacter,
	insertNewPlayerCharacter,
	ProfileContext,
	type SharedNonPlayerCharactersMap,
	type SharedPlayerCharactersMap,
	updateNonPlayerCharacter,
	updatePlayerCharacter,
	useCharacters,
} from '../../../utilities';
import ContactsApp from '../../Contacts';

/**
 * {@link Contacts} input props.
 */
export interface ContactsProps {
	playerCharactersMap: SharedPlayerCharactersMap;
	nonPlayerCharactersMap: SharedNonPlayerCharactersMap;
}

/**
 * Contacts app wrapper that handles translation of Fluid-backed state
 */
export function Contacts(props: ContactsProps): React.ReactElement {
	const { playerCharactersMap, nonPlayerCharactersMap } = props;

	const { profile } = assertNotUndefined(React.useContext(ProfileContext));

	const contacts = useCharacters(playerCharactersMap, nonPlayerCharactersMap);

	/**
	 * Submits an edit of the provided character to the appropriate map
	 */
	function editCharacter(character: Character): void {
		if (!profile.dmMode) {
			throw new Error('Only DM may edit characters.');
		}

		if (isPlayerCharacter(character)) {
			updatePlayerCharacter(character, props.playerCharactersMap);
		} else {
			updateNonPlayerCharacter(character, props.nonPlayerCharactersMap);
		}
	}

	/**
	 * Submits a delete of the provided character to the appropriate map
	 */
	function deleteCharacter(character: Character): void {
		if (!profile.dmMode) {
			throw new Error('Only DM may delete characters.');
		}

		if (isPlayerCharacter(character)) {
			deletePlayerCharacter(character, props.playerCharactersMap);
		} else {
			deleteNonPlayerCharacter(character, props.nonPlayerCharactersMap);
		}
	}

	/**
	 * Submits an insert of the provided character to the appropriate map
	 */
	function insertNewCharacter(character: Character): void {
		if (!profile.dmMode) {
			throw new Error('Only DM may create characters.');
		}

		if (isPlayerCharacter(character)) {
			insertNewPlayerCharacter(character, props.playerCharactersMap);
		} else {
			insertNewNonPlayerCharacter(character, props.nonPlayerCharactersMap);
		}
	}

	return (
		<ContactsApp
			profile={profile}
			contacts={contacts}
			editCharacter={editCharacter}
			deleteCharacter={deleteCharacter}
			createCharacter={insertNewCharacter}
		/>
	);
}
