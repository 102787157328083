import type { CanCreateCampaign, HasCampaigns } from '@datapad/common-props';
import type { Campaign } from '@datapad/interfaces';
import { assertNotUndefined, urlFormat } from '@datapad/utilities';
import { ModalCard } from '@datapad/utility-components';
import { Add } from '@mui/icons-material';
import {
	AppBar,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tooltip,
} from '@mui/material';
import React from 'react';
import { CampaignCreationForm } from './CampaignCreationForm.js';

/**
 * {@link Toolbar} input props.
 */
interface ToolbarProps extends HasCampaigns, CanCreateCampaign {
	/**
	 * The currently selected Campaign.
	 * If undefined, will select the first campaign in the list.
	 */
	selectedCampaign: Campaign | undefined;

	/**
	 * Function to invoke to change campaign selection.
	 * Undefined =\> refresh selection to default
	 */
	onChangeCampaignSelection: (newCampaignSelection: Campaign | undefined) => void;
}

/**
 * Modal dialogue status
 */
enum ModalStatus {
	None,
	Creating,
}

/**
 * Campaigns app toolbar
 */
export function Toolbar(props: ToolbarProps): React.ReactElement {
	const { createCampaign } = props;

	const [modalStatus, setModalStatus] = React.useState<ModalStatus>(ModalStatus.None);

	const maybeModal = (
		<EditingModal
			modalStatus={modalStatus}
			onAddNewCampaign={(campaign) => {
				createCampaign(campaign);
				setModalStatus(ModalStatus.None);
			}}
			onCancel={() => setModalStatus(ModalStatus.None)}
		/>
	);
	return (
		<>
			{maybeModal}
			<AppBar
				id="profile-toolbar-div"
				position="static"
				color="default"
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					paddingTop: '7px',
					paddingBottom: '2px',
				}}
			>
				<div
					id="profile-toolbar-filters"
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<CampaignSelectionDropDown {...props} />
				</div>
				<div
					id="profile-toolbar-buttons"
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Tooltip title="Add new campaign">
						<IconButton
							onClick={() => {
								setModalStatus(ModalStatus.Creating);
							}}
						>
							<Add />
						</IconButton>
					</Tooltip>
				</div>
			</AppBar>
		</>
	);
}

/**
 * Campaign selection drop-down for the toolbar
 */
function CampaignSelectionDropDown(props: ToolbarProps): React.ReactElement {
	const { campaigns, selectedCampaign, onChangeCampaignSelection } = props;

	if (campaigns.length === 0) {
		return <></>;
	}

	const campaignSelectionOptions: React.ReactNode[] = [];
	campaigns.forEach((campaign) => {
		campaignSelectionOptions.push(
			<MenuItem
				key={`campaign-selection-option-${urlFormat(campaign.name)}`}
				value={campaign.name}
			>
				{campaign.name}
			</MenuItem>,
		);
	});

	/**
	 * Gets the matching {@link @datapad/interfaces#Campaign} from the campaigns list based on its
	 * {@link @datapad/interfaces#Campaign.name}.
	 *
	 * Should never fail to find a match since the form the string is coming from is a drop-down.
	 */
	function getCampaignFromName(campaignName: string): Campaign {
		return assertNotUndefined(campaigns.find((campaign) => campaign.name === campaignName));
	}

	return (
		<div
			style={{
				height: '100%',
				minWidth: '170px', // For the little carrot
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				paddingLeft: '5px',
				paddingRight: '5px',
				textAlign: 'left',
			}}
		>
			<FormControl variant="outlined" size="small">
				<InputLabel id="campaign-selection-label">Character Selection</InputLabel>
				<Select
					id="campaign-selection-select"
					labelId="campaign-selection-label"
					label="Campaign Selection"
					value={selectedCampaign?.name}
					onChange={(event) => {
						const campaign = getCampaignFromName(event.target.value as string);
						onChangeCampaignSelection(campaign);
					}}
					variant="outlined"
				>
					{campaignSelectionOptions}
				</Select>
			</FormControl>
		</div>
	);
}

interface EditingModalProps {
	modalStatus: ModalStatus;
	onCancel: () => void;
	onAddNewCampaign: (campaign: Campaign) => void;
}

/**
 * Editing modal dialogue.
 * Will be empty if the specified {@link ModalStatus} is {@link ModalStatus.None}.
 */
function EditingModal(props: EditingModalProps): React.ReactElement {
	let renderedDialogue: React.ReactElement | undefined;

	switch (props.modalStatus) {
		case ModalStatus.None:
			renderedDialogue = undefined;
			break;
		case ModalStatus.Creating:
			renderedDialogue = (
				<CampaignCreationForm onSubmit={props.onAddNewCampaign} onCancel={props.onCancel} />
			);
			break;
		default:
			throw new Error(`Unrecognized ModalStatus value: "${props.modalStatus}".`);
	}

	if (renderedDialogue === undefined) {
		return <></>;
	} else {
		return (
			<ModalCard
				open
				onClose={props.onCancel}
				style={{
					maxHeight: '95%',
				}}
			>
				{renderedDialogue}
			</ModalCard>
		);
	}
}
