import { Link, ListItem } from '@mui/material';
import React from 'react';

/**
 * Datapad menu enumteration of external resources used by the campaign.
 */
export function ResourceLinksList(): React.ReactElement {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				float: 'inline-end',
				padding: '10px',
			}}
		>
			<h4 style={{ margin: '5px' }}>Other Resources</h4>
			<ListItem>
				<Link href="https://sw5e.com/" target="_blank" rel="noopener noreferrer">
					SW5e
				</Link>
			</ListItem>
			<ListItem>
				<Link href="http://swlotf.wikidot.com/" target="_blank" rel="noopener noreferrer">
					Wiki
				</Link>
			</ListItem>
			<ListItem>
				<Link
					href="https://drive.google.com/drive/folders/0B0DnV-NrBZTZbHNZb0QzNXRNdE0?usp=sharing"
					target="_blank"
					rel="noopener noreferrer"
				>
					Drive
				</Link>
			</ListItem>
			<ListItem>
				<Link
					href="https://app.roll20.net/campaigns/details/3130121/starships-and-krayt-dragons-legends-of-the-fallen"
					target="_blank"
					rel="noopener noreferrer"
				>
					Roll20
				</Link>
			</ListItem>
		</div>
	);
}
