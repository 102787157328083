import hash from 'object-hash';

import type { ITokenProvider, ITokenResponse } from '@fluidframework/azure-client';

import { getAzureFluidRelayAccessToken } from '@datapad/backend-interface';

/**
 * Allowed lifetime of a cached token, expressed in seconds.
 */
const tokenLifetimeInSeconds = 3600; // 1 hour

/**
 * Cached token representation.
 */
interface CachedToken {
	/**
	 * Time at which the token is set to expire.
	 * The cached value must not be used beyond this time.
	 */
	expiryTime: number;

	/**
	 * The cached token string.
	 */
	jwt: string;
}

export class NetlifyFunctionTokenProvider implements ITokenProvider {
	private readonly userName: string;

	private tokenCache = new Map<string, CachedToken>();

	public constructor(userName: string) {
		this.userName = userName;
	}

	/**
	 * {@inheritDoc @fluidframework/tinylicious-client#ITokenProvider.fetchOrdererToken}
	 */
	public async fetchOrdererToken(
		tenantId: string,
		documentId?: string,
		refresh?: boolean,
	): Promise<ITokenResponse> {
		let token;
		try {
			token = await this.getToken(tenantId, documentId, refresh ?? false);
		} catch (error) {
			console.error(`Error generating token.`);
			throw error;
		}
		return token;
	}

	/**
	 * {@inheritDoc @fluidframework/tinylicious-client#ITokenProvider.fetchStorageToken}
	 */
	public async fetchStorageToken(
		tenantId: string,
		documentId: string,
		refresh?: boolean,
	): Promise<ITokenResponse> {
		let token;
		try {
			token = await this.getToken(tenantId, documentId, refresh ?? false);
		} catch (error) {
			console.error(`Error generating token.`);
			throw error;
		}
		return token;
	}

	private async getToken(
		tenantId: string,
		documentId: string | undefined,
		refresh: boolean,
	): Promise<ITokenResponse> {
		const cacheKey = hash({ tenantId, documentId, userName: this.userName });

		// The user has specified that tokens should be refreshed.
		// Delete the cached value if it exists.
		if (refresh) {
			this.tokenCache.delete(cacheKey);
		}

		const cachedToken = this.tokenCache.get(cacheKey);
		if (cachedToken !== undefined) {
			// Round expiry time
			if (Date.now() < Math.floor(cachedToken.expiryTime)) {
				// Token is still valid (not expired). Return it.
				return {
					jwt: cachedToken.jwt,
					fromCache: true,
				};
			} else {
				// The token has expired. Remove it from the cache.
				this.tokenCache.delete(cacheKey);
			}
		}

		// We will attempt to get the token from our service
		// This is a basic 5 attempt retry policy with a 1 second delay inbetween
		const retryAttempts = 5;

		for (let i = 0; i < retryAttempts; i++) {
			// Set expiry time *before* request to ensure our cache does not attempt to use the token
			// after its actual expiry, which is set during the serverless function invocation.
			// Also round time down to reduce chance of token expiring between the time it was returned
			// (from the cache) and the time it's used by the runtime.
			const expiryTime = Math.floor(Date.now() + 1000 * tokenLifetimeInSeconds);

			const response = await getAzureFluidRelayAccessToken(
				tenantId,
				documentId,
				this.userName,
				tokenLifetimeInSeconds,
			);

			if (response) {
				this.tokenCache.set(cacheKey, {
					jwt: response,
					expiryTime,
				});
				return {
					jwt: response,
					fromCache: false,
				};
			} else if (i < retryAttempts - 1) {
				// Add increasing pause
				await pauseProcessing(1000 * i);
			}
		}
		throw new Error(`Could not get access token from backend after ${retryAttempts} attempts.`);
	}
}

/**
 * Basic function to make adding a pause simple.
 */
async function pauseProcessing(delayInMilliseconds: number): Promise<void> {
	let res: () => void;
	const p = new Promise<void>((r) => (res = r));
	setTimeout(() => {
		res();
	}, delayInMilliseconds);
	return p;
}
