import type { HasCampaign } from '@datapad/common-props';
import { toLongString } from '@datapad/interfaces';
import { Today } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { menuLabelStyle, menuLabelTypographyVariant } from './Constants';

/**
 * {@link CampaignDetails} input props.
 */
export interface CampaignDetailsProps extends HasCampaign {
	onClick(): void;
}

/**
 * Datapad menu header.
 */
export function CampaignDetails(props: CampaignDetailsProps): React.ReactElement {
	const { campaign, onClick } = props;

	const campaignDate = campaign.currentDate;
	if (campaignDate === undefined) {
		return <></>;
	}

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Typography variant={menuLabelTypographyVariant} style={menuLabelStyle}>
				{"Today's Date"}
			</Typography>
			<ListItem button onClick={onClick}>
				<ListItemIcon>{<Today />}</ListItemIcon>
				<ListItemText primary={toLongString(campaignDate)} />
			</ListItem>
		</div>
	);
}
