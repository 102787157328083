/**
 * Contains common React Context definitions used by the Datapad to avoid prop drilling.
 */

import type { HasProfile } from '@datapad/common-props';
import type { CalendarDate } from '@datapad/interfaces';
import React from 'react';
import type { AppId } from '../apps/Datapad/AppId';

/**
 * Data used by {@link ProfileContext}.
 */
export interface ProfileContextData extends HasProfile {
	/**
	 * Changes the signed-in character of the session profile.
	 */
	changeSignedInCharacter(character: string | undefined): void;

	/**
	 * Changes the profile campaign's current, in-world date.
	 */
	changeCampaignDate(newDate: CalendarDate): void;

	/**
	 * Toggles the profile's DM Mode.
	 * @remarks Must only be set when the player is the DM.
	 */
	toggleDMMode?(newValue: boolean): void;
}

/**
 * React Context containing the signed-in player's profile.
 */
export const ProfileContext = React.createContext<ProfileContextData | undefined>(undefined);

/**
 * Data used by {@link DatapadAppStateContext}.
 */
export interface DatapadAppStateContextData {
	/**
	 * Update Datapad app state to display the main menu.
	 */
	showMenu(): void;

	/**
	 * Update Datapad app state to display the main menu.
	 */
	hideMenu(): void;

	/**
	 * Change the app selection to the one specified.
	 *
	 * @param selection - The new app selection.
	 * @param urlSearchParams - Optional urlSearch parameters to provide the app.
	 * TODO: encapsulate this better. Apps should not need to know eachothers URL format.
	 * @param toggleMenuState - Optional change to menu state upon changing apps.
	 */
	changeAppSelection(
		selection: AppId,
		urlSearchParams?: string,
		toggleMenuState?: 'open' | 'closed',
	): void;
}

/**
 * React Context containing globally accessible Datapad app state / functionality.
 */
export const DatapadAppStateContext = React.createContext<DatapadAppStateContextData | undefined>(
	undefined,
);
