import { urlFormat } from '@datapad/utilities';
import type { CalendarDate } from './calendar/index.js';

// TODOs:
// - RawCampaign and Campaign (real characters vs character names)

/**
 * Represents a player-character party.
 */
export type Party = string;

/**
 * Represents a campaign in the world.
 */
export interface Campaign {
	/**
	 * Name of the campaign
	 */
	name: string;

	/**
	 * The name of the party associated with the campaign.
	 */
	party: Party;

	/**
	 * The current in-world date for the campaign.
	 */
	currentDate: CalendarDate;

	/**
	 * List of active characters (by name) associated with the campaign.
	 */
	characters: readonly string[];

	/**
	 * List of inactive (former) characters (by name) associated with the campaign.
	 */
	inactiveCharacters?: readonly string[];
}

/**
 * Campaign key for URL search params
 */
const campaignUrlSearchKey = 'campaign';

/**
 * Gets the appropriate campaign name in URL format (if any) from the current URL search component
 * @param searchParams - URL search string
 */
export function urlFormattedCampaignNameFromUrlSearch(
	searchParams: URLSearchParams,
): string | undefined {
	let campaignNameUrlString: string | undefined;
	searchParams.forEach((value, key) => {
		if (key.toLocaleLowerCase() === campaignUrlSearchKey) {
			campaignNameUrlString = value;
		}
	});
	return campaignNameUrlString;
}

/**
 * Builds the URL search parameters for the selected campaign
 */
export function urlSearchFromCampaign(campaign?: Campaign): string {
	return urlSearchFromCampaignName(campaign?.name);
}

/**
 * Builds the URL search parameters for the selected campaign
 */
export function urlSearchFromCampaignName(campaignName?: string): string {
	if (campaignName === undefined) {
		return '';
	}
	const campaignUrlString = urlFormat(campaignName);
	return `${campaignUrlSearchKey}=${campaignUrlString}`;
}
