import { CircularProgress } from '@mui/material';
import React from 'react';
import { Img as ReactImage } from 'react-image';
import { ImageContainerShape, type ImageOptions } from './ImageOptions.js';

/**
 * Utilities for interacting with image cloud storage
 */

// TODO: timeout after some provided time. Stop spinner and display error.
/**
 * Renders the first image found in the list of urls. Multiple images can be used to
 * handle the case where the requested image does not exists, or 404s or what-have-you.
 * Displays a spinner while the image is being loaded.
 */
export function loadAndRenderImage(
	imageUrls: string[],
	options: ImageOptions,
	key: string, // Required. Without it, the component gets confused and will fail to load the image
): React.ReactElement {
	let borderRadius = 0;

	const minImageDimensionInPixels = getMinImageDimensionInPixels(
		options.maxHeightInPixels,
		options.maxWidthInPixels,
	);

	switch (options.containerShape) {
		case ImageContainerShape.RoundedRectangle:
			borderRadius = minImageDimensionInPixels / 20;
			break;
		case ImageContainerShape.Circle:
			borderRadius = minImageDimensionInPixels;
			break;
		default:
			break;
	}

	// TODO: memory leak if this component is unmounted before image is loaded :(
	return (
		<ReactImage
			src={imageUrls}
			key={key}
			loader={renderLoadingPlaceholder(options.maxHeightInPixels, options.maxWidthInPixels)}
			style={{
				borderRadius,
				maxHeight: options.maxHeightInPixels ?? '100%',
				maxWidth: options.maxWidthInPixels ?? '100%',
				backgroundColor: options.backgroundColor,
			}}
		></ReactImage>
	);
}

/**
 * Renders the spinner, spaced for the image being loaded
 */
function renderLoadingPlaceholder(
	maxHeightInPixels?: number,
	maxWidthInPixels?: number,
): React.ReactElement {
	return (
		<div
			style={{
				minWidth: maxWidthInPixels,
				minHeight: maxHeightInPixels,
				display: 'flex',
				justifyContent: 'center',
				alignContent: 'center',
				alignItems: 'center',
			}}
		>
			<CircularProgress color="primary"></CircularProgress>
		</div>
	);
}

/**
 * Gets minimum between the width and height.
 * Note: User must specify at least one of the two parameters.
 */
export function getMinImageDimensionInPixels(
	maxHeightInPixels?: number,
	maxWidthInPixels?: number,
): number {
	if (maxHeightInPixels === undefined && maxWidthInPixels === undefined) {
		throw new Error('Both dimensions were undefined. Must specify at least one min dimension.');
	}

	if (maxHeightInPixels === undefined) {
		// Checked above...
		return maxWidthInPixels!;
	}

	if (maxWidthInPixels === undefined) {
		return maxHeightInPixels;
	}

	return Math.min(maxHeightInPixels, maxWidthInPixels);
}

/**
 * Gets maximum between the width and height
 */
export function getMaxImageDimensionInPixels(
	maxHeightInPixels?: number,
	maxWidthInPixels?: number,
): number | undefined {
	if (maxHeightInPixels === undefined && maxWidthInPixels === undefined) {
		return undefined;
	}

	if (maxHeightInPixels === undefined) {
		return maxWidthInPixels;
	}

	if (maxWidthInPixels === undefined) {
		return maxHeightInPixels;
	}

	return Math.max(maxHeightInPixels, maxWidthInPixels);
}
