import type React from 'react';

/**
 * Input parameters for {@link DataEntry}
 */
export interface DataEntryProps<T> {
	/**
	 * {@inheritDoc DataEntry.initialValue}
	 */
	initialValue: T | undefined;

	/**
	 * {@inheritDoc DataEntry.label}
	 */
	label: string;

	/**
	 * See {@link DataEntry.elementId}.
	 * If undefined, will use {@link DataEntryProps.label}
	 */
	elementId?: string;

	/**
	 * See {@link DataEntry.locked}.
	 * undefined =\> false
	 */
	locked?: boolean;
}

export abstract class DataEntry<T = unknown> {
	/**
	 * Initial value for the form.
	 */
	public readonly initialValue: T | undefined;

	/**
	 * Form label
	 */
	public readonly label: string;

	/**
	 * Element ID for the dom.
	 */
	public readonly elementId: string;

	/**
	 * Indicates that the form is locked and may not be edited.
	 */
	public readonly locked: boolean;

	protected constructor(props: DataEntryProps<T>) {
		this.initialValue = props.initialValue;
		this.label = props.label;
		this.elementId = props.elementId ?? props.label;
		this.locked = props.locked ?? false;
	}

	/**
	 * Determines whether or not the provided value is a valid submission for this field.
	 * @returns An error `string` if the current value is invalid. `undefined` otherwise.
	 */
	public abstract errorMessage(value: T | undefined): string | undefined;

	/**
	 * Whether or not the provided value is valid for the form.
	 */
	public isValueValid(value: T | undefined): boolean {
		return this.errorMessage(value) === undefined;
	}

	/**
	 * Determines whether or not the provided value is different from the initial value.
	 */
	public abstract hasValueChanged(value: T | undefined): boolean;

	/**
	 * Renders the appropriate form for the data entry type.
	 */
	public abstract renderForm(
		currentValue: T | undefined,
		onChange: (newValue: T | undefined) => void,
	): React.ReactElement;
}
