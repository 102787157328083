import {
	type CanCreateCharacter,
	type CanDeleteCharacter,
	type CanEditCharacter,
	CharacterProfile,
} from '@datapad/character-components';
import type { HasProfile } from '@datapad/common-props';
import type { PlayerCharacter } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import { DMToolbar } from './Components/DMToolbar.js';

/**
 * Externally specified props
 */
export interface ProfileProps
	extends HasProfile,
		CanCreateCharacter<PlayerCharacter>,
		CanEditCharacter<PlayerCharacter>,
		CanDeleteCharacter<PlayerCharacter> {
	/**
	 * Function to call to handle a link to the Contacts app
	 */
	onContactLink: (contactName: string) => void;
}

/**
 * Profile app view
 */
export function Profile(props: ProfileProps): React.ReactElement {
	const { createCharacter, editCharacter, deleteCharacter, onContactLink, profile } = props;

	// #region Helper functions

	/**
	 * Delete an existing character and update selection to default
	 */
	function onDeleteCharacter(character: PlayerCharacter): void {
		deleteCharacter(character);
	}

	/**
	 * Create new character and change selection to it.
	 */
	function onCreateCharacter(character: PlayerCharacter): void {
		createCharacter(character);
	}

	// #endregion

	let renderContent;
	if (profile.characters.length === 0) {
		renderContent = <NoCharacters />;
	} else {
		const character = assertNotUndefined(profile.signedInCharacter);

		const toolbar = profile.dmMode ? <DMToolbar createCharacter={onCreateCharacter} /> : <></>;
		const view = (
			<CharacterProfile
				profile={profile}
				character={character}
				userCanEdit={true}
				displayEmptyCards={true}
				handleCharacterLink={onContactLink}
				editCharacter={editCharacter}
				deleteCharacter={onDeleteCharacter}
			/>
		);

		renderContent = (
			<>
				{toolbar}
				{view}
			</>
		);
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
			}}
		>
			{renderContent}
		</div>
	);
}

/**
 * Renders a default view if the Player has no characters
 */
function NoCharacters(): React.ReactElement {
	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			No player characters found. If this is an error, talk to your DM 😉.
		</div>
	);
}
