import type { Character } from '@datapad/interfaces';
import { ModalCard } from '@datapad/utility-components';
import { Edit } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Divider, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import type { EditableCharacterProfileBaseProps } from './CommonProps.js';

/**
 * {@link CharacterInfoCard} component state
 */
export interface CharacterInfoCardState {
	/**
	 * Whether or not the component is in "editing" mode, in which a dialogue will be displayed.
	 */
	editing: boolean;
}

/**
 * A small card containing basic character info.
 */
export abstract class CharacterInfoCard<
	TProps extends EditableCharacterProfileBaseProps = EditableCharacterProfileBaseProps,
> extends React.Component<TProps, CharacterInfoCardState> {
	/**
	 * Text to display in the card header
	 */
	abstract readonly headerText: string;

	protected constructor(props: TProps) {
		super(props);
		this.state = {
			editing: false,
		};
	}

	/**
	 * Style properties for editing modal dialogue.
	 * Can be overridden by subtypes as needed.
	 */
	protected getModalCardStyle(): React.CSSProperties {
		return {
			width: '95%',
			maxWidth: '400px',
			maxHeight: '95%',
		};
	}

	protected updateEditStatus(editing: boolean): void {
		this.setState({
			...this.state,
			editing,
		});
	}

	protected cancelEdit(): void {
		this.updateEditStatus(false);
	}

	protected async submitEdits(characterUpdate: Character): Promise<void> {
		this.props.editCharacter(characterUpdate);
		this.updateEditStatus(false);
	}

	public override render(): React.ReactElement {
		return (
			<Card style={{ height: '100%' }}>
				{this.renderMaybeEditingModal()}
				{this.renderHeader()}
				<Divider variant="middle" orientation="horizontal" light />
				{this.renderBody()}
			</Card>
		);
	}

	/**
	 * Renders the character info card header.
	 */
	private renderHeader(): React.ReactElement {
		return <CardHeader title={this.headerText} action={this.renderMaybeEditButton()} />;
	}

	private renderMaybeEditButton(): React.ReactElement {
		if (this.props.userCanEdit) {
			return (
				<Tooltip title="Edit">
					<IconButton onClick={() => this.updateEditStatus(true)}>
						<Edit />
					</IconButton>
				</Tooltip>
			);
		} else {
			return <></>;
		}
	}

	private renderBody(): React.ReactElement {
		return (
			<CardContent
				style={{
					maxHeight: '350px',
					overflowY: 'auto',
					overflowX: 'clip',
				}}
			>
				{this.renderBodyContents()}
			</CardContent>
		);
	}

	private renderMaybeEditingModal(): React.ReactElement {
		if (!this.state.editing) {
			return <></>;
		}

		const modalStyle = this.getModalCardStyle();

		return (
			<ModalCard open style={modalStyle} onClose={() => this.cancelEdit()}>
				{this.renderEditForm()}
			</ModalCard>
		);
	}

	/**
	 * Renders the internal contents of the character info card
	 */
	protected abstract renderBodyContents(): React.ReactElement;

	/**
	 * Renders the edit form contents for the modal dialogue
	 */
	protected abstract renderEditForm(): React.ReactElement;
}
