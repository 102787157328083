import { ImageContainerShape } from '@datapad/image';
import { urlFormat } from '@datapad/utilities';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { CharacterImageVariant, renderCharacterImage } from './ImageUtilities.js';

// TODO: only make entry clickable if the signed-in character knows the character

/**
 * Input props for {@link CharacterList}.
 */
interface CharacterListProps {
	characterNames: readonly string[];
	onSelect: (characterName: string) => void;

	/**
	 * @see {@link List}
	 */
	dense?: boolean;

	/**
	 * @see {@link List}
	 */
	disablePadding?: boolean;

	/**
	 * Whether or not to treat the list entry as a "button" (i.e. give it hover-over behavior, etc.).
	 *
	 * @defaultValue `true`
	 */
	button?: boolean;
}

/**
 * Renders a {@link List} of {@link CharacterListEntry}s for the provided characters.
 */
export function CharacterList(props: CharacterListProps): React.ReactElement {
	const { characterNames, onSelect, dense, disablePadding, button } = props;
	return (
		<List dense={dense} disablePadding={disablePadding}>
			{characterNames.map((characterName) => {
				const urlFormatCharacterName = urlFormat(characterName);
				const key = `character-${urlFormatCharacterName}`;
				return (
					<CharacterListEntry
						key={key}
						characterName={characterName}
						onClick={() => onSelect(characterName)}
						button={button}
					/>
				);
			})}
		</List>
	);
}

/**
 * Input props for {@link CharacterListEntry}.
 */
interface CharacterListEntryProps {
	characterName: string;
	onClick?: () => void;

	/**
	 * Whether or not to treat the list entry as a "button" (i.e. give it hover-over behavior, etc.).
	 *
	 * @defaultValue `true`
	 */
	button?: boolean;
}

/**
 * Renders a mini card displaying the name and thumbnail image of a character.
 */
export function CharacterListEntry(props: CharacterListEntryProps): React.ReactElement {
	const { characterName, onClick, button } = props;
	const characterImage = renderCharacterImage(characterName, {
		maxHeightInPixels: 45,
		maxWidthInPixels: 45,
		containerShape: ImageContainerShape.Circle,
		variant: CharacterImageVariant.Profile,
		backgroundColor: 'white',
	});

	const renderedContents = (
		<>
			<ListItemIcon>{characterImage}</ListItemIcon>
			<ListItemText primary={characterName} />
		</>
	);

	// Handle default value: true
	const useButton = button === false ? false : true;

	// This is as dumb as it looks.
	// Unfortunate side-effect of Material-UIs type-wise templating WRT the `button` prop.
	// See this TS issue for more details: <https://github.com/microsoft/TypeScript/issues/19360>.
	return useButton ? (
		<ListItem button onClick={onClick}>
			{renderedContents}
		</ListItem>
	) : (
		<ListItem onClick={onClick}>{renderedContents}</ListItem>
	);
}
