import { CharacterList } from '@datapad/character-components';
import type { CanGetCharacter, HasCalendarEvent, HasProfile } from '@datapad/common-props';
import {
	type CalendarEvent,
	type Character,
	dateFromEvent,
	toLongString,
} from '@datapad/interfaces';
import { MarkdownTextbox } from '@datapad/markdown-textbox';
import { sortArray } from '@datapad/utilities';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, Container, Typography } from '@mui/material';
import React from 'react';

// TODOs: Update backend to not fetch full characters for events

/**
 * Input props for {@link EventDetails}
 */
export interface EventDetailsProps extends HasCalendarEvent, HasProfile, CanGetCharacter {
	/**
	 * Event for which info will be displayed.
	 */
	event: CalendarEvent;

	/**
	 * Function to handle a character link.
	 */
	handleCharacterLink: (characterName: string) => void;
}

/**
 * Renders details about the specified {@link CalendarEvent}.
 */
export class EventDetails extends React.Component<EventDetailsProps> {
	public constructor(props: EventDetailsProps) {
		super(props);
	}

	public override render(): React.ReactNode {
		const { event, getCharacter } = this.props;

		const eventCharacterNames = sortArray(this.props.event.characters ?? [], (a, b) =>
			a.localeCompare(b),
		);
		const eventCharacters = eventCharacterNames.map(getCharacter);

		return (
			<div>
				{this.renderDate(event)}
				{event.location ? this.renderLocation(event.location) : <></>}
				{event.description ? this.renderDescription(event.description) : <></>}
				{eventCharacterNames !== undefined && eventCharacterNames.length > 0 ? (
					this.renderCharacters(eventCharacters)
				) : (
					<></>
				)}
			</div>
		);
	}

	private renderDescription(eventDescription: string): React.ReactNode {
		return (
			<div
				style={{
					width: '100%',
				}}
			>
				<Typography variant="h6">Summary</Typography>
				<Container
					style={{
						width: '100%',
						maxHeight: '300px',
						overflowY: 'auto',
						background: 'transparent',
						paddingLeft: '5px',
						paddingRight: '5px',
					}}
				>
					<MarkdownTextbox text={eventDescription} />
				</Container>
			</div>
		);
	}

	// TODO: link to wookieepedia or wherever
	private renderLocation(eventLocation: string): React.ReactNode {
		return this.renderEventData(eventLocation, 'Location');
	}

	private renderDate(event: CalendarEvent): React.ReactNode {
		const date = dateFromEvent(event);
		const longRepresentation = toLongString(date);
		return this.renderEventData(`${longRepresentation} BBY`, 'Date');
	}

	private renderEventData(data: string, label?: string): React.ReactNode {
		return (
			<div
				style={{
					textAlign: 'left',
				}}
			>
				<p>
					{label ? <b>{label}: </b> : <></>}
					{data}
				</p>
			</div>
		);
	}

	/**
	 * Renders the list of provided characters
	 */
	private renderCharacters(characters: Character[]): React.ReactElement {
		const useAccordion = characters.length > 5;

		const listRender = (
			<CharacterList
				characterNames={characters.map((character) => character.name)}
				onSelect={this.props.handleCharacterLink}
			/>
		);

		if (useAccordion) {
			return (
				<div
					style={{
						textAlign: 'left',
						paddingTop: '10px',
					}}
				>
					<Accordion
						variant="outlined"
						style={{
							background: 'transparent',
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography variant="h6">Involved Characters</Typography>
						</AccordionSummary>
						{listRender}
					</Accordion>
				</div>
			);
		}

		return (
			<div
				style={{
					textAlign: 'left',
					paddingTop: '10px',
				}}
			>
				<Typography variant="h6">Involved Characters:</Typography>
				{listRender}
			</div>
		);
	}
}
