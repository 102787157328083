import { getFluidContainerId } from '@datapad/backend-interface';
import { GalaxyMap } from '@datapad/galaxy-map';
import { type CalendarDate, type Player, PlayerKind, type Profile } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import { LoadingScreen } from '@datapad/utility-components';
import type { IFluidHandle } from '@fluidframework/core-interfaces';
import { Drawer } from '@mui/material';
import type { ContainerSchema, ICriticalContainerError, IFluidContainer } from 'fluid-framework';
import { SharedMap, SharedString } from 'fluid-framework/legacy';
import React from 'react';
import {
	DatapadAppStateContext,
	FluidContainerContext,
	ProfileContext,
	type SharedCalendarEventsMap,
	type SharedCampaignsMap,
	type SharedNonPlayerCharactersMap,
	type SharedPlayerCharactersMap,
	type SharedPlayersMap,
	calendarEventsMapKey,
	campaignsMapKey,
	nonPlayerCharactersMapKey,
	playerCharactersMapKey,
	playersMapKey,
	preFetchMaps,
	usePlayer,
	useProfile,
} from '../../../utilities';
import type { Actions } from '../Actions';
import { AppId } from '../AppId';
import type { AppState } from '../State';
import { getAzureClient } from '../utilities';
import { Banner } from './Banner';
import { Calendar } from './Calendar';
import { Campaigns } from './Campaigns';
import { Contacts } from './Contacts';
import { DatapadPaper } from './DatapadPaper';
import { Players } from './Players';
import { Profile as ProfileApp } from './Profile';
import { Timeline } from './Timeline';
import { DatapadMenu } from './menu';

/**
 * Default app selection to be used within the datapad
 */
export const defaultAppSelection = AppId.Profile;

const appId = 'datpad';
const viewId = 'datapad-view';
const menuId = 'datapad-menu';

/**
 * Determines which apps in the Datapad are enabled. Set by the consumer.
 */
export interface DatapadExternalProps {
	/**
	 * Name of the signed-in user.
	 */
	userName: string;

	/**
	 * Function for signing the user out of the application.
	 */
	logoutFunction: () => void;

	/**
	 * Currently selected application.
	 */
	appSelection: AppId;

	/**
	 * Function to call when the app selection needs to change
	 */
	onChangeAppSelection: (appId: AppId, urlSearchParams?: string) => void;

	/**
	 * Function to invoke to refresh all app state
	 */
	onRefreshAppState: () => void;
}

/**
 * Datapad {@link https://reactjs.org/docs/render-props.html | Render Props}
 */
export type DatapadProps = Actions & AppState & DatapadExternalProps;

/**
 * Datapad main entry-point. Appears below header in app. Contains side-bar UI for navigating options.
 *
 * @remarks Root-most element in the internal data-load flow. Manages the Fluid container.
 */
export function Datapad(props: DatapadProps): React.ReactElement {
	// #region Helper functions

	/**
	 * Gets an existing Fluid container
	 */
	async function getFluidContainer(userName: string): Promise<void> {
		const containerId = await getFluidContainerId();

		if (!containerId) {
			throw new Error(`Could not fetch Fluid container ID.`);
		}

		// Get connection to service
		const azureClient = await getAzureClient(userName);

		// Get or create container
		const containerSchema: ContainerSchema = {
			initialObjects: { rootMap: SharedMap },
			dynamicObjectTypes: [SharedMap, SharedString],
		};

		const { container } = await azureClient.getContainer(containerId, containerSchema, '2');

		// TODO: store and use services object as well?
		props.setFluidContainer(container);
	}

	// #endregion

	const fluidContainer = props.fluidContainer;

	let appViewAndMenu: React.ReactElement;
	if (fluidContainer) {
		appViewAndMenu = <LoadCoreMaps {...props} fluidContainer={fluidContainer} />;
	} else {
		getFluidContainer(props.userName);
		appViewAndMenu = <LoadingScreen text={`Loading system data...`} />;
	}

	return (
		<DatapadPaper>
			<Banner
				onOpenMenu={() => props.setDatapadMenuState('open')}
				onRefreshAppState={props.onRefreshAppState}
			/>
			<div
				id={appId}
				style={{
					display: 'flex',
					flexDirection: 'row',
					textAlign: 'center',
					flex: 1,
					overflow: 'clip',
				}}
			>
				{appViewAndMenu}
			</div>
		</DatapadPaper>
	);
}

interface LoadCoreMapsProps extends DatapadProps {
	/**
	 * @override
	 */
	fluidContainer: IFluidContainer;
}

/**
 * Fetches the subset of the Fluid maps required to generate player profile data, then dispatches to
 * the next layer.
 *
 * @remarks Also pre-fetches other (non-core) maps as an optimization, but nothing in this layer
 * waits on these to be populated.
 */
function LoadCoreMaps(props: LoadCoreMapsProps): React.ReactElement {
	const { fluidContainer } = props;

	const datapadMap = assertNotUndefined(fluidContainer.initialObjects.rootMap as SharedMap);

	const [playersMap, setPlayersMap] = React.useState<SharedPlayersMap | undefined>(undefined);

	const [playerCharactersMap, setPlayerCharactersMap] = React.useState<
		SharedPlayerCharactersMap | undefined
	>(undefined);

	const [campaignsMap, setCampaignsMap] = React.useState<SharedCampaignsMap | undefined>(
		undefined,
	);

	// Pre-fetch app data tables for performance
	preFetchMaps(datapadMap);

	// #region Fluid state management

	// Simple monitor of connection / disposal state.
	// In the case of pre-mature disposal, throw an error.
	React.useEffect(() => {
		function onContainerDisconnected(error?: ICriticalContainerError): void {
			console.log(`Fluid Container disconnected.`);
			// TODO: display some sort of warning to the user when in a disconnected state?
			if (error !== undefined) {
				console.error('Fluid Container disconnected due to an error.');
				console.error(error);
			}
		}
		function onContainerConnected(): void {
			console.log(`Fluid Container connected.`);
		}

		function onContainerDisposed(error?: ICriticalContainerError): void {
			console.error('Fluid Container was disposed.');
			console.error(error ?? 'No error reason provided by the Container.');
			throw (
				error ??
				new Error(
					'Fluid container was unexpectedly disposed, and no error reason was provided by the runtime.',
				)
			);
		}

		fluidContainer.on('connected', onContainerConnected);
		fluidContainer.on('disconnected', onContainerDisconnected);
		fluidContainer.on('disposed', onContainerDisposed);

		return () => {
			fluidContainer.off('connected', onContainerConnected);
			fluidContainer.off('disconnected', onContainerDisconnected);
			fluidContainer.off('disposed', onContainerDisposed);
		};
	}, [fluidContainer]);

	// Register to receive root map updates, and update local state accordingly.
	React.useEffect(() => {
		/**
		 * Sync Fluid data into view state.
		 * This should only ever run if we replace one of the app map objects. I.e. not often...
		 */
		async function syncFluidState(): Promise<void> {
			await getAwaitAndSetMap(playersMapKey, setPlayersMap, datapadMap);
			await getAwaitAndSetMap(playerCharactersMapKey, setPlayerCharactersMap, datapadMap);
			await getAwaitAndSetMap(campaignsMapKey, setCampaignsMap, datapadMap);
			// Other maps are handled lower in the component hierarchy
		}

		// Update state each time our map changes
		datapadMap.on('valueChanged', syncFluidState);

		// Run update once to ensure sub-maps get populated when they are ready.
		syncFluidState();

		// Turn off listener when component is unmounted
		return () => {
			datapadMap.off('valueChanged', syncFluidState);
		};
	}, [datapadMap, setPlayersMap, setPlayerCharactersMap, setCampaignsMap]);

	// #endregion

	if (
		playersMap === undefined ||
		playerCharactersMap === undefined ||
		campaignsMap === undefined
	) {
		generateMapIfMissing(playersMapKey, datapadMap, fluidContainer);
		generateMapIfMissing(playerCharactersMapKey, datapadMap, fluidContainer);
		generateMapIfMissing(campaignsMapKey, datapadMap, fluidContainer);

		return <LoadingScreen text={`Loading profile data...`} />;
	} else {
		return (
			<FluidContainerContext.Provider value={fluidContainer}>
				<LoadProfile
					{...props}
					playersMap={playersMap}
					playerCharactersMap={playerCharactersMap}
					campaignsMap={campaignsMap}
				/>
			</FluidContainerContext.Provider>
		);
	}
}

/**
 * {@link LoadProfile} input props.
 */
interface LoadProfileProps extends LoadCoreMapsProps {
	playersMap: SharedPlayersMap;
	playerCharactersMap: SharedPlayerCharactersMap;
	campaignsMap: SharedCampaignsMap;
}

/**
 * Generates the player's session profile from the loaded Fluid state, then dispatches to the next
 * layer.
 *
 * @remarks Sets {@link ProfileContext} as a global context object for app components to use.
 */
function LoadProfile(props: LoadProfileProps): React.ReactElement {
	const { userName, playersMap, playerCharactersMap, campaignsMap } = props;

	const player: Player = usePlayer(userName, playersMap);
	const isPlayerDungeonMaster = player.playerKind === PlayerKind.DungeonMaster;

	// undefined sessionCharacter => use default selection
	const [sessionCharacter, setSessionCharacter] = React.useState<string | undefined>(undefined);
	const [dmMode, setDMMode] = React.useState<boolean>(isPlayerDungeonMaster);

	const profile: Profile = useProfile(
		player,
		sessionCharacter,
		campaignsMap,
		playerCharactersMap,
		dmMode,
	);

	/**
	 * Updates the profile player's default character selection.
	 * Used to change signed-in character selection.
	 */
	function updateSessionCharacter(character: string | undefined): void {
		setSessionCharacter(character);
	}

	function updateCampaignDate(newDate: CalendarDate): void {
		if (profile.campaign === undefined) {
			console.error('Attempted to update campaign date for non-existent campaign.');
		} else {
			campaignsMap.set(profile.campaign.name, {
				...profile.campaign,
				currentDate: newDate,
			});
		}
	}

	function toggleDMMode(newValue: boolean): void {
		setDMMode(newValue);
	}

	return (
		<ProfileContext.Provider
			value={{
				profile,
				changeSignedInCharacter: updateSessionCharacter,
				changeCampaignDate: updateCampaignDate,
				toggleDMMode: isPlayerDungeonMaster ? toggleDMMode : undefined,
			}}
		>
			<DisplayDatapad {...props} />
		</ProfileContext.Provider>
	);
}

/**
 * {@link DisplayDatapad} input props.
 */
type DisplayDatapadProps = LoadProfileProps;

/**
 * Final layer in the load flow. Displays the current app selection and toggle-able menu.
 *
 * @remarks Lazily-loads Fluid maps as needed by the selected app.
 */
function DisplayDatapad(props: DisplayDatapadProps): React.ReactElement {
	const { fluidContainer, playersMap, playerCharactersMap, campaignsMap } = props;

	/**
	 * Changes the app selection
	 */
	function changeApp(
		newAppSelection: AppId,
		urlSearchParams: string | undefined,
		toggleMenuState: 'open' | 'closed' | undefined,
	): void {
		if (newAppSelection !== props.appSelection || urlSearchParams) {
			props.onChangeAppSelection(newAppSelection, urlSearchParams);
		}
		if (toggleMenuState !== undefined) {
			props.setDatapadMenuState(toggleMenuState);
		}
	}

	const [nonPlayerCharactersMap, setNonPlayerCharactersMap] = React.useState<
		SharedNonPlayerCharactersMap | undefined
	>(undefined);

	const [calendarEventsMap, setCalendarEventsMap] = React.useState<
		SharedCalendarEventsMap | undefined
	>(undefined);

	const datapadMap = assertNotUndefined(fluidContainer.initialObjects.rootMap as SharedMap);

	// #region Fluid state management

	/**
	 * Sync Fluid data into view state.
	 * This should only ever run if we replace one of the app map objects. I.e. not often...
	 */
	async function syncFluidState(): Promise<void> {
		// playersMap is handled specially one hierarchical level higher
		await getAwaitAndSetMap(nonPlayerCharactersMapKey, setNonPlayerCharactersMap, datapadMap);
		await getAwaitAndSetMap(calendarEventsMapKey, setCalendarEventsMap, datapadMap);
		// TODO: other data maps as needed
	}

	// Register to receive root map updates, and update local state accordingly.
	React.useEffect(() => {
		// Update state each time our map changes
		datapadMap.on('valueChanged', syncFluidState);

		// Turn off listener when component is unmounted
		return () => {
			datapadMap.off('valueChanged', syncFluidState);
		};
	});

	// #endregion

	// Run update once to ensure sub-maps get populated when they are ready.
	syncFluidState();

	let app: React.ReactElement;
	switch (props.appSelection) {
		case AppId.Profile:
			app = <ProfileApp playerCharactersMap={playerCharactersMap} />;
			break;
		case AppId.GalaxyMap:
			app = <GalaxyMap />;
			break;
		case AppId.Contacts:
			if (nonPlayerCharactersMap === undefined) {
				generateMapIfMissing(nonPlayerCharactersMapKey, datapadMap, fluidContainer);
				app = <InitializingMapView appName="Contacts" />;
			} else {
				app = (
					<Contacts
						playerCharactersMap={playerCharactersMap}
						nonPlayerCharactersMap={nonPlayerCharactersMap}
					/>
				);
			}
			break;
		case AppId.Calendar:
			if (calendarEventsMap === undefined || nonPlayerCharactersMap === undefined) {
				generateMapIfMissing(calendarEventsMapKey, datapadMap, fluidContainer);
				generateMapIfMissing(nonPlayerCharactersMapKey, datapadMap, fluidContainer);
				app = <InitializingMapView appName="Calendar" />;
			} else {
				app = (
					<Calendar
						calendarEventsMap={calendarEventsMap}
						playerCharactersMap={playerCharactersMap}
						nonPlayerCharactersMap={nonPlayerCharactersMap}
					/>
				);
			}
			break;
		case AppId.Timeline:
			if (calendarEventsMap === undefined || nonPlayerCharactersMap === undefined) {
				generateMapIfMissing(calendarEventsMapKey, datapadMap, fluidContainer);
				generateMapIfMissing(nonPlayerCharactersMapKey, datapadMap, fluidContainer);
				app = <InitializingMapView appName="Timeline" />;
			} else {
				app = (
					<Timeline
						calendarEventsMap={calendarEventsMap}
						playerCharactersMap={playerCharactersMap}
						nonPlayerCharactersMap={nonPlayerCharactersMap}
					/>
				);
			}
			break;
		case AppId.Players:
			app = <Players playersMap={playersMap} />;
			break;

		case AppId.Campaigns:
			app = <Campaigns campaignsMap={campaignsMap} />;
			break;

		default:
			throw new Error(`Unrecognized app selection: ${props.appSelection}`);
	}

	const view = (
		<div
			id={viewId}
			style={{
				textAlign: 'center',
				float: 'right',
				flex: 1,
			}}
		>
			{app}
		</div>
	);

	const menu = (
		<Drawer
			id={menuId}
			onClose={() => {
				props.setDatapadMenuState('closed');
			}}
			open={props.menuState === 'open'}
		>
			<DatapadMenu
				appSelection={props.appSelection}
				hideMenu={() => props.setDatapadMenuState('closed')}
				logout={props.logoutFunction}
			/>
		</Drawer>
	);

	return (
		<DatapadAppStateContext.Provider
			value={{
				showMenu: () => props.setDatapadMenuState('open'),
				hideMenu: () => props.setDatapadMenuState('closed'),
				changeAppSelection: changeApp,
			}}
		>
			{view}
			{menu}
		</DatapadAppStateContext.Provider>
	);
}

/**
 * {@link InitializingMapView} input props.
 */
interface InitializingMapViewProps {
	/**
	 * Name of the app for which the Fluid data object is being initialized.
	 */
	appName: string;
}

/**
 * Generates a new SharedMap and inserts it into the root map if it is undefined, and the key
 * is missing from the root map
 */
function generateMapIfMissing(
	key: string,
	datapadMap: SharedMap,
	fluidContainer: IFluidContainer,
): void {
	if (!datapadMap.has(key)) {
		fluidContainer.create(SharedMap).then((newMap) => datapadMap.set(key, newMap.handle));
	}
}

/**
 * Gets the sub-map handle for the provided key, awaits it, and sets it as React state
 * via the provided `setFunction`.
 */
async function getAwaitAndSetMap<TSharedMap>(
	key: string,
	setFunction: (map: TSharedMap) => void,
	datapadMap: SharedMap,
): Promise<void> {
	const handle: IFluidHandle<TSharedMap> | undefined = datapadMap.get(key);
	if (handle !== undefined) {
		const map = await handle.get();
		setFunction(map);
	}
}

/**
 * Loading screen to display when initializing underlying Fluid data for an app.
 */
function InitializingMapView(props: InitializingMapViewProps): React.ReactElement {
	return <LoadingScreen text={`Initializing new Fluid data object for ${props.appName}...`} />;
}

export default Datapad;
