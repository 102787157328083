import {
	Calendar,
	type CanCreateEvent,
	type CanDeleteEvent,
	type CanEditEvent,
} from '@datapad/calendar';
import type {
	CanGetCharacter,
	CanModifyCurrentDate,
	HasBirthdayEvents,
	HasCalendarEvents,
} from '@datapad/common-props';
import {
	type CalendarDate,
	type MonthOrFestivalWeek,
	monthOrFestivalWeekFromDate,
	monthsAndFestivalWeeks,
	urlSearchFromCharacterName,
} from '@datapad/interfaces';
import { assertNotUndefined, urlFormat } from '@datapad/utilities';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DatapadAppStateContext, ProfileContext } from '../utilities';
import { AppId } from './Datapad/AppId';

export interface RoutedCalendarProps
	extends HasCalendarEvents,
		HasBirthdayEvents,
		CanGetCharacter,
		CanModifyCurrentDate,
		CanCreateEvent,
		CanDeleteEvent,
		CanEditEvent {}

/**
 * Calendar component with URL routing
 */
export function RoutedCalendar(props: RoutedCalendarProps): React.ReactElement {
	const {
		events,
		birthdays,
		updateCurrentDate,
		createEvent,
		deleteEvent,
		editEvent,
		getCharacter,
	} = props;

	const location = useLocation();
	const history = useHistory();

	const { profile, changeSignedInCharacter } = assertNotUndefined(
		React.useContext(ProfileContext),
	);
	const currentDate = profile.getCampaignDateOrDefault();

	const { changeAppSelection, showMenu } = assertNotUndefined(
		React.useContext(DatapadAppStateContext),
	);

	// Redirect to the month containing the current date if no month/year was specified in url
	if (!location.search.length) {
		const search = urlSearchForDate(currentDate);
		history.replace({ search });
		return <></>;
	}

	const monthAndYear = getMonthAndYearFromSearch(new URLSearchParams(location.search));

	if (!monthAndYear) {
		// TODO: display error page
		throw new Error('No valid year/month specifier found in provided url search terms');
	}

	return (
		<Calendar
			profile={profile}
			events={events}
			birthdays={birthdays}
			monthOrFestivalWeek={monthAndYear.monthOrFestivalWeek}
			year={monthAndYear.year}
			onChangeMonth={(monthOrFestivalWeek: MonthOrFestivalWeek, year: number) => {
				// Don't push update if there is no change to make (just mucks up history)
				if (
					monthOrFestivalWeek.name !== monthAndYear.monthOrFestivalWeek.name ||
					year !== monthAndYear.year
				) {
					const urlSearch = urlSearchForMonth(monthOrFestivalWeek, year);
					history.push({
						search: urlSearch,
					});
				}
			}}
			getCharacter={getCharacter}
			// TODO: dedupe this logic with other uses
			handleCharacterLink={(characterName: string) => {
				const isPlayerCharacter = profile.doesCharacterNameBelongToPlayer(characterName);
				if (isPlayerCharacter) {
					changeSignedInCharacter(characterName);
					changeAppSelection(AppId.Profile);
					showMenu();
				} else {
					changeAppSelection(AppId.Contacts, urlSearchFromCharacterName(characterName));
				}
			}}
			updateCurrentDate={updateCurrentDate}
			createEvent={createEvent}
			deleteEvent={deleteEvent}
			editEvent={editEvent}
		/>
	);
}

/**
 * Month key for URL search params
 */
const monthUrlSearchKey = 'month';

/**
 * Converts a specific month/year to a url search string
 */
export function urlSearchForMonth(monthOrFestivalWeek: MonthOrFestivalWeek, year: number): string {
	return `${monthUrlSearchKey}=${year}-${urlFormat(monthOrFestivalWeek.name)}`;
}

/**
 * Gets the appropriate month/year url search string for the provided date.
 */
export function urlSearchForDate(date: CalendarDate): string {
	const monthOrFestivalWeek = monthOrFestivalWeekFromDate(date);
	return urlSearchForMonth(monthOrFestivalWeek, date.year);
}

interface MonthAndYear {
	monthOrFestivalWeek: MonthOrFestivalWeek;
	year: number;
}

/**
 * Gets the appropriate character name in URL format (if any) from the current URL search component
 * @param searchParams - URL search string
 */
export function getMonthAndYearFromSearch(searchParams: URLSearchParams): MonthAndYear | undefined {
	let stringRepresentation: string | undefined;
	searchParams.forEach((value, key) => {
		if (key.toLocaleLowerCase() === monthUrlSearchKey) {
			stringRepresentation = value;
		}
	});
	if (stringRepresentation) {
		// Format is <year>-<month-or-festival-week-name>
		const delimiterIndex = stringRepresentation.indexOf('-');
		if (delimiterIndex === -1) {
			return undefined;
		}

		const yearString = stringRepresentation.substring(0, delimiterIndex);
		const monthString = stringRepresentation.substring(
			delimiterIndex + 1,
			stringRepresentation.length,
		);

		const year = Number.parseInt(yearString);
		if (Number.isNaN(year)) {
			return undefined;
		}
		const monthOrFestivalWeek = getMonthOrFestivalWeekFromUrlFormat(monthString);
		if (!monthOrFestivalWeek) {
			return undefined;
		}
		return {
			year,
			monthOrFestivalWeek,
		};
	}
	return undefined;
}

/**
 * Gets the appropriate {@link MonthOrFestivalWeek} from the provided url-formatted string representation.
 * If no valid match is found, returns undefined.
 */
function getMonthOrFestivalWeekFromUrlFormat(monthString: string): MonthOrFestivalWeek | undefined {
	for (const month of monthsAndFestivalWeeks) {
		if (urlFormat(month.name) === monthString.toLocaleLowerCase()) {
			return month;
		}
	}
	return undefined;
}

export default RoutedCalendar;
