import { cloneArray, haveEqualContents } from '@datapad/utilities';
import { Card } from '@mui/material';
import React from 'react';
import { FormSubmissionFooter } from './FormSubmissionFooter.js';
import { MapForm, type MapFormEntry, areMapFormEntriesValid } from './MapForm.js';

/**
 * Input props for {@link MapFormDialogue}
 */
export interface MapFormDialogueProps {
	formTitle: string;
	initialEntries: MapFormEntry[] | undefined;
	keyLabel?: string;
	valueLabel?: string;
	/**
	 * Whether or not duplicate keys are allowed in the "map"
	 * undefined =\> false
	 */
	allowDuplicateKeys?: boolean;
	onSubmit: (newList: MapFormEntry[] | undefined) => void;
	onCancel: () => void;
}

/**
 * {@link MapFormDialogue} internal state
 */
interface MapFormDialogueState {
	entries: MapFormEntry[] | undefined;
}

/**
 * String map form
 */
export class MapFormDialogue extends React.Component<MapFormDialogueProps, MapFormDialogueState> {
	public constructor(props: MapFormDialogueProps) {
		super(props);
		this.state = {
			entries:
				props.initialEntries === undefined ? undefined : cloneArray(props.initialEntries),
		};
	}

	isStateValidForSubmission(): boolean {
		if (this.state.entries === undefined) {
			return this.props.initialEntries !== undefined;
		}

		if (!areMapFormEntriesValid(this.state.entries, this.props.allowDuplicateKeys ?? false)) {
			return false;
		}
		return (
			this.props.initialEntries === undefined ||
			!haveEqualContents(
				this.props.initialEntries,
				this.state.entries,
				(a, b) => a.key === b.key && a.value === b.value,
			)
		);
	}

	onUpdate(newEntries: MapFormEntry[] | undefined): void {
		this.setState({
			...this.state,
			entries: newEntries,
		});
	}

	/**
	 * {@inheritDoc react#React.Component.render}
	 * @override
	 */
	public override render(): React.ReactElement {
		const isStateValidForSubmission = this.isStateValidForSubmission();
		return (
			<Card>
				<MapForm
					initialEntries={this.props.initialEntries}
					formTitle={this.props.formTitle}
					keyLabel={this.props.keyLabel}
					valueLabel={this.props.valueLabel}
					allowDuplicateKeys={this.props.allowDuplicateKeys}
					onChange={(newList) => this.onUpdate(newList)}
				/>
				<FormSubmissionFooter
					onSubmit={() => this.props.onSubmit(this.state.entries)}
					onCancel={this.props.onCancel}
					submitDisabled={!isStateValidForSubmission}
				/>
			</Card>
		);
	}
}
