import { urlFormat } from '@datapad/utilities';
import type { BirthdayEvent } from './calendar/index.js';

/**
 * Represents a physical attribute of a {@link Character}
 */
export interface PhysicalAttribute {
	/**
	 * Name of the attribute
	 */
	name: string;

	/**
	 * Value of the attribute
	 */
	value: string;
}

/**
 * Represents a {@link Character} relationship with another Character.
 */
export interface Relationship {
	/**
	 * Kind of relationship (e.g. "Brother").
	 */
	kind: string;

	/**
	 * Name of the character the relationship is with.
	 */
	characterName: string;
}

/**
 * {@link Character} birthday
 */
export interface Birthday {
	/**
	 * Year (BBY)
	 */
	year: number;

	/**
	 * Day of the year.
	 * Must be on [0, 367]
	 */
	day: number;
}

// TODO: make upper case once DB has been fixed + schema validation has been added.
/**
 * Character status
 */
export enum CharacterStatus {
	Active = 'active',
	Deceased = 'deceased',
	Unknown = 'unknown',
}

/**
 * Populates {@link characterStatusOptions}
 */
function getCharacterStatusOptions(): CharacterStatus[] {
	const result = [];
	for (const [, value] of Object.entries(CharacterStatus)) {
		result.push(value);
	}
	return result;
}

/**
 * Pre-populated list of all options for {@link CharacterStatus}
 */
export const characterStatusOptions = getCharacterStatusOptions();

/**
 * Converts {@link CharacterStatus} to a nicely capitalized form.
 */
export function characterStatusToString(characterStatus?: CharacterStatus): string {
	switch (characterStatus) {
		case CharacterStatus.Active:
			return 'Active';
		case CharacterStatus.Deceased:
			return 'Deceased';
		case CharacterStatus.Unknown:
		case undefined:
			return 'Unknown';
		default:
			throw new Error(`Unrecognized CharacterStatus value: "${CharacterStatus}".`);
	}
}

/**
 * Character pronoun options
 */
export const pronounOptions = ['HE/HIM', 'SHE/HER', 'THEY/THEM'];

/**
 * Represents a Character in the world
 */
export interface Character {
	/**
	 * The character's name.
	 * Must be non-empty.
	 */
	name: string;

	/**
	 * Short variant of {@link Character.name}.
	 * Replaces many name renderings for brevity.
	 */
	shortName?: string;

	/**
	 * Other nicknames (not to be confused with {@link Character.shortName})
	 */
	nicknames?: string[];

	/**
	 * Aliases (for undercover operations and the like)
	 */
	aliases?: string[];
	species?: string; // undefined === "Unknown"
	speciesUrl?: string; // undefined => Use default url generation
	pronouns?: string; // undefined === "Unknown"
	homeworld?: string; // undefined === "Unknown"
	affiliations?: string[]; // undefined === "None"
	status?: CharacterStatus; // undefined === CharacterStatus.Unknown
	bio?: string; // Markdown formatted. undefined === no bio
	knownBy?: string[]; // undefined and empty === known by no one.
	universallyKnown?: boolean; // undefined === false.
	titles?: string[]; // undefined === no titles
	physicalAttributes?: PhysicalAttribute[]; // undefined === no attributes
	imageResourceName?: string; // undefined === use `name` as image resource base name
	summary?: string; // undefined === no summary
	notes?: string; // Markdown formatted. undefined === no notes
	relationships?: Relationship[]; // undefined => no relationships to display
	birthday?: Birthday;
}

/**
 * A player {@link Character}
 */
export interface PlayerCharacter extends Character {
	/**
	 * {@link Player.userName | User name} of the {@link Player} to whom this character belongs.
	 */
	player: string;
}

/**
 * A non-player {@link Character}
 */
export interface NonPlayerCharacter extends Character {
	// TODO?
}

/**
 * Returns whether or not the contact is a Droid.
 */
export function isDroid(character: Character): boolean {
	return character.species === 'Droid';
}

/**
 * Returns whether or not the contact is a {@link PlayerCharacter}.
 */
export function isPlayerCharacter(character: Character): character is PlayerCharacter {
	// Kind of sketchy, but it works
	return (character as PlayerCharacter)?.player !== undefined;
}

/**
 * Whether or not the character has any {@link Character.physicalAttributes}
 */
export function hasPhysicalAttributes(character: Character): boolean {
	return character.physicalAttributes !== undefined && character.physicalAttributes.length !== 0;
}

/**
 * Whether or not the character has any {@link Character.relationships}
 */
export function hasRelationships(character: Character): boolean {
	return character.relationships !== undefined && character.relationships.length !== 0;
}

/**
 * Whether or not the character has any {@link Character.titles}
 */
export function hasTitles(character: Character): boolean {
	return character.titles !== undefined && character.titles.length !== 0;
}

/**
 * Whether or not the character has any {@link Character.affiliations}
 */
export function hasAffiliations(character: Character): boolean {
	return character.affiliations !== undefined && character.affiliations.length !== 0;
}

/**
 * Whether or not the character has any {@link Character.bio}
 */
export function hasBio(character: Character): boolean {
	return character.bio !== undefined && character.bio.length !== 0;
}

/**
 * Whether or not the character has any {@link Character.notes}
 */
export function hasNotes(character: Character): boolean {
	return character.notes !== undefined && character.notes.length !== 0;
}

/**
 * Whether or not the character has any {@link Character.shortName}
 */
export function hasShortName(character: Character): boolean {
	return character.shortName !== undefined && character.shortName.length !== 0;
}

/**
 * Determines whether or not the contact has any faction affiliations
 */
export function getMaybeFirstFactionAffiliation(character: Character): string | undefined {
	return character.affiliations !== undefined && character.affiliations.length > 0
		? character.affiliations[0]
		: undefined;
}

/**
 * Gets the summary for the given character, if it has one.
 * Otherwise, returns the first title if the character has titles.
 * Otherwise returns undefined;
 */
export function getSummaryOrTitle(character: Character): string | undefined {
	return character.summary
		? character.summary
		: character.titles && character.titles.length !== 0
			? character.titles[0]
			: undefined;
}

/**
 * Gets the character's short name if it has one, otherwise the full name
 */
export function getShortNameOrName(character: Character): string {
	return hasShortName(character) ? character.shortName! : character.name;
}

/**
 * Gets the Wookieepedia link for the character's species, if one was specified
 */
export function getSpeciesLinkUrl(character: Character): string | undefined {
	if (character.speciesUrl) {
		return character.speciesUrl;
	}
	return character.species
		? `https://starwars.fandom.com/wiki/${character.species.replace(' ', '_')}`
		: undefined;
}

/**
 * Gets the Wookieepedia link for the character's homeworld, if one was specified
 */
export function getHomeworldLinkUrl(character: Character): string | undefined {
	return character.homeworld
		? `https://starwars.fandom.com/wiki/${character.homeworld.replace(' ', '_')}`
		: undefined;
}

/**
 * Gets the appropriate {@link CharacterStatus} from the provided string value, if possible.
 * Else, returns undefined.
 */
export function tryGharacterStatusFromString(value: string): CharacterStatus | undefined {
	const adjustedValue = value.toLocaleLowerCase();
	switch (adjustedValue) {
		case 'active':
			return CharacterStatus.Active;
		case 'deceased':
			return CharacterStatus.Deceased;
		case 'unknown':
			return CharacterStatus.Unknown;
		default:
			return undefined;
	}
}

/**
 * Gets the appropriate {@link CharacterStatus} from the provided string value, if possible.
 * Else, throws.
 */
export function getCharacterStatusFromStringOrThrow(value: string): CharacterStatus {
	const maybeResult = tryGharacterStatusFromString(value);
	if (maybeResult === undefined) {
		throw new Error(`Invalid CharacterStatus string received: "${value}".`);
	}
	return maybeResult;
}

/**
 * Contact key for URL search params
 */
const characterUrlSearchKey = 'character';

/**
 * Gets the appropriate character name in URL format (if any) from the current URL search component
 * @param searchParams - URL search string
 */
export function urlFormattedCharacterNameFromUrlSearch(
	searchParams: URLSearchParams,
): string | undefined {
	let characterNameUrlString: string | undefined;
	searchParams.forEach((value, key) => {
		if (key.toLocaleLowerCase() === characterUrlSearchKey) {
			characterNameUrlString = value;
		}
	});
	return characterNameUrlString;
}

/**
 * Builds the URL search parameters for the selected contact
 */
export function urlSearchFromCharacter(character?: Character): string {
	return urlSearchFromCharacterName(character?.name);
}

/**
 * Builds the URL search parameters for the selected contact
 */
export function urlSearchFromCharacterName(characterName?: string): string {
	if (characterName === undefined) {
		return '';
	}
	const characterUrlString = urlFormat(characterName);
	return `${characterUrlSearchKey}=${characterUrlString}`;
}

/**
 * Filters the list of characters to only those that are universally known.
 */
export function getUniversallyKnownCharacters(characters: readonly Character[]): Character[] {
	return characters.filter((character) => character.universallyKnown);
}

/**
 * Determines whether or not the specified {@link Character} is known by the specified {@link PlayerCharacter}.
 *
 * @remarks Includes characters that are universally known.
 */
export function isCharacterKnown(character: Character, playerCharacter: PlayerCharacter): boolean {
	if (character.universallyKnown) {
		return true;
	}

	// If the character is known by the player character, return true
	if (character.knownBy && character.knownBy.includes(playerCharacter.name)) {
		return true;
	}

	return false;
}

/**
 * Returns all provided {@link Character}s known by the specified {@link Player}
 */
export function getKnownCharacters(
	characters: readonly Character[],
	playerCharacter: PlayerCharacter | undefined,
): Character[] {
	if (playerCharacter === undefined) {
		return getUniversallyKnownCharacters(characters);
	}

	const knownCharacters = new Set<Character>();
	for (const character of characters) {
		if (isCharacterKnown(character, playerCharacter)) {
			knownCharacters.add(character);
		}
	}
	return Array.from(knownCharacters);
}

/**
 * Gets a list of {@link BirthdayEvent}s for all of the provided characters that specify a birth
 * date.
 */
export function getBirthdays(characters: readonly Character[]): BirthdayEvent[] {
	const result: BirthdayEvent[] = [];
	for (const character of characters) {
		if (character.birthday !== undefined) {
			result.push({
				character: character,
				date: {
					year: character.birthday.year,
					dayOfTheYear: character.birthday.day,
				},
			});
		}
	}
	return result;
}
