/**
 * Dispatch ID for the RefreshAppState action
 */
export const REFRESH_APP_STATE = 'REFRESH_APP_STATE';

/**
 * Typed dispatch ID for the RefreshAppState action
 */
export type REFRESH_APP_STATE = typeof REFRESH_APP_STATE;

/**
 * RefreshAppState action interface
 */
export interface RefreshAppState {
	type: REFRESH_APP_STATE;
}

/**
 * RefreshAppState {@link https://redux.js.org/basics/actions#action-creators | Action Creator}
 */
export function refreshAppState(): RefreshAppState {
	return {
		type: REFRESH_APP_STATE,
	};
}

/**
 * Refresh all app state
 */
export type RefreshAppStateFunction = () => void;

/**
 * Collection of action functions supported by the root app component.
 */
export interface Actions {
	/**
	 * {@inheritdoc LoadEventsFunction}
	 */
	refreshAppState: RefreshAppStateFunction;
}
