import type { Campaign } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import { ProfileContext, type SharedCampaignsMap, useCampaigns } from '../../../utilities';
import CampaignsApp from '../../Campaigns';

/**
 * {@link Campaigns} input props.
 */
export interface CampaignsProps {
	campaignsMap: SharedCampaignsMap;
}

/**
 * Contacts app wrapper that handles translation of Fluid-backed state
 */
export function Campaigns(props: CampaignsProps): React.ReactElement {
	const { campaignsMap } = props;

	const campaigns = useCampaigns(campaignsMap);

	const { profile } = assertNotUndefined(React.useContext(ProfileContext));

	if (!profile.dmMode) {
		throw new Error('Only DM may use Players app.');
	}

	/**
	 * Adds a new campaign to the SharedMap.
	 */
	function createCampaign(campaign: Campaign): void {
		campaignsMap.set(campaign.name, campaign);
	}

	/**
	 * Updates an existing campaign in the SharedMap.
	 */
	function editCampaign(campaign: Campaign): void {
		// Only update state if it still exists in the map.
		// Potentially prevents re-adding a campaign deleted by another user.
		if (campaignsMap.has(campaign.name)) {
			campaignsMap.set(campaign.name, campaign);
		}
	}

	/**
	 * Removes the provided campaign from the SharedMap.
	 */
	function deleteCampaign(campaign: Campaign): void {
		campaignsMap.delete(campaign.name);
	}

	return (
		<CampaignsApp
			campaigns={campaigns}
			createCampaign={createCampaign}
			deleteCampaign={deleteCampaign}
			editCampaign={editCampaign}
		/>
	);
}
