import { ConfirmationDialogue } from '@datapad/form-components';
import { ImageContainerShape } from '@datapad/image';
import {
	type Character,
	hasAffiliations,
	hasBio,
	hasNotes,
	hasPhysicalAttributes,
	hasRelationships,
	hasShortName,
	hasTitles,
	type Profile,
} from '@datapad/interfaces';
import { ModalCard } from '@datapad/utility-components';
import { Delete } from '@mui/icons-material';
import { Divider, Grid, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import { CharacterAffiliations } from './CharacterAffiliations.js';
import { CharacterBasics } from './CharacterBasics.js';
import { CharacterBio } from './CharacterBio.js';
import { CharacterImageForModal } from './CharacterImageModal.js';
import { CharacterKnownBy } from './CharacterKnownBy.js';
import { CharacterNotes } from './CharacterNotes.js';
import { CharacterPhysicalAttributes } from './CharacterPhysicalAttributes.js';
import { CharacterRelationships } from './CharacterRelationships.js';
import { CharacterTitles } from './CharacterTitles.js';
import type {
	CanDeleteCharacter,
	CanHandleCharacterLink,
	EditableCharacterProfileBaseProps,
} from './CommonProps.js';
import { CharacterImageVariant, renderCharacterImage } from './ImageUtilities.js';

/**
 * {@link CharacterProfile} input props
 */
export interface CharacterProfileProps
	extends EditableCharacterProfileBaseProps,
		CanHandleCharacterLink,
		CanDeleteCharacter<Character> {
	/**
	 * The signed-in user
	 */
	profile: Profile;

	/**
	 * Whether or not to display cards for missing data on the character.
	 */
	displayEmptyCards: boolean;
}

enum ModalStatus {
	None,
	DisplayImage,
	DeleteCharacterDialogue,
}

interface State {
	modalStatus: ModalStatus;
}

/**
 * Displays basic information about the provided Character, and affords making edits to
 * the backing data.
 *
 * @remarks
 * TODO: functional component
 */
export class CharacterProfile extends React.Component<CharacterProfileProps, State> {
	public constructor(props: CharacterProfileProps) {
		super(props);
		this.state = {
			modalStatus: ModalStatus.None,
		};
	}

	private setModalStatus(modalStatus: ModalStatus): void {
		this.setState({
			...this.state,
			modalStatus,
		});
	}

	private onSubmitEdit(updatedCharacter: Character): void {
		this.props.editCharacter(updatedCharacter);
		this.setModalStatus(ModalStatus.None);
	}

	public override render(): React.ReactElement {
		return (
			<>
				{this.renderMaybeModal()}
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						height: '100%',
						width: '100%',
						flex: '1',
						overflow: 'auto',
					}}
				>
					{this.renderProfile()}
				</div>
			</>
		);
	}

	private renderProfile(): React.ReactNode {
		const character = this.props.character;
		return (
			<div
				style={{
					width: '100%',
					height: '100%',
					justifyContent: 'center',
				}}
			>
				{this.renderHeader(character)}
				<Grid container alignItems="stretch" justifyContent="space-evenly" spacing={0}>
					{this.renderImage(character)}
					{this.props.displayEmptyCards || hasBio(character)
						? this.renderBio(character)
						: undefined}
					{this.renderBasics()}
					{this.props.profile.dmMode // Only display knownBy to DM
						? this.renderKnownBy(character)
						: undefined}
					{this.props.displayEmptyCards || hasTitles(character)
						? this.renderTitles(character)
						: undefined}
					{this.props.displayEmptyCards || hasAffiliations(character)
						? this.renderAffiliations(character)
						: undefined}
					{this.props.displayEmptyCards || hasPhysicalAttributes(character)
						? this.renderPhysicalAttributes(character)
						: undefined}
					{this.props.displayEmptyCards || hasRelationships(character)
						? this.renderRelationships(character)
						: undefined}
					{this.props.displayEmptyCards || hasNotes(character)
						? this.renderNotes(character)
						: undefined}
				</Grid>
			</div>
		);
	}

	private renderHeader(character: Character): React.ReactElement {
		// Only DM can delete
		const maybeDeleteButton = this.props.profile.dmMode ? (
			<IconButton onClick={() => this.setModalStatus(ModalStatus.DeleteCharacterDialogue)}>
				<Delete />
			</IconButton>
		) : (
			<div />
		);
		return (
			<div style={{ padding: '5px' }}>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<div />
					<div>
						<Typography variant="h4">{character.name}</Typography>
						{hasShortName(character) ? (
							<Typography variant="h5">{`"${character.shortName}"`}</Typography>
						) : (
							<></>
						)}
					</div>
					{maybeDeleteButton}
				</div>
				<Divider />
			</div>
		);
	}

	// TODO: size image dynamically
	private renderImage(character: Character): React.ReactElement {
		const image = renderCharacterImage(character.name, {
			maxHeightInPixels: 400,
			containerShape: ImageContainerShape.RoundedRectangle,
			variant: CharacterImageVariant.Profile,
			backgroundColor: 'white',
		});

		const imageWithOnClick = (
			<div
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						width: '100%',
						height: '100%',
						padding: '10px',
					}}
					onClick={() => this.setModalStatus(ModalStatus.DisplayImage)}
				>
					{image}
				</div>
			</div>
		);

		return this.renderSmallGridItem(imageWithOnClick);
	}

	private renderBasics(): React.ReactElement {
		return this.renderSmallGridItem(<CharacterBasics {...this.props} />);
	}

	private renderTitles(character: Character): React.ReactElement {
		return this.renderSmallGridItem(
			<CharacterTitles
				character={character}
				userCanEdit={this.props.userCanEdit}
				editCharacter={(character) => this.onSubmitEdit(character)}
			/>,
		);
	}

	private renderAffiliations(character: Character): React.ReactElement {
		return this.renderSmallGridItem(
			<CharacterAffiliations
				character={character}
				userCanEdit={this.props.userCanEdit}
				editCharacter={(character) => this.onSubmitEdit(character)}
			/>,
		);
	}

	private renderKnownBy(character: Character): React.ReactElement {
		return this.renderSmallGridItem(
			<CharacterKnownBy
				character={character}
				userCanEdit={this.props.userCanEdit}
				editCharacter={(character) => this.onSubmitEdit(character)}
				handleCharacterLink={this.props.handleCharacterLink}
			/>,
		);
	}

	private renderBio(character: Character): React.ReactElement {
		return this.renderLargeGridItem(
			<CharacterBio
				character={character}
				userCanEdit={this.props.userCanEdit}
				editCharacter={(character) => this.onSubmitEdit(character)}
			/>,
		);
	}

	private renderNotes(character: Character): React.ReactElement {
		return this.renderSmallGridItem(
			<CharacterNotes
				character={character}
				userCanEdit={this.props.userCanEdit}
				editCharacter={(character) => this.onSubmitEdit(character)}
			/>,
		);
	}

	private renderPhysicalAttributes(character: Character): React.ReactElement {
		return this.renderSmallGridItem(
			<CharacterPhysicalAttributes
				character={character}
				userCanEdit={this.props.userCanEdit}
				editCharacter={(character) => this.onSubmitEdit(character)}
			/>,
		);
	}

	private renderRelationships(character: Character): React.ReactElement {
		return this.renderSmallGridItem(
			<CharacterRelationships
				character={character}
				userCanEdit={this.props.userCanEdit}
				editCharacter={(character) => this.onSubmitEdit(character)}
				handleCharacterLink={this.props.handleCharacterLink}
			/>,
		);
	}

	private renderSmallGridItem(child: React.ReactElement): React.ReactElement {
		return (
			<Grid item xs={12} md={6} xl={4}>
				{child}
			</Grid>
		);
	}

	private renderLargeGridItem(child: React.ReactElement): React.ReactElement {
		return (
			<Grid item xs={12} xl={8}>
				{child}
			</Grid>
		);
	}

	private renderMaybeModal(): React.ReactElement {
		switch (this.state.modalStatus) {
			case ModalStatus.None:
				return <></>;
			case ModalStatus.DisplayImage:
				return this.renderImageModal();
			case ModalStatus.DeleteCharacterDialogue:
				return (
					<ModalCard open>
						<ConfirmationDialogue
							onConfirm={() => this.props.deleteCharacter(this.props.character)}
							onCancel={() => this.setModalStatus(ModalStatus.None)}
						>
							<Typography variant="body1">
								{`Are you sure you wish to delete "${this.props.character.name}"?`}
							</Typography>
							<br />
							<Typography variant="body2">
								{`Note that this action cannot be undone.`}
							</Typography>
						</ConfirmationDialogue>
					</ModalCard>
				);
			default:
				throw new Error(`Unrecognized ModalStatus value: "${this.state.modalStatus}".`);
		}
	}

	private renderImageModal(): React.ReactElement {
		const character = this.props.character;
		return (
			<Modal open onClose={() => this.setModalStatus(ModalStatus.None)}>
				<CharacterImageForModal character={character} />
			</Modal>
		);
	}
}
