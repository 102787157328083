import { type CanCreateCharacter, CharacterCreationForm } from '@datapad/character-components';
import type { PlayerCharacter } from '@datapad/interfaces';
import { ModalCard } from '@datapad/utility-components';
import { Add } from '@mui/icons-material';
import { AppBar, IconButton, Tooltip } from '@mui/material';
import React from 'react';

/**
 * Input props for {@link DMToolbar}
 */
export type DMToolbarProps = CanCreateCharacter<PlayerCharacter>;

/**
 * Modal dialogue status
 */
enum ModalStatus {
	None,
	Creating,
}

/**
 * {@link DMToolbar} state
 */
interface ToolbarState {
	modalStatus: ModalStatus;
}

/**
 * Profile app toolbar
 */
export class DMToolbar extends React.Component<DMToolbarProps, ToolbarState> {
	public constructor(props: DMToolbarProps) {
		super(props);
		this.state = {
			modalStatus: ModalStatus.None,
		};
	}

	private setModalStatus(modalStatus: ModalStatus): void {
		this.setState({
			...this.state,
			modalStatus,
		});
	}

	public override render(): React.ReactElement {
		return (
			<>
				<EditingModal
					modalStatus={this.state.modalStatus}
					onSubmit={this.props.createCharacter}
					onCancel={() => this.setModalStatus(ModalStatus.None)}
				/>
				<AppBar
					id="profile-toolbar-div"
					position="static"
					color="default"
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row-reverse',
						paddingTop: '2px',
						paddingBottom: '2px',
					}}
				>
					<Tooltip title="Add new Player Character">
						<IconButton
							onClick={() => {
								this.setModalStatus(ModalStatus.Creating);
							}}
						>
							<Add />
						</IconButton>
					</Tooltip>
				</AppBar>
			</>
		);
	}
}

interface EditingModalProps {
	modalStatus: ModalStatus;
	onCancel: () => void;
	onSubmit: (character: PlayerCharacter) => void;
}

/**
 * Editing modal dialogue.
 * Will be empty if the specified {@link ModalStatus} is {@link ModalStatus.None}.
 */
function EditingModal(props: EditingModalProps): React.ReactElement {
	let renderedDialogue: React.ReactElement | undefined;

	switch (props.modalStatus) {
		case ModalStatus.None:
			renderedDialogue = undefined;
			break;
		case ModalStatus.Creating:
			renderedDialogue = (
				<CharacterCreationForm
					playerCharacter={true}
					onSubmit={(character) => props.onSubmit(character as PlayerCharacter)}
					onCancel={props.onCancel}
				/>
			);
			break;
		default:
			throw new Error(`Unrecognized ModalStatus value: "${props.modalStatus}".`);
	}

	if (renderedDialogue === undefined) {
		return <></>;
	} else {
		return (
			<ModalCard
				open
				onClose={props.onCancel}
				style={{
					maxHeight: '95%',
				}}
			>
				{renderedDialogue}
			</ModalCard>
		);
	}
}
