import {
	getMaxImageDimensionInPixels,
	type ImageOptions,
	loadAndRenderImage,
} from '@datapad/image';
import { urlFormat } from '@datapad/utilities';
import type React from 'react';

// TODOs:
// - Take characters instead of names so we can use their image URL overrides again

/**
 * Utilities for interacting with image cloud storage
 */

/**
 * Base image for all URL assets.
 */
const baseImageUrl = 'https://datapadassets.blob.core.windows.net';

/**
 * Image size options for Character images. Correspond to hosted image variants.
 */
export enum CharacterImageSize {
	Full = 'full',
	Profile = 'profile',
	Thumbnail = 'thumbnail',
}

/**
 * Image size options for Faction images. Correspond to hosted image variants.
 */
export enum FactionImageSize {
	Small = 'small',
	Large = 'large',
}

export enum CharacterImageVariant {
	Full = 'full',
	Profile = 'profile',
}

/**
 * Options for image rendering.
 */
export interface CharacterImageOptions extends ImageOptions {
	/**
	 * Full or profile image display
	 */
	variant: CharacterImageVariant;
}

/**
 * Renders the emblem for the first faction in the specified list for which an image exists.
 */
export function renderFirstFactionEmblem(
	factionNames: string[],
	options: ImageOptions,
): React.ReactElement {
	const cleanedNames = factionNames.map((factionName) => urlFormat(factionName));

	const factionImageUrls: string[] = [];
	cleanedNames.forEach((cleanedName) => {
		const urls = getSizedFactionUrls(`${baseImageUrl}/factions/${cleanedName}`, options);
		factionImageUrls.push(...urls);
	});

	const key = cleanedNames.reduce((aggregate, current) => `${aggregate}-${current}`);

	return loadAndRenderImage(factionImageUrls, options, `${key}-image`);
}

/**
 * Renders the faction image for the specified faction.
 */
export function renderFactionEmblem(
	factionName: string,
	options: ImageOptions,
): React.ReactElement {
	const cleanedName = urlFormat(factionName);
	const factionImageUrls = getSizedFactionUrls(
		`${baseImageUrl}/factions/${cleanedName}`,
		options,
	);
	return loadAndRenderImage(factionImageUrls, options, `${cleanedName}-image`);
}

const characterPlaceholderImagePath = 'images/Missing-Contact-Image.png';

// TODO: timeout on load attempt.
/**
 * Renders the contact image for the specified contact if one exists. Otherwise displays
 * the `missing contact` image.
 */
export function renderCharacterImage(
	characterName: string,
	options: CharacterImageOptions,
): React.ReactElement {
	const cleanedName = urlFormat(characterName);
	const contactImageUrls = getSizedCharacterUrl(
		`${baseImageUrl}/contacts/${cleanedName}`,
		options,
	);
	const urls = [contactImageUrls, characterPlaceholderImagePath];
	return loadAndRenderImage(urls, options, `${cleanedName}-image`);
}

/**
 * Renders the character placeholder image
 */
export function renderCharacterPlaceholderImage(
	options: CharacterImageOptions,
): React.ReactElement {
	return loadAndRenderImage([characterPlaceholderImagePath], options, 'missing-contact-image');
}

/**
 * Gets the suggested image size(s) to query for based on the specified height.
 * Potentially returns multiple sizes, given in the order of recommendation.
 */
function getFactionImageSizes(options: ImageOptions): FactionImageSize[] {
	const maxImageDimensionInPixels = getMaxImageDimensionInPixels(
		options.maxHeightInPixels,
		options.maxWidthInPixels,
	);
	if (maxImageDimensionInPixels === undefined || maxImageDimensionInPixels > 250) {
		return [FactionImageSize.Large, FactionImageSize.Small];
	}
	return [FactionImageSize.Small, FactionImageSize.Large];
}

/**
 * Gets urls for the base url image at different sizes, in order based on size recommendations.
 */
function getSizedFactionUrls(urlBase: string, options: ImageOptions): string[] {
	const recommendedSizes = getFactionImageSizes(options);
	return recommendedSizes.map((size) => `${urlBase}-${size}.png`);
}

/**
 * Gets the suggested image size(s) to query for based on the specified height.
 * Potentially returns multiple sizes, given in the order of recommendation.
 */
function getCharacterImageSize(options: CharacterImageOptions): CharacterImageSize {
	if (options.variant === CharacterImageVariant.Full) {
		return CharacterImageSize.Full;
	}

	const maxImageDimensionInPixels = getMaxImageDimensionInPixels(
		options.maxHeightInPixels,
		options.maxWidthInPixels,
	);
	return maxImageDimensionInPixels === undefined || maxImageDimensionInPixels > 150
		? CharacterImageSize.Profile
		: CharacterImageSize.Thumbnail;
}

/**
 * Gets urls for the base url image at different sizes, in order based on size recommendations.
 */
function getSizedCharacterUrl(urlBase: string, options: CharacterImageOptions): string {
	const size = getCharacterImageSize(options);
	return `${urlBase}-${size}.png`;
}
