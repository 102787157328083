import { Button, Tooltip } from '@mui/material';
import React from 'react';

/**
 * Input props for {@link FormSubmissionFooter}
 */
interface FormSubmissionFooterProps {
	onSubmit: () => void;
	onCancel: () => void;
	submitDisabled?: boolean;
}

/**
 * Card footer with form submit and cancel buttons
 */
export function FormSubmissionFooter(props: FormSubmissionFooterProps): React.ReactElement {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				padding: '15px',
			}}
		>
			<Tooltip title="Submit changes">
				<Button
					color="primary"
					fullWidth={false}
					onClick={props.onSubmit}
					disabled={props.submitDisabled ?? false}
				>
					Submit
				</Button>
			</Tooltip>
			<Tooltip title="Cancel">
				<Button color="secondary" fullWidth={false} onClick={props.onCancel}>
					Cancel
				</Button>
			</Tooltip>
		</div>
	);
}
