import { haveEqualContents } from '@datapad/utilities';
import React from 'react';
import { areMapFormEntriesValid, MapForm, type MapFormEntry } from '../MapForm.js';
import { DataEntry, type DataEntryProps } from './DataEntry.js';

/**
 * Input props for {@link MapEntry}
 */
export interface MapEntryProps extends DataEntryProps<MapFormEntry[]> {
	/**
	 * {@inheritDoc MapEntry.keyLabel}
	 */
	keyLabel?: string;

	/**
	 * {@inheritDoc MapEntry.valueLabel}
	 */
	valueLabel?: string;

	/**
	 * {@inheritDoc MapEntry.allowDuplicateKeys}
	 */
	allowDuplicateKeys?: boolean;
}

export class MapEntry extends DataEntry<MapFormEntry[]> {
	/**
	 * Label for map entry key textbox
	 */
	public readonly keyLabel?: string;

	/**
	 * Label for map entry value textbox
	 */
	public readonly valueLabel?: string;

	/**
	 * Whether or not duplicate keys are allowed in the "map"
	 * undefined =\> false
	 */
	public readonly allowDuplicateKeys?: boolean;

	public constructor(props: MapEntryProps) {
		super(props);
		this.keyLabel = props.keyLabel;
		this.valueLabel = props.valueLabel;
	}

	public errorMessage(entries: MapFormEntry[] | undefined): string | undefined {
		const valid = areMapFormEntriesValid(entries, this.allowDuplicateKeys ?? false);
		return valid ? undefined : 'Both key and value of entry must be non-empty';
	}

	public hasValueChanged(entries: MapFormEntry[] | undefined): boolean {
		if (entries === undefined && this.initialValue === undefined) {
			return false;
		}
		if (entries === undefined || this.initialValue === undefined) {
			return true;
		}
		return haveEqualContents(
			entries,
			this.initialValue,
			(a, b) => a.key === b.key && a.value === b.value,
		);
	}

	/**
	 * {@inheritDoc DataEntry.renderForm}
	 */
	public renderForm(
		currentEntries: MapFormEntry[] | undefined,
		onChange: (newEntry: MapFormEntry[] | undefined) => void,
	): React.ReactElement {
		return (
			<MapForm
				formTitle={this.label}
				initialEntries={currentEntries as MapFormEntry[]}
				keyLabel={this.keyLabel}
				valueLabel={this.valueLabel}
				onChange={onChange}
			/>
		);
	}
}
