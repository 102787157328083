import { renderFactionEmblem } from '@datapad/character-components';
import { ImageContainerShape } from '@datapad/image';
import type { CalendarEvent } from '@datapad/interfaces';
import React from 'react';

/**
 * Renders a 40px circle with the icon of the first faction associated with the event.
 */
export function FactionIcon({ event }: { event: CalendarEvent }): React.ReactElement {
	const iconSizeInPixels = 32;
	const circleSizeInPixels = 40;

	const hasInvolvedFactions = (event.involvedFactions?.length ?? 0) !== 0;
	const maybeFactionImage = hasInvolvedFactions ? (
		renderFactionEmblem((event.involvedFactions as string[])[0], {
			maxHeightInPixels: iconSizeInPixels,
			maxWidthInPixels: iconSizeInPixels,
			containerShape: ImageContainerShape.Rectangle,
		})
	) : (
		<></>
	);

	return (
		<div
			style={{
				width: `${circleSizeInPixels}px`,
				height: `${circleSizeInPixels}px`,
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '50%',
			}}
		>
			{maybeFactionImage}
		</div>
	);
}
