import type { Campaign } from '../Campaign.js';
import type { Character } from '../Character.js';
import type { CalendarDate } from './Calendar.js';

/**
 * Event in the SW Calendar.
 */
export interface CalendarEvent {
	title: string;
	year: number;
	day: number;
	location?: string;
	locationLinkUrl?: string;
	description?: string;
	involvedFactions?: string[];
	kind: EventKind;

	/**
	 * The names of characters involved in the event
	 */
	characters?: string[];
}

export enum EventKind {
	Galaxy = 'galaxy',
	Party = 'party',
	Character = 'character',
}

/**
 * Populates {@link eventKindOptions}
 */
function getEventKindValues(): EventKind[] {
	const result = [];
	for (const [, value] of Object.entries(EventKind)) {
		result.push(value);
	}
	return result;
}

/**
 * Pre-populated list of all options for {@link EventKind}
 */
export const eventKindOptions = getEventKindValues();

/**
 * Gets the appropriate {@link EventKind} from the provided string.
 * Throws if invalid.
 */
export function eventKindFromString(value: string): EventKind {
	const valueLowerCase = value.toLocaleLowerCase();
	switch (valueLowerCase) {
		case 'galaxy':
			return EventKind.Galaxy;
		case 'party':
			return EventKind.Party;
		case 'character':
			return EventKind.Character;
		default:
			throw new Error(`Unrecognized EventKind string value: "${value}".`);
	}
}

/**
 * Creates a {@link CalendarDate} from a {@link CalendarEvent}
 */
export function dateFromEvent(event: CalendarEvent): CalendarDate {
	return { year: event.year, dayOfTheYear: event.day };
}

/**
 * Filters the list of input events to only those of type {@link EventKind.Galaxy}.
 */
export function getGalaxyEvents(events: readonly CalendarEvent[]): CalendarEvent[] {
	return events.filter((event) => event.kind === EventKind.Galaxy);
}

/**
 * Determines if the specified event is known to the specified character.
 */
export function isEventKnown(
	character: Character,
	campaign: Campaign | undefined,
	event: CalendarEvent,
): boolean {
	function wasCharacterInvolved(): boolean {
		return (
			event.characters !== undefined &&
			event.characters.some((eventCharacter) => eventCharacter === character.name)
		);
	}

	function wasForCampaignParty(): boolean {
		return (
			campaign !== undefined &&
			event.involvedFactions !== undefined &&
			event.involvedFactions.includes(campaign.party)
		);
	}

	switch (event.kind) {
		case EventKind.Character:
			return wasCharacterInvolved();
		case EventKind.Party:
			// Include party events if it corresponds with the session campaign's party,
			// or if the session character was involved,
			return wasCharacterInvolved() || wasForCampaignParty();
		case EventKind.Galaxy:
			// Always include galaxy events
			return true;
		default:
			throw new Error(`Unrecognized event kind: "${event.kind}".`);
	}
}

/**
 * Filters the list of events to those that the session character would be aware of.
 */
export function getEventsKnownToCharacter(
	character: Character,
	campaign: Campaign | undefined,
	events: readonly CalendarEvent[],
): CalendarEvent[] {
	return events.filter((event) => isEventKnown(character, campaign, event));
}
