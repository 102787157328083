import {
	type DataEntry,
	EditFormDialogue,
	ListEntry,
	NumberEntry,
	StringEntry,
} from '@datapad/form-components';
import { type Campaign, daysPerYear } from '@datapad/interfaces';
import { assertNotUndefined, assertUndefinedOrNonEmpty } from '@datapad/utilities';
import React from 'react';

/**
 * Input props for {@link CampaignCreationForm}
 */
export interface Props {
	/**
	 * Existing campaign to update. If set, form can be treated as an edit/update form.
	 * If not, form can be treated as a creation form.
	 */
	existingCampaign?: Campaign;
	onSubmit: (campaign: Campaign) => void;
	onCancel: () => void;
}

/**
 * Form for creating a new {@link Campaign}.
 * Intended for use in modal dialogues.
 */
export function CampaignCreationForm(props: Props): React.ReactElement {
	const schemas = campaignCreactionSchemas(props.existingCampaign);
	return (
		<EditFormDialogue
			title="Create New Campaign"
			schemas={schemas}
			onSubmit={(data) => props.onSubmit(campaignFromFormData(data))}
			onCancel={props.onCancel}
		/>
	);
}

/**
 * Creates the input schemas for the campaign creation form.
 */
function campaignCreactionSchemas(existingCampaign?: Campaign): Map<string, DataEntry> {
	function createElementId(formName: string): string {
		return `campaign-creation-${formName}-form`;
	}

	const schemas = new Map<string, DataEntry>();
	schemas.set(
		'name',
		new StringEntry({
			initialValue: existingCampaign?.name,
			label: 'Campaign Name',
			elementId: createElementId('name'),
			required: true,
			multiLine: false,
			locked: existingCampaign !== undefined, // Cannot edit username if editing an existing user
		}),
	);

	schemas.set(
		'party',
		new StringEntry({
			initialValue: existingCampaign?.party,
			label: 'Campaign Party',
			elementId: createElementId('party'),
			required: true,
			multiLine: false,
		}),
	);

	// TODO: add date entry
	schemas.set(
		'dateYear',
		new NumberEntry({
			initialValue: existingCampaign?.currentDate.year,
			label: 'Current year',
			elementId: createElementId('current-year'),
			required: true,
			decimals: false,
			min: 0,
		}),
	);
	schemas.set(
		'dateDay',
		new NumberEntry({
			initialValue: existingCampaign?.currentDate.dayOfTheYear,
			label: 'Current day',
			elementId: createElementId('current-day'),
			required: true,
			decimals: false,
			min: 0,
			max: daysPerYear - 1,
		}),
	);

	schemas.set(
		'characters',
		new ListEntry({
			initialValue: existingCampaign?.characters,
			label: 'Active Characters',
			elementId: createElementId('characters'),
		}),
	);

	schemas.set(
		'inactiveCharacters',
		new ListEntry({
			initialValue: existingCampaign?.inactiveCharacters,
			label: 'Inactive Characters',
			elementId: createElementId('inactive-characters'),
		}),
	);

	return schemas;
}

/**
 * Converts campaign-creation form data to a {@link Campaign}.
 */
function campaignFromFormData(data: Map<string, unknown>): Campaign {
	const name = assertNotUndefined(data.get('name') as string);
	const party = assertNotUndefined(data.get('party') as string);
	const dateYear = assertNotUndefined(data.get('dateYear') as number);
	const dateDay = assertNotUndefined(data.get('dateDay') as number);
	const characters = assertUndefinedOrNonEmpty(data.get('characters') as string[]);
	const inactiveCharacters = assertUndefinedOrNonEmpty(
		data.get('inactiveCharacters') as string[],
	);

	return {
		name,
		party,
		currentDate: { year: dateYear, dayOfTheYear: dateDay },
		characters: characters ?? [],
		inactiveCharacters,
	};
}
