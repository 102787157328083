import { Menu, Sync } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

/**
 * {@link Banner} input props
 */
interface Props {
	/**
	 * Function to invoke to open the datapad menu
	 */
	onOpenMenu?: () => void;

	/**
	 * Function to invoke to refresh all app state
	 */
	onRefreshAppState?: () => void;
}

/**
 * Datapad banner
 */
export function Banner(props: Props): React.ReactElement {
	const maybeBurgerButton =
		props.onOpenMenu === undefined ? (
			<></>
		) : (
			<Tooltip title="Expand menu">
				<IconButton
					onClick={() => props.onOpenMenu!()}
					style={{
						position: 'absolute',
						left: '2px',
						bottom: '2px',
					}}
				>
					<Menu />
				</IconButton>
			</Tooltip>
		);

	const maybeRefreshButton =
		props.onRefreshAppState === undefined ? (
			<></>
		) : (
			<Tooltip title="Refresh app state">
				<IconButton
					size="medium"
					onClick={() => props.onRefreshAppState!()}
					style={{
						position: 'absolute',
						right: '2px',
						bottom: '2px',
					}}
				>
					<Sync />
				</IconButton>
			</Tooltip>
		);
	return (
		<header
			className="App-header"
			style={{
				position: 'relative',
				backgroundColor: 'rgba(25, 25, 25)',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-around',
			}}
		>
			{maybeBurgerButton}
			<img
				src="images/Order-Of-The-Fallen-Logo-Long.svg"
				alt="Campaign logo"
				style={{
					height: '60px',
					marginTop: '15px',
					marginBottom: '15px',
					marginLeft: '5px',
					marginRight: '5px',
					pointerEvents: 'none',
					objectFit: 'scale-down',
				}}
			/>
			{maybeRefreshButton}
		</header>
	);
}
