import { ImageContainerShape } from '@datapad/image';
import React from 'react';
import type { CharacterProfileBaseProps } from './CommonProps.js';
import { CharacterImageVariant, renderCharacterImage } from './ImageUtilities.js';

/**
 * Renders a nearly full-screen render of the image for the specified character, centered over the
 * screen. Intended to be used in a Modal.
 */
export function CharacterImageForModal({
	character,
}: CharacterProfileBaseProps): React.ReactElement {
	const scalar = 0.85;
	const maxWidth = scalar * window.innerWidth;
	const maxHeight = scalar * window.innerHeight;
	return (
		<div
			style={{
				position: 'absolute',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%)',
			}}
		>
			{renderCharacterImage(character.name, {
				maxWidthInPixels: maxWidth,
				maxHeightInPixels: maxHeight,
				containerShape: ImageContainerShape.RoundedRectangle,
				variant: CharacterImageVariant.Full,
			})}
		</div>
	);
}
