import type { HasCharacterLink, HasDate } from '@datapad/common-props';
import {
	type BirthdayEvent,
	type CalendarEvent,
	weekdayOrHolidayFromDate,
} from '@datapad/interfaces';
import { urlFormat } from '@datapad/utilities';
import { Avatar, Card, TableCell, Typography } from '@mui/material';
import React from 'react';

/**
 * Input props for {@link CalendarCell}
 */
export interface Props extends HasCharacterLink, HasDate {
	/**
	 * Does this cell represent today's date
	 */
	isToday: boolean;

	/**
	 * Calendar events for the cell date.
	 */
	todaysEvents: CalendarEvent[];

	/**
	 * Birthdays for the cell date.
	 */
	todaysBirthdays: BirthdayEvent[];

	/**
	 * Function to invoke when the cell is selected.
	 */
	onSelect: () => void;
}

/**
 * Single cell in the calendar, representing a day of the month.
 */
export class CalendarCell extends React.Component<Props> {
	public constructor(props: Props) {
		super(props);
	}

	public override render(): React.ReactElement {
		return (
			<TableCell
				variant="body"
				padding="checkbox"
				style={{
					height: '100px', // TODO
					width: '20%',
					maxWidth: '0px', // Required to make contents trim correctly? Doesn't even seem to matter what the width actually is...
					minWidth: '0px',
					padding: '1px',
				}}
				size="small"
			>
				{this.renderCellInterior()}
			</TableCell>
		);
	}

	private renderCellInterior(): React.ReactElement {
		const events = this.props.todaysEvents;
		const birthdays = this.props.todaysBirthdays;
		const isToday = this.props.isToday;
		const weekdayOrHoliday = weekdayOrHolidayFromDate(this.props.date);
		const isHoliday = weekdayOrHoliday.isHoliday;

		const anyEvents = events.length !== 0;
		const anyBirthdays = birthdays.length !== 0;

		// 0-indexed under the hood, but we want to display 1 for the first day
		const dayOfMonth = weekdayOrHoliday.dayNumber + 1;
		return (
			<Card
				style={{
					height: '100%',
					maxHeight: '100px',
					background: isHoliday ? 'rgba(124, 252, 0, 0.1)' : undefined,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
				onClick={this.props.onSelect}
			>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-around',
					}}
				>
					<DayNumberBadge dayNumber={dayOfMonth} isTodaysDate={isToday} />
				</div>
				<div
					style={{
						flex: 1,
						padding: '5px',
						width: '100%',
					}}
				>
					{anyEvents ? renderEventList(events) : undefined}
					{anyBirthdays ? renderBirthdayList(birthdays) : undefined}
				</div>
				<div
					style={{
						padding: '5px',
						width: '100%',
					}}
				>
					{isHoliday ? weekdayOrHoliday.weekdayOrHolidayName : undefined}
				</div>
			</Card>
		);
	}
}

/**
 * Renders the provided list of events
 */
function renderEventList(events: CalendarEvent[]): React.ReactElement {
	return (
		<ul
			style={{
				marginTop: 0,
				paddingLeft: '20px',
			}}
		>
			{events.map((event) => (
				<li key={urlFormat(event.title)}>
					<Typography variant="body2" noWrap>
						{event.title}
					</Typography>
				</li>
			))}
		</ul>
	);
}

/**
 * Renders the provided list of birthdays
 */
function renderBirthdayList(birthdays: BirthdayEvent[]): React.ReactElement {
	return (
		<ul
			style={{
				marginTop: 0,
				paddingLeft: '20px',
			}}
		>
			{birthdays.map((birthday) => (
				<li key={urlFormat(birthday.character.name)}>
					<Typography variant="body2" noWrap color="primary">
						{`${birthday.character.name}'s birthday`}
					</Typography>
				</li>
			))}
		</ul>
	);
}

interface DayNumberBadgeProps {
	dayNumber: number;
	isTodaysDate: boolean;
}

/**
 * Renders the day number. In a blue circle if it is today's date.
 */
function DayNumberBadge(props: DayNumberBadgeProps): React.ReactElement {
	const { dayNumber, isTodaysDate } = props;
	return (
		<Avatar
			sx={{
				bgcolor: isTodaysDate ? 'primary.main' : 'transparent',
				color: 'text.primary',
			}}
		>
			<Typography variant="body2">{dayNumber}</Typography>
		</Avatar>
	);
}
