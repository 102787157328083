import type {
	CanGetCharacter,
	HasCalendarEvent,
	HasCalendarLink,
	HasCharacterLink,
	HasProfile,
} from '@datapad/common-props';
import { EventDetails } from '@datapad/event-components';
import { dateFromEvent } from '@datapad/interfaces';
import { Event, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	Card,
	CardActions,
	CardContent,
	Collapse,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';

/**
 * {@link TimelineEventCard} input props.
 */
export interface TimelineEventCardProps
	extends CanGetCharacter,
		HasCalendarEvent,
		HasCalendarLink,
		HasCharacterLink,
		HasProfile {
	isSelected: boolean;
	toggleSelection: () => void;
}

/**
 * The details card for a timeline event entry.
 */
export function TimelineEventCard(props: TimelineEventCardProps): React.ReactElement {
	const {
		event: timelineEvent,
		getCharacter,
		handleCalendarLink,
		handleCharacterLink,
		isSelected,
		profile,
		toggleSelection,
	} = props;

	const date = dateFromEvent(timelineEvent);

	return (
		<Card
			raised={true}
			sx={{
				minWidth: 250,
				overflow: 'hidden',
			}}
			onClick={(clickEvent) => {
				// Clicks on the card should not collapse the card.
				// Ensures that deselect event capture on container
				// does not immediately deselect the contact.
				clickEvent.stopPropagation();
			}}
		>
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					padding: '5px',
				}}
			>
				<div style={{ height: '100%' }} />

				<div
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-around',
					}}
				>
					<Typography variant="subtitle1" align="center">
						{timelineEvent.title}
					</Typography>
				</div>
				<div
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-around',
						paddingRight: '10px',
					}}
				>
					<Tooltip title={isSelected ? 'Close' : 'Expand'}>
						<IconButton
							onClick={(event) => {
								// Ensures that deselect event capture on container
								// does not immediately deselect the contact.
								event.stopPropagation();
								toggleSelection();
							}}
						>
							{isSelected ? <ExpandLess /> : <ExpandMore />}
						</IconButton>
					</Tooltip>
				</div>
			</div>
			<Collapse in={isSelected}>
				<CardContent
					sx={{
						textAlign: 'left',
					}}
				>
					<EventDetails
						profile={profile}
						event={timelineEvent}
						getCharacter={getCharacter}
						handleCharacterLink={handleCharacterLink}
					/>
				</CardContent>
				<CardActions>
					<Tooltip title="View in calendar app">
						<IconButton onClick={() => handleCalendarLink(date)}>
							<Event />
						</IconButton>
					</Tooltip>
				</CardActions>
			</Collapse>
		</Card>
	);
}
