import { CircularProgress } from '@mui/material';
import React from 'react';

/**
 * {@link LoadingScreen} input props
 */
export interface LoadingScreenProps {
	/**
	 * Text to display with the spinner.
	 */
	text?: string;
}

/**
 * Simple loading screen component
 */
export function LoadingScreen(props: LoadingScreenProps): React.ReactElement {
	const textDiv = props.text ? (
		<div
			style={{
				padding: '15px',
			}}
		>
			{props.text}
		</div>
	) : (
		<></>
	);

	const spinnerDiv = (
		<div
			style={{
				padding: '15px',
				textAlign: 'center',
			}}
		>
			<div
				style={{
					display: 'inline-block',
				}}
			>
				<CircularProgress color="primary" />
			</div>
		</div>
	);

	return (
		<div
			style={{
				flex: 1,
				textAlign: 'center',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{textDiv}
			{spinnerDiv}
		</div>
	);
}
