/**
 * Returns the provided value if defined. Else, returns the provided default value.
 */
export function getOrDefault<T>(value: T | undefined, defaultValue: T): T {
	return value ?? defaultValue;
}

/**
 * Noop function. Does nothing.
 */
export function noopFunction(): void {
	// noop
}

/**
 * Noop async function. Does nothing.
 */
export function asyncNoopFunction(): Promise<void> {
	return Promise.resolve();
}
