/**
 * ID for each sub-app supported by the Datapad.
 */
export enum AppId {
	Profile,
	GalaxyMap,
	Contacts,
	Calendar,
	Timeline,
	Players,
	Campaigns,
}
