import type {
	CanGetCharacter,
	CanModifyCurrentDate,
	HasBirthdayEvents,
	HasCalendarEvents,
	HasCharacterLink,
	HasProfile,
} from '@datapad/common-props';
import type { MonthOrFestivalWeek } from '@datapad/interfaces';
import React from 'react';
import type { CanCreateEvent, CanDeleteEvent, CanEditEvent } from './CommonProps.js';
import { CalendarGrid } from './components/CalendarGrid.js';

/**
 * {@link Calendar} input props.
 */
interface CalendarAppComponentProps
	extends HasProfile,
		HasCharacterLink,
		HasCalendarEvents,
		HasBirthdayEvents,
		CanGetCharacter,
		CanCreateEvent,
		CanDeleteEvent,
		CanEditEvent,
		CanModifyCurrentDate {
	/**
	 * Month or festival week to display.
	 */
	monthOrFestivalWeek: MonthOrFestivalWeek;

	/**
	 * The year (BBY)
	 * @privateRemarks TODO: Convert to AR dating
	 */
	year: number;

	/**
	 * Function to invoke when the month changes
	 */
	onChangeMonth: (monthOrFestivalWeek: MonthOrFestivalWeek, year: number) => void;
}

/**
 * Datapad Calendar app
 */
export function Calendar(props: CalendarAppComponentProps): React.ReactElement {
	return <CalendarGrid {...props} />;
}
