import { MarkdownTextbox } from '@datapad/markdown-textbox';
import React from 'react';

/**
 * Input props for {@link CharacterMarkdownTextbox}
 */
export interface CharacterMarkdownTextboxProps {
	/**
	 * The text to display.
	 */
	text: string;
}

/**
 * Markdown textbox for {@link @datapad/interfaces#Character} properties.
 */
export function CharacterMarkdownTextbox(props: CharacterMarkdownTextboxProps): React.ReactElement {
	return (
		<div
			style={{
				textAlign: 'left',
				padding: '15px',
				maxHeight: '300px',
				overflowY: 'auto',
			}}
		>
			<MarkdownTextbox text={props.text} />
		</div>
	);
}
