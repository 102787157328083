import { getFluidTenantId } from '@datapad/backend-interface';
import {
	AzureClient,
	type AzureClientProps,
	type AzureRemoteConnectionConfig,
} from '@fluidframework/azure-client';
import { NetlifyFunctionTokenProvider } from './NetlifyFunctionTokenProvider';

/**
 * Gets the configured AzureClient for a remote connection to the real service.
 */
export async function getAzureClient(userName: string): Promise<AzureClient> {
	const connectionConfig: AzureRemoteConnectionConfig = await getConfig(userName);

	const serviceConfig: AzureClientProps = {
		connection: connectionConfig,
	};

	return new AzureClient(serviceConfig);
}

/**
 * Gets the {@link AzureRemoteConnectionConfig} for the Datapad's Azure Fluid Relay service.
 */
async function getConfig(userName: string): Promise<AzureRemoteConnectionConfig> {
	const tenantId = await getFluidTenantId();
	if (!tenantId) {
		throw new Error('Failed to fetch Fluid tenant ID from backend.');
	}
	return {
		tenantId,
		tokenProvider: new NetlifyFunctionTokenProvider(userName),
		endpoint: 'https://us.fluidrelay.azure.com/',
		type: 'remote',
	};
}
