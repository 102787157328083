import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { CharacterInfoCard } from './CharacterDataCard.js';
import type { EditableCharacterProfileBaseProps } from './CommonProps.js';

/**
 * Base class for simple cards displaying tables of key-value pairs.
 */
export abstract class CharacterAttributeMapTable<
	TProps extends EditableCharacterProfileBaseProps = EditableCharacterProfileBaseProps,
> extends CharacterInfoCard<TProps> {
	protected constructor(props: TProps) {
		super(props);
	}

	/**
	 * The number of key-value pairs to be displayed.
	 */
	protected abstract getEntryCount(): number;

	/**
	 * {@inheritDoc CharacterInfoCard.getModalCardStyle}
	 */
	protected override getModalCardStyle(): React.CSSProperties {
		return {
			width: '95%',
			maxWidth: '600px',
			maxHeight: '95%',
		};
	}

	protected renderBodyContents(): React.ReactElement {
		if (this.getEntryCount() === 0) {
			return this.renderNoEntries();
		} else {
			return this.renderEntries();
		}
	}

	protected abstract renderKey(attributeIndex: number): React.ReactElement;
	protected abstract renderValue(attributeIndex: number): React.ReactElement;

	private renderNoEntries(): React.ReactElement {
		const divStyle = {
			width: '100%',
			padding: '10px',
		};
		return (
			<div style={divStyle}>
				<p>No data recorded</p>
			</div>
		);
	}

	private renderEntries(): React.ReactElement {
		const tableContents: React.ReactElement[] = [];
		const entryCount = this.getEntryCount();
		for (let i = 0; i < entryCount; i++) {
			tableContents.push(this.renderAttribute(i));
		}
		return (
			<Table>
				<TableBody>{tableContents}</TableBody>
			</Table>
		);
	}

	private renderAttribute(entryIndex: number): React.ReactElement {
		return (
			<TableRow key={`map-entry-row-${entryIndex}`}>
				<TableCell>{this.renderKey(entryIndex)}</TableCell>
				<TableCell>{this.renderValue(entryIndex)}</TableCell>
			</TableRow>
		);
	}
}
