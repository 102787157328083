import { haveEqualContents } from '@datapad/utilities';
import React from 'react';
import { ListForm } from '../ListForm.js';
import { DataEntry, type DataEntryProps } from './DataEntry.js';

/**
 * Input props for {@link ListEntry}
 */
export type ListEntryProps = DataEntryProps<readonly string[]>;

export class ListEntry extends DataEntry<readonly string[]> {
	public constructor(props: ListEntryProps) {
		super(props);
	}

	public errorMessage(values: readonly string[] | undefined): string | undefined {
		if (values === undefined) {
			return undefined;
		}
		for (const value of values) {
			if (value.length === 0) {
				return 'Entries must be non-empty';
			}
		}
		return undefined;
	}

	public hasValueChanged(values: readonly string[] | undefined): boolean {
		const emptyInitial = this.initialValue === undefined || this.initialValue.length === 0;
		const emptyNew = values === undefined || values.length === 0;

		if (emptyNew && emptyInitial) {
			return false;
		}
		if (emptyNew || emptyInitial) {
			return true;
		}

		// Verified above.

		return !haveEqualContents(values, this.initialValue!, (a, b) => a === b);
	}

	/**
	 * {@inheritDoc DataEntry.renderForm}
	 */
	public renderForm(
		currentValue: readonly string[] | undefined,
		onChange: (newValue: string[] | undefined) => void,
	): React.ReactElement {
		return (
			<ListForm
				formTitle={this.label}
				initialValues={currentValue as string[]}
				onChange={onChange}
			/>
		);
	}
}
