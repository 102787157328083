import { MultilineStringFormDialogue } from '@datapad/form-components';
import type { Character } from '@datapad/interfaces';
import { assertUndefinedOrNonEmpty } from '@datapad/utilities';
import { Typography } from '@mui/material';
import React from 'react';
import { CharacterInfoCard } from './CharacterDataCard.js';
import { CharacterMarkdownTextbox } from './CharacterMarkdownTextbox.js';
import type { EditableCharacterProfileBaseProps } from './CommonProps.js';

export class CharacterNotes extends CharacterInfoCard {
	/**
	 * {@inheritDoc CharacterInfoCard.headerText}
	 */
	public readonly headerText = 'Notes';

	public constructor(props: EditableCharacterProfileBaseProps) {
		super(props);
	}

	/**
	 * {@inheritDoc CharacterInfoCard.getModalCardStyle}
	 */
	protected override getModalCardStyle(): React.CSSProperties {
		return {
			width: '95%',
			maxWidth: '800px',
			maxHeight: '95%',
		};
	}

	async submitNotesEdits(newValue: string | undefined): Promise<void> {
		const characterUpdate: Character = {
			...this.props.character,
			notes: assertUndefinedOrNonEmpty(newValue),
		};
		await this.submitEdits(characterUpdate);
	}

	public renderBodyContents(): React.ReactElement {
		if (!this.props.character.notes) {
			return (
				<div>
					<Typography variant="body1">No character notes to display</Typography>
				</div>
			);
		}
		return <CharacterMarkdownTextbox text={this.props.character.notes} />;
	}

	public renderEditForm(): React.ReactElement {
		return (
			<MultilineStringFormDialogue
				formTitle="Edit Notes"
				existingValue={this.props.character.notes}
				onSubmit={(newValue) => this.submitNotesEdits(newValue)}
				onCancel={() => this.cancelEdit()}
				placeholder="Note: Markdown syntax is supported."
			/>
		);
	}
}
