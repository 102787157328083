import type {
	CanCreatePlayer,
	CanDeletePlayer,
	CanEditPlayer,
	HasCharacterLink,
	HasPlayers,
} from '@datapad/common-props';
import type { Player } from '@datapad/interfaces';
import { urlFormat } from '@datapad/utilities';
import React from 'react';
import { MissingPlayer } from './Constants.js';
import { Players as PlayersBase } from './components/Players.js';

/**
 * External input props for {@link Players}
 */
interface PlayersAppComponentProps
	extends HasCharacterLink,
		HasPlayers,
		CanEditPlayer,
		CanDeletePlayer,
		CanCreatePlayer {
	/**
	 * Current player selection (if any).
	 * URL formatted player userName.
	 */
	playerSelection: string | undefined;

	/**
	 * Changes the player selection to the one specified.
	 * `undefined` =\> default selection.
	 */
	changePlayerSelection(player: Player | undefined): void;
}

/**
 * Inner Players app component
 */
export function Players(props: PlayersAppComponentProps): React.ReactElement {
	const {
		players,
		playerSelection,
		changePlayerSelection,
		createPlayer,
		deletePlayer,
		editPlayer,
	} = props;

	let selectedPlayer: Player | MissingPlayer | undefined;
	if (playerSelection === undefined) {
		selectedPlayer = players.length === 0 ? undefined : players[0];
	} else {
		selectedPlayer =
			players.find((player) => urlFormat(player.userName) === playerSelection) ??
			MissingPlayer;
	}

	/**
	 * Adds the new player and selects it
	 */
	function onAddPlayer(player: Player): void {
		createPlayer(player);
		changePlayerSelection(player);
	}

	/**
	 * Deletes an existing player
	 */
	function onDeletePlayer(player: Player): void {
		deletePlayer(player);

		if (
			selectedPlayer === undefined ||
			selectedPlayer === MissingPlayer ||
			selectedPlayer.userName === player.userName
		) {
			changePlayerSelection(undefined);
		}
	}

	return (
		<PlayersBase
			players={players}
			selectedPlayer={selectedPlayer}
			changePlayerSelection={changePlayerSelection}
			handleCharacterLink={props.handleCharacterLink}
			createPlayer={onAddPlayer}
			deletePlayer={onDeletePlayer}
			editPlayer={editPlayer}
		/>
	);
}
