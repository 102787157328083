import { CardContent, Typography } from '@mui/material';
import React from 'react';

/**
 * {@link ErrorScreen} input props
 */
export interface ErrorScreenProps {
	/**
	 * Lines of text to be displayed. Line breaks will be inserted between each.
	 */
	textLines: string[];
}

/**
 * Error screen dialogue to display when an interactive edit fails to submit to the DB
 */
export function ErrorScreen(props: ErrorScreenProps): React.ReactElement {
	return (
		<CardContent
			style={{
				width: '100%',
			}}
		>
			{props.textLines.map((textLine, index) => (
				<Typography key={`error-text-line-${index}`} variant="body1">
					{textLine}
				</Typography>
			))}
		</CardContent>
	);
}
