import { assertNotUndefined } from '@datapad/utilities';
import { Divider, List, ListItem } from '@mui/material';
import React from 'react';
import { DatapadAppStateContext, ProfileContext } from '../../../../utilities';
import { urlSearchForDate } from '../../../Calendar';
import { AppId } from '../../AppId';
import { AppSelectionList } from './AppSelectionList';
import { CampaignDetails } from './CampaignDetails';
import { CharacterSelector } from './CharacterSelector';
import { MenuFooter } from './MenuFooter';
import { MenuHeader } from './MenuHeader';
import { ResourceLinksList } from './ResourceLinksList';

/**
 * {@link DatapadMenu} input props.
 */
export interface DatapadMenuProps {
	/**
	 * Selected application.
	 */
	appSelection: AppId;

	/**
	 * Function for signing the user out of the application.
	 */
	logout(): void;

	/**
	 * Hides (collapses) the menu view.
	 */
	hideMenu(): void;
}

/**
 * Datapad
 */
export function DatapadMenu(props: DatapadMenuProps): React.ReactElement {
	const { hideMenu, appSelection, logout } = props;

	const { profile, changeSignedInCharacter } = assertNotUndefined(
		React.useContext(ProfileContext),
	);

	const { changeAppSelection } = assertNotUndefined(React.useContext(DatapadAppStateContext));

	const campaign = profile.campaign;

	return (
		<List
			component="nav"
			disablePadding={true}
			style={{
				width: `325px`,
				height: '100%',
			}}
		>
			<ListItem>
				<MenuHeader hideMenu={hideMenu} />
			</ListItem>
			{profile.isPlayerGuest() ? (
				<></>
			) : (
				<ListItem>
					<CharacterSelector
						profile={profile}
						changeSelectedCharacter={(character) => {
							changeSignedInCharacter(character);
							changeAppSelection(AppId.Profile, undefined, 'open');
						}}
					/>
				</ListItem>
			)}
			{campaign === undefined ? (
				<></>
			) : (
				<>
					<Divider orientation="horizontal" />
					<ListItem>
						<CampaignDetails
							campaign={campaign}
							onClick={() => {
								changeAppSelection(
									AppId.Calendar,
									urlSearchForDate(campaign.currentDate),
								);
								hideMenu();
							}}
						/>
					</ListItem>
				</>
			)}
			<Divider orientation="horizontal" />
			<ListItem>
				<AppSelectionList
					profile={profile}
					currentAppSelection={appSelection}
					changeAppSelection={changeAppSelection}
				/>
			</ListItem>
			<Divider orientation="horizontal" />
			<ListItem>
				<ResourceLinksList />
			</ListItem>
			<Divider orientation="horizontal" />
			<ListItem>
				<MenuFooter logout={logout} />
			</ListItem>
		</List>
	);
}
