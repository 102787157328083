import { TextField } from '@mui/material';
import type { ChangeEvent } from 'react';
import React from 'react';

/**
 * Single-line text box rendering helper for forms.
 */
export function renderSingleLineTextBox(
	value: string,
	label: string | undefined,
	onChange: (newValue: string) => void,
	errorMessage?: string,
): React.ReactElement {
	const isEmpty = value.length === 0;
	if (isEmpty) {
		errorMessage = isEmpty ? 'Cannot be empty' : undefined;
	}
	return (
		<TextField
			color="primary"
			size="small"
			defaultValue={value}
			label={label}
			variant="outlined"
			multiline={false}
			error={errorMessage !== undefined}
			helperText={errorMessage}
			onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
				onChange(event.target.value)
			}
		/>
	);
}
