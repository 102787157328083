import { Campaigns as _Campaigns } from '@datapad/campaigns';
import type {
	CanCreateCampaign,
	CanDeleteCampaign,
	CanEditCampaign,
	HasCampaigns,
} from '@datapad/common-props';
import {
	type CalendarDate,
	type Campaign,
	urlFormattedCampaignNameFromUrlSearch,
	urlSearchFromCampaign,
} from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import { ErrorScreen } from '@datapad/utility-components';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DatapadAppStateContext, ProfileContext } from '../utilities';
import { urlSearchForDate } from './Calendar';
import { AppId } from './Datapad/AppId';
/**
 * {@link RoutedCampaigns} input props.
 */
export interface RoutedCampaignsProps
	extends HasCampaigns,
		CanEditCampaign,
		CanDeleteCampaign,
		CanCreateCampaign {}

/**
 * Campaigns component with URL routing.
 */
export function RoutedCampaigns(props: RoutedCampaignsProps): React.ReactElement {
	const { campaigns, editCampaign, deleteCampaign, createCampaign } = props;

	const location = useLocation();
	const history = useHistory();

	const { profile, changeSignedInCharacter } = assertNotUndefined(
		React.useContext(ProfileContext),
	);

	const { changeAppSelection, showMenu } = assertNotUndefined(
		React.useContext(DatapadAppStateContext),
	);

	if (!profile.dmMode) {
		return (
			<ErrorScreen
				textLines={['User not authorized.', 'Only the GM may view the Campaigns app.']}
			/>
		);
	}

	// Redirect to default character selection so that url reflects the default
	if (!location.search.length && campaigns.length !== 0) {
		const search = urlSearchFromCampaign(campaigns[0]);

		if (search.length === 0) {
			throw new Error(
				'Campaigns map is non-empty, but a selection could not be generated. This should not be possible.',
			);
		}

		history.replace({ search });
		return <></>;
	}

	/**
	 * Name of the selected campaign.
	 */
	const campaignSelection = urlFormattedCampaignNameFromUrlSearch(
		new URLSearchParams(location.search),
	);

	return (
		<_Campaigns
			campaigns={campaigns}
			campaignSelection={campaignSelection}
			changeCampaignSelection={(campaign: Campaign | undefined) => {
				const newSelection =
					campaign ?? (campaigns.length === 0 ? undefined : campaigns[0]);
				const search =
					newSelection === undefined ? undefined : urlSearchFromCampaign(newSelection);
				history.push({ search });
			}}
			handleCharacterLink={(characterName: string) => {
				// Characters specified in this app will always be player characters.
				// Handle link by changing active character, and moving to Profile app view.
				changeSignedInCharacter(characterName);
				changeAppSelection(AppId.Profile);
				showMenu();
			}}
			handleCalendarLink={(date: CalendarDate) => {
				const urlPathname = 'calendar';
				const urlSearch = urlSearchForDate(date);
				history.push({
					pathname: urlPathname,
					search: urlSearch,
				});
			}}
			editCampaign={editCampaign}
			createCampaign={createCampaign}
			deleteCampaign={deleteCampaign}
		/>
	);
}

export default RoutedCampaigns;
