import type {
	CanCreatePlayer,
	CanDeletePlayer,
	CanEditPlayer,
	HasPlayers,
} from '@datapad/common-props';
import {
	type Player,
	urlFormattedPlayerNameFromUrlSearch,
	urlSearchFromCharacterName,
	urlSearchFromPlayer,
} from '@datapad/interfaces';
import { Players } from '@datapad/players';
import { assertNotUndefined } from '@datapad/utilities';
import { ErrorScreen } from '@datapad/utility-components';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ProfileContext } from '../utilities';

/**
 * {@link RoutedPlayers} input props.
 */
export interface RoutedPlayersProps
	extends HasPlayers,
		CanEditPlayer,
		CanDeletePlayer,
		CanCreatePlayer {}

/**
 * Players app component with URL routing.
 */
export function RoutedPlayers(props: RoutedPlayersProps): React.ReactElement {
	const { players, editPlayer, deletePlayer, createPlayer } = props;

	const location = useLocation();
	const history = useHistory();

	const { profile } = assertNotUndefined(React.useContext(ProfileContext));

	if (!profile.dmMode) {
		return (
			<ErrorScreen
				textLines={['User not authorized.', 'Only the GM may view the Players app.']}
			/>
		);
	}

	// Redirect to default character selection so that url reflects the default
	if (!location.search.length && players.length !== 0) {
		const search = urlSearchFromPlayer(players[0]);

		if (search.length === 0) {
			throw new Error(
				'Players map is non-empty, but a selection could not be generated. This should not be possible.',
			);
		}

		history.replace({ search });
		return <></>;
	}

	/**
	 * Name of the selected player.
	 */
	const playerSelection = urlFormattedPlayerNameFromUrlSearch(
		new URLSearchParams(location.search),
	);

	return (
		<Players
			players={players}
			playerSelection={playerSelection}
			changePlayerSelection={(player: Player | undefined) => {
				const newSelection = player ?? (players.length === 0 ? undefined : players[0]);
				const search =
					newSelection === undefined ? undefined : urlSearchFromPlayer(newSelection);
				history.push({ search });
			}}
			handleCharacterLink={(characterName: string) => {
				const urlPathname = 'profile';
				const urlSearch = urlSearchFromCharacterName(characterName);
				history.push({
					pathname: urlPathname,
					search: urlSearch,
				});
			}}
			editPlayer={editPlayer}
			createPlayer={createPlayer}
			deletePlayer={deletePlayer}
		/>
	);
}

export default RoutedPlayers;
