import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import React from 'react';
import { FormSubmissionFooter } from './FormSubmissionFooter.js';
import type { DataEntry } from './data-types/DataEntry.js';

interface Props {
	title: string;
	schemas: Map<string, DataEntry>;
	onSubmit: (data: Map<string, unknown>) => void;
	onCancel: () => void;
}

interface State {
	entries: Map<string, unknown>;
}

/**
 * Form widget for editing data based on an input schema
 */
export class EditFormDialogue extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		const initialStateMap = new Map<string, unknown>();
		props.schemas.forEach((value, key) => {
			if (value.initialValue !== undefined) {
				initialStateMap.set(key, value.initialValue);
			}
		});

		this.state = {
			entries: initialStateMap,
		};
	}

	private isFormStateValid(): boolean {
		let isStateValid = true;
		let hasAnyValueChanged = false;
		this.props.schemas.forEach((schema, key) => {
			const state = this.state.entries.get(key);
			if (!schema.isValueValid(state)) {
				isStateValid = false;
			}
			if (schema.hasValueChanged(state)) {
				hasAnyValueChanged = true;
			}
		});
		return isStateValid && hasAnyValueChanged;
	}

	private updateState(key: string, data: unknown): void {
		const schema = this.props.schemas.get(key);

		if (!schema) {
			throw new Error(`Invalid state. No schema found for key: ${key}`);
		}

		const stateMap = new Map<string, unknown>(this.state.entries);
		stateMap.set(key, data);
		this.setState({
			...this.state,
			entries: stateMap,
		});
	}

	private onSubmit(): void {
		const validState = this.isFormStateValid();

		if (validState) {
			this.props.onSubmit(this.state.entries);
		} else {
			throw new Error(
				'Submit was called despite form being in an invalid state. This should not be possible.',
			);
		}
	}

	/**
	 * {@inheritDoc react#React.Component.render}
	 * @override
	 */
	public override render(): React.ReactNode {
		const childNodes: React.ReactNode[] = [];
		this.props.schemas.forEach((schema, key) => {
			const child = this.renderForm(key, schema);
			childNodes.push(
				<Grid item key={key}>
					{child}
				</Grid>,
			);
		});

		const isStateValidForSubmission = this.isFormStateValid();

		return (
			<Card>
				<CardHeader title={this.props.title} />
				<CardContent>
					<Grid container spacing={3}>
						{childNodes}
					</Grid>
				</CardContent>
				<FormSubmissionFooter
					onSubmit={() => this.onSubmit()}
					onCancel={this.props.onCancel}
					submitDisabled={!isStateValidForSubmission}
				/>
			</Card>
		);
	}

	public renderForm(key: string, dataEntry: DataEntry): React.ReactNode {
		const currentValue = this.state.entries.get(key);

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		const onChangeFunction = (newValue: unknown) => this.updateState(key, newValue);

		return dataEntry.renderForm(currentValue, onChangeFunction);
	}
}
