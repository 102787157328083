import { Card, CardHeader, TextField } from '@mui/material';
import React, { type ChangeEvent } from 'react';
import { FormSubmissionFooter } from './FormSubmissionFooter.js';

/**
 * Input props for {@link MultilineStringFormDialogue}
 */
export interface MultilineStringFormDialogueProps {
	formTitle: string;
	existingValue: string | undefined;
	onSubmit: (newValue: string | undefined) => void;
	onCancel: () => void;

	/**
	 * Whether or not an empty string is allowed to be submitted.
	 */
	required?: boolean;

	elementId?: string;
	placeholder?: string;
}

/**
 * {@link MultilineStringFormDialogue} internal state
 */
interface MultilineStringFormDialogueState {
	value: string | undefined;
}

/**
 * String list form
 */
export class MultilineStringFormDialogue extends React.Component<
	MultilineStringFormDialogueProps,
	MultilineStringFormDialogueState
> {
	public constructor(props: MultilineStringFormDialogueProps) {
		super(props);
		this.state = {
			value: props.existingValue,
		};
	}

	onUpdate(newValue: string | undefined): void {
		this.setState({
			...this.state,
			value: newValue,
		});
	}

	/**
	 * {@inheritDoc react#React.Component.render}
	 * @override
	 */
	public override render(): React.ReactElement {
		const isStateValidForSubmission =
			!this.props.required ||
			(this.state.value !== undefined && this.state.value.length !== 0);
		return (
			<Card>
				<CardHeader title={this.props.formTitle} />
				<TextField
					defaultValue={this.state.value}
					id={this.props.elementId}
					variant="outlined"
					multiline={true}
					error={!isStateValidForSubmission}
					helperText={isStateValidForSubmission ? undefined : 'Value must be non-empty.'}
					placeholder={this.props.placeholder}
					onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
						this.onUpdate(event.target.value)
					}
					fullWidth={true}
				/>
				<FormSubmissionFooter
					onSubmit={() => this.props.onSubmit(this.state.value)}
					onCancel={this.props.onCancel}
					submitDisabled={!isStateValidForSubmission}
				/>
			</Card>
		);
	}
}
