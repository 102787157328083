import { type CanHandleCharacterLink, renderFactionEmblem } from '@datapad/character-components';
import type {
	CanGetCharacter,
	HasCalendarEvents,
	HasCalendarLink,
	HasCharacterLink,
	HasProfile,
} from '@datapad/common-props';
import { ImageContainerShape } from '@datapad/image';
import {
	type CalendarDate,
	type CalendarEvent,
	compareDates,
	dateFromEvent,
	toShortString,
} from '@datapad/interfaces';
import { sortArray } from '@datapad/utilities';
import {
	Timeline as MuiTimeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
} from '@mui/lab';
import { Typography } from '@mui/material';
import React from 'react';
import { TimelineEventCard } from './components/TimelineEventCard.js';
import { Toolbar } from './components/Toolbar.js';

// TODO: function components

export interface TimelineProps
	extends HasProfile,
		HasCalendarEvents,
		HasCharacterLink,
		HasCalendarLink,
		CanGetCharacter,
		CanHandleCharacterLink {}

export interface TimelineAppComponentState {
	/**
	 * Sorting. true =\> ascending, false =\> descending.
	 */
	sortAscending: boolean;

	/**
	 * Selected event title.
	 */
	selectedEvent?: string;
}

export class Timeline extends React.Component<TimelineProps, TimelineAppComponentState> {
	public constructor(props: TimelineProps) {
		super(props);
		this.state = {
			sortAscending: true,
			selectedEvent: undefined,
		};
	}

	private getSortedEvents(): CalendarEvent[] {
		if (this.props.events === undefined) {
			throw new Error('Events not loaded yet.');
		}
		return sortArray(this.props.events, (a, b) => {
			const dateA = dateFromEvent(a);
			const dateB = dateFromEvent(b);
			const compare = compareDates(dateA, dateB);
			return this.state.sortAscending ? compare : -compare;
		});
	}

	private isSelected(event: CalendarEvent): boolean {
		return event.title === this.state.selectedEvent;
	}

	private selectEvent(event: CalendarEvent): void {
		this.setState({
			...this.state,
			selectedEvent: event.title,
		});
	}

	private deselectEvent(): void {
		this.setState({
			...this.state,
			selectedEvent: undefined,
		});
	}

	public override render(): React.ReactNode {
		const sortedEvents = this.getSortedEvents();
		const renderedEvents = sortedEvents.map((event) => this.renderTimelineElement(event));

		return (
			<div
				style={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Toolbar />
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						overflow: 'auto',
						scrollbarWidth: 'thin',
					}}
				>
					<div
						style={{
							maxWidth: '1000px',
						}}
					>
						<MuiTimeline style={{ padding: '3px' }} position={'alternate'}>
							{renderedEvents}
						</MuiTimeline>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Renders a timeline element with the specified content and icon.
	 */
	private renderTimelineElement(timelineEvent: CalendarEvent): React.ReactNode {
		const date = dateFromEvent(timelineEvent);
		const isSelected = this.isSelected(timelineEvent);

		const toggleSelection = (): void => {
			if (isSelected) {
				this.deselectEvent();
			} else {
				this.selectEvent(timelineEvent);
			}
		};

		return (
			<TimelineItem key={`timeline-event-${timelineEvent.title}`}>
				<TimelineOppositeContent>
					<div style={{ width: '0px' }} />
				</TimelineOppositeContent>
				<TimelineSeparator>
					{this.renderEventNode(timelineEvent, date)}
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent>
					<TimelineEventCard
						event={timelineEvent}
						isSelected={isSelected}
						toggleSelection={toggleSelection}
						getCharacter={this.props.getCharacter}
						handleCalendarLink={this.props.handleCalendarLink}
						handleCharacterLink={this.props.handleCharacterLink}
						profile={this.props.profile}
					/>
				</TimelineContent>
			</TimelineItem>
		);
	}

	private renderEventNode(timelineEvent: CalendarEvent, date: CalendarDate): React.ReactNode {
		const iconSizeInPixels = 40;

		const hasInvolvedFactions = (timelineEvent.involvedFactions?.length ?? 0) !== 0;
		const factionImage = hasInvolvedFactions ? (
			renderFactionEmblem((timelineEvent.involvedFactions as string[])[0], {
				maxHeightInPixels: iconSizeInPixels,
				maxWidthInPixels: iconSizeInPixels,
				containerShape: ImageContainerShape.Rectangle,
			})
		) : (
			<></>
		);

		const isSelected = this.isSelected(timelineEvent);

		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
					onClick={(clickEvent) => {
						if (isSelected) {
							this.deselectEvent();
						} else {
							this.selectEvent(timelineEvent);
						}
						// Ensures that deselect event capture on container
						// does not immediately deselect the contact.
						clickEvent.stopPropagation();
					}}
				>
					<TimelineDot variant="outlined" color={isSelected ? 'primary' : 'grey'}>
						<div
							style={{
								height: `${iconSizeInPixels}px`,
								width: `${iconSizeInPixels}px`,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<div>{factionImage}</div>
						</div>
					</TimelineDot>
				</div>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<Typography variant="body2" color="textSecondary">
						{toShortString(date)}
					</Typography>
				</div>
			</div>
		);
	}
}
