import { cloneMap } from '@datapad/utilities';
import { Add, Delete } from '@mui/icons-material';
import { Card, CardActions, CardHeader, IconButton, List, ListItem, Tooltip } from '@mui/material';
import React from 'react';
import { renderSingleLineTextBox } from './Utilities.js';

/**
 * {@link ListForm} input props.
 */
export interface ListFormProps {
	formTitle: string;
	entryLabel?: string;
	initialValues: readonly string[] | undefined;
	onChange: (newValues: string[] | undefined) => void;
}

/**
 * {@link ListForm} internal state.
 */
export interface ListFormState {
	values: Map<number, string>;

	/**
	 * The next key to associate with entries.
	 *
	 * @remarks
	 *
	 * Monotonically increasing.
	 *
	 * Used as a stable React key for list entries.
	 */
	nextKey: number;
}

/**
 * String list form
 */
export class ListForm extends React.Component<ListFormProps, ListFormState> {
	public constructor(props: ListFormProps) {
		super(props);

		const values = new Map<number, string>();
		if (props.initialValues !== undefined) {
			props.initialValues.forEach((value, index) => {
				values.set(index, value);
			});
		}

		this.state = {
			values,
			nextKey: values.size,
		};
	}

	/**
	 * Function to call to update the value of an existing list entry.
	 * @param key - Key of the entry to update.
	 * @param newValue - New value for the list entry. Will override the existing value.
	 */
	onUpdateEntry(key: number, newValue: string): void {
		const newValuesMap = cloneMap(this.state.values);
		newValuesMap.set(key, newValue);
		this.setState({
			...this.state,
			values: newValuesMap,
		});

		this.props.onChange([...newValuesMap.values()]);
	}

	/**
	 * Function to call to delete the list entry at the provided index.
	 * @param key - Key of the entry to delete.
	 */
	onDeleteEntry(key: number): void {
		const newValuesMap = cloneMap(this.state.values);
		newValuesMap.delete(key);
		this.setState({
			...this.state,
			values: newValuesMap,
		});

		this.props.onChange([...newValuesMap.values()]);
	}

	/**
	 * Function to call when a new list entry is to be added.
	 * Pushes an empty string to the end of the list.
	 */
	onAddNewEntry(): void {
		const newValuesMap = cloneMap(this.state.values);
		newValuesMap.set(this.state.nextKey, '');
		this.setState({
			...this.state,
			nextKey: this.state.nextKey + 1,
			values: newValuesMap,
		});

		this.props.onChange([...newValuesMap.values()]);
	}

	/**
	 * {@inheritDoc react#React.Component.render}
	 * @override
	 */
	public override render(): React.ReactElement {
		const renderedEditList = this.renderList();
		return (
			<Card
				style={{
					minWidth: '250px',
				}}
			>
				<CardHeader title={this.props.formTitle}></CardHeader>
				{renderedEditList}
				<CardActions>{this.renderAddNewButton()}</CardActions>
			</Card>
		);
	}

	private renderAddNewButton(): React.ReactElement {
		return (
			<Tooltip title="Add new entry">
				<IconButton size="small" onClick={() => this.onAddNewEntry()}>
					<Add />
				</IconButton>
			</Tooltip>
		);
	}

	/**
	 * Renders the values as a list of string form entries, if any were provided.
	 */
	private renderList(): React.ReactElement {
		if (this.state.values.size === 0) {
			return <></>;
		}

		const renderedChildForms: React.ReactElement[] = [];
		this.state.values.forEach((value, key) => {
			renderedChildForms.push(this.renderListEntryForm(value, key));
		});

		return <List>{renderedChildForms}</List>;
	}

	private renderListEntryForm(value: string, key: number): React.ReactElement {
		const isEmpty = value.length === 0;
		const maybeErrorMessage = isEmpty ? 'Cannot be empty' : undefined;
		return (
			<ListItem
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					padding: '5px',
				}}
				key={`list-form-element-${key}`}
			>
				{renderSingleLineTextBox(
					value,
					this.props.entryLabel,
					(newValue) => this.onUpdateEntry(key, newValue),

					maybeErrorMessage,
				)}
				<Tooltip title="Delete">
					<IconButton size="small" onClick={() => this.onDeleteEntry(key)}>
						<Delete />
					</IconButton>
				</Tooltip>
			</ListItem>
		);
	}
}
