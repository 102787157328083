import { ListFormDialogue } from '@datapad/form-components';
import { ImageContainerShape } from '@datapad/image';
import type { Character } from '@datapad/interfaces';
import { urlFormat } from '@datapad/utilities';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { CharacterInfoCard } from './CharacterDataCard.js';
import type { EditableCharacterProfileBaseProps } from './CommonProps.js';
import { renderFactionEmblem } from './ImageUtilities.js';

const divStyle = {
	width: '100%',
	padding: '10px',
};

export class CharacterAffiliations extends CharacterInfoCard<EditableCharacterProfileBaseProps> {
	/**
	 * {@inheritDoc CharacterInfoCard.headerText}
	 */
	public readonly headerText = 'Affiliations';

	public constructor(props: EditableCharacterProfileBaseProps) {
		super(props);
	}

	async onSubmitAffilationChanges(newAffiliationsList: string[] | undefined): Promise<void> {
		const characterUpdate: Character = {
			...this.props.character,
			affiliations: newAffiliationsList,
		};

		await this.submitEdits(characterUpdate);
	}

	public renderBodyContents(): React.ReactElement {
		const affiliations = this.props.character.affiliations;
		if (!affiliations || affiliations.length === 0) {
			return this.renderNoAffiliations();
		}
		return this.renderAffiliations(affiliations);
	}

	private renderAffiliations(affiliations: string[]): React.ReactElement {
		const affiliationEntries = affiliations.map((affiliation) => {
			const affilliationImage = renderFactionEmblem(affiliation, {
				maxWidthInPixels: 40,
				maxHeightInPixels: 40,
				containerShape: ImageContainerShape.RoundedRectangle,
			});
			return (
				<TableRow key={`affiliation-${urlFormat(affiliation)}`}>
					<TableCell align="left">
						<Typography variant="body2">{affiliation}</Typography>
					</TableCell>
					<TableCell align="center">{affilliationImage}</TableCell>
				</TableRow>
			);
		});
		return (
			<Table>
				<TableBody>{affiliationEntries}</TableBody>
			</Table>
		);
	}

	private renderNoAffiliations(): React.ReactElement {
		return (
			<div style={divStyle}>
				<p>No known affiliations</p>
			</div>
		);
	}

	public renderEditForm(): React.ReactElement {
		return (
			<ListFormDialogue
				formTitle="Edit Affiliations"
				entryLabel="Affiliation"
				initialValues={this.props.character.affiliations ?? []}
				onSubmit={(newAffiliationsList) =>
					this.onSubmitAffilationChanges(newAffiliationsList)
				}
				onCancel={() => this.cancelEdit()}
			/>
		);
	}
}
