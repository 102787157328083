import type {
	CanCreatePlayer,
	CanDeletePlayer,
	CanEditPlayer,
	HasCharacterLink,
	HasPlayers,
} from '@datapad/common-props';
import type { Player } from '@datapad/interfaces';
import { ErrorScreen } from '@datapad/utility-components';
import React from 'react';
import { MissingPlayer } from '../Constants.js';
import { PlayerView } from './PlayerView.js';
import { Toolbar } from './Toolbar.js';

/**
 * {@link Players} input props.
 */
export interface PlayersProps
	extends HasPlayers,
		CanCreatePlayer,
		CanEditPlayer,
		CanDeletePlayer,
		HasCharacterLink {
	/**
	 * The selected Player to view
	 */
	selectedPlayer: Player | MissingPlayer | undefined;

	/**
	 * Function to invoke to change player selection.
	 * Undefined =\> refresh selection to default
	 */
	changePlayerSelection: (newPlayerSelection: Player | undefined) => void;
}

/**
 * View that displays information about Players and affords editing / creating / deleting them.
 */
export function Players(props: PlayersProps): React.ReactElement {
	const {
		players,
		selectedPlayer,
		changePlayerSelection,
		createPlayer,
		deletePlayer,
		editPlayer,
		handleCharacterLink,
	} = props;

	let innerView: React.ReactElement;
	if (selectedPlayer === MissingPlayer) {
		innerView = <MissingPlayerErrorScreen />;
	} else if (players.length === 0) {
		innerView = <NoPlayersErrorScreen />;
	} else {
		innerView = (
			<PlayerView
				player={selectedPlayer ?? players[0]}
				canEdit
				editPlayer={editPlayer}
				deletePlayer={deletePlayer}
				handleCharacterLink={handleCharacterLink}
			/>
		);
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
			}}
		>
			<Toolbar
				players={players}
				selectedPlayer={selectedPlayer === MissingPlayer ? undefined : selectedPlayer}
				onChangePlayerSelection={changePlayerSelection}
				createPlayer={createPlayer}
			/>
			<div>{innerView}</div>
		</div>
	);
}

/**
 * Error page to display if no players were loaded
 */
export function NoPlayersErrorScreen(): React.ReactElement {
	return (
		<ErrorScreen
			textLines={[
				'No players found.',
				'This is very unlikely and probably indicates an error.',
				'Since only the GM (you) and see this... fix it!',
			]}
		/>
	);
}

/**
 * Error page to display if the selected player does not exist.
 */
export function MissingPlayerErrorScreen(): React.ReactElement {
	return (
		<ErrorScreen
			textLines={[
				`The selected player does not exist.`,
				'This could indicate an error, or could indicate that the selected player has been deleted.',
			]}
		/>
	);
}
