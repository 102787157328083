import { Card } from '@mui/material';
import React from 'react';

/**
 * Input props for {@link DialogueCard}
 */
export interface DialogueCardProps {
	/**
	 * Child nodes for the {@link DialogueCard}
	 */
	children?: React.ReactNode;

	/**
	 * Style properties to apply to the card.
	 * Some aspects will be overriden (see {@link DialogueCard}).
	 */
	style?: React.CSSProperties;
}

/**
 * {@link Card} formatted for use as a dialogue.
 */
export function DialogueCard(props: DialogueCardProps): React.ReactElement {
	return (
		<Card
			variant="elevation"
			style={{
				...props.style,
				position: 'absolute',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%)',
				maxHeight: '95%',
				overflow: 'auto',
			}}
		>
			{props.children}
		</Card>
	);
}
