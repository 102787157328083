/**
 * Clones the provided array.
 */
export function cloneArray<T>(input: readonly T[]): T[] {
	return input.map((value) => value);
}

/**
 * Sorting helper that does not act in place (incurs a copy).
 * Returns a sorted copy of the input array based on the provided comparator.
 */
export function sortArray<T>(input: readonly T[], comparator: (a: T, b: T) => number): T[] {
	// Clone array to avoid in-place sort of provided array
	const copy = cloneArray(input);
	return copy.sort(comparator);
}

/**
 * Checks if two arrays have the same contents
 */
export function haveEqualContents<T>(
	a: readonly T[],
	b: readonly T[],
	areEqual?: (aValue: T, bValue: T) => boolean,
): boolean {
	if (a.length !== b.length) {
		return false;
	}

	if (areEqual === undefined) {
		areEqual = (aValue: T, bValue: T) => aValue === bValue;
	}

	for (let i = 0; i < a.length; i++) {
		if (!areEqual(a[i], b[i])) {
			return false;
		}
	}
	return true;
}

/**
 * Checks if two arrays have any intersection.
 * I.e. have any 1 or more members in common per the provided equality check.
 */
export function doArraysIntersect<T>(
	a: readonly T[],
	b: readonly T[],
	areEqual?: (aValue: T, bValue: T) => boolean,
): boolean {
	if (areEqual === undefined) {
		areEqual = (aValue: T, bValue: T) => aValue === bValue;
	}

	return a.some((aMember) => b.some((bMember) => areEqual!(aMember, bMember)));
}
