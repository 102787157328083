import { Paper } from '@mui/material';
import React, { type PropsWithChildren } from 'react';

/**
 * {@link DatapadPaper} input props.
 */
export type DatapadPaperProps = PropsWithChildren;

/**
 * Root paper (container) used by the datapad. Encapsulates shared styling details, etc.
 */
export function DatapadPaper(props: DatapadPaperProps): React.ReactElement {
	const { children } = props;

	return (
		<Paper
			square={true}
			style={{
				display: 'flex',
				flexDirection: 'column',
				position: 'absolute',
				width: '100%',
				height: '100%',
			}}
		>
			{children}
		</Paper>
	);
}
