/**
 * App node export for Datapad
 */

import { combineReducers } from 'redux';
import RoutedDatapad from './DatapadApp';
import { reducer as datapadReducers } from './State';

export default RoutedDatapad;

export { Banner as DatapadBanner } from './Components/Banner';
export type { AppState as DatapadAppState } from './State';

export const reducers = combineReducers({
	datapad: datapadReducers,
});
