import { MapFormDialogue, type MapFormEntry } from '@datapad/form-components';
import type { Character, Relationship } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import { CharacterAttributeMapTable } from './CharacterAttributeMapTable.js';
import { CharacterListEntry } from './CharacterList.js';
import type { CanHandleCharacterLink, EditableCharacterProfileBaseProps } from './CommonProps.js';

/**
 * Input props for {@link CharacterRelationships}
 */
export type CharacterRelationshipsProps = EditableCharacterProfileBaseProps &
	CanHandleCharacterLink;

/**
 * Simple card displaying a table of the provided {@link Character}'s
 * {@link Character.relationships}.
 */
export class CharacterRelationships extends CharacterAttributeMapTable<CharacterRelationshipsProps> {
	/**
	 * {@inheritDoc CharacterInfoCard.headerText}
	 */
	public readonly headerText = 'Relationships';

	public constructor(props: CharacterRelationshipsProps) {
		super(props);
	}

	/**
	 * {@inheritDoc CharacterAttributeMapTable.getEntryCount}
	 */
	protected getEntryCount(): number {
		return this.props.character.relationships?.length ?? 0;
	}

	async onSubmitAffilationChanges(
		newRelationshipEntries: MapFormEntry[] | undefined,
	): Promise<void> {
		const newRelationships: Relationship[] | undefined =
			newRelationshipEntries === undefined
				? undefined
				: newRelationshipEntries.map((entry) => {
						return {
							kind: entry.key,
							characterName: entry.value,
						};
					});

		const characterUpdate: Character = {
			...this.props.character,
			relationships: newRelationships,
		};

		await this.submitEdits(characterUpdate);
	}

	protected renderKey(entryIndex: number): React.ReactElement {
		const relationships = assertNotUndefined(this.props.character.relationships);
		return <b>{relationships[entryIndex].kind}</b>;
	}

	protected renderValue(entryIndex: number): React.ReactElement {
		const relationships = assertNotUndefined(this.props.character.relationships);
		const characterName = relationships[entryIndex].characterName;
		return (
			<CharacterListEntry
				characterName={characterName}
				onClick={() => {
					this.props.handleCharacterLink(characterName);
				}}
			/>
		);
	}

	public renderEditForm(): React.ReactElement {
		const mapEntries: MapFormEntry[] | undefined =
			this.props.character.relationships === undefined
				? undefined
				: this.props.character.relationships.map((relationship) => {
						return { key: relationship.kind, value: relationship.characterName };
					});
		return (
			<MapFormDialogue
				formTitle="Edit Relationships"
				initialEntries={mapEntries}
				keyLabel="Relationship"
				valueLabel="Character Name"
				allowDuplicateKeys
				onSubmit={(newEntries) => this.onSubmitAffilationChanges(newEntries)}
				onCancel={() => this.cancelEdit()}
			/>
		);
	}
}
