import type {
	CanGetCharacter,
	CanModifyCurrentDate,
	HasBirthdayEvents,
	HasCalendarEvents,
	HasCharacterLink,
	HasDate,
	HasProfile,
} from '@datapad/common-props';
import { assertNotUndefined } from '@datapad/utilities';
import { ModalCard } from '@datapad/utility-components';
import React from 'react';
import type { CanCreateEvent, CanDeleteEvent, CanEditEvent } from '../CommonProps.js';
import { DateCardInternals } from './DateCardInternals.js';
import { EventCardInternals } from './EventCardInternals.js';

/**
 * {@link DayCardModal} input props.
 */
export interface DayCardModalProps
	extends HasProfile,
		HasCharacterLink,
		HasDate,
		HasCalendarEvents,
		HasBirthdayEvents,
		CanGetCharacter,
		CanCreateEvent,
		CanEditEvent,
		CanDeleteEvent,
		CanModifyCurrentDate {
	/**
	 * Function to invoke when the close button is pressed.
	 */
	closeModal: () => void;
}

/**
 * Modal card for a day / its events.
 * Always displayed.
 */
export function DayCardModal(props: DayCardModalProps): React.ReactElement {
	const {
		date,
		events,
		birthdays,
		createEvent,
		deleteEvent,
		editEvent,
		closeModal,
		getCharacter,
		handleCharacterLink,
		profile,
		updateCurrentDate,
	} = props;

	const [selectedEventTitle, setSelectedEventTitle] = React.useState<string | undefined>(
		undefined,
	);

	function onClose(): void {
		// When we close the modal, clear selection.
		setSelectedEventTitle(undefined);
		closeModal();
	}

	// Reset event selection if input date changes
	React.useEffect(() => {
		setSelectedEventTitle(undefined);
	}, [date]);

	const selectedEvent =
		selectedEventTitle === undefined
			? undefined
			: assertNotUndefined(events.find((event) => event.title === selectedEventTitle));

	const cardInternals = selectedEvent ? (
		<EventCardInternals
			event={selectedEvent}
			onBack={() => setSelectedEventTitle(undefined)}
			deleteEvent={() => {
				const _selectedEvent = assertNotUndefined(selectedEvent);
				deleteEvent(_selectedEvent.title);
				setSelectedEventTitle(undefined);
			}}
			editEvent={editEvent}
			getCharacter={getCharacter}
			handleCharacterLink={handleCharacterLink}
			onClose={onClose}
			profile={profile}
		/>
	) : (
		<DateCardInternals
			date={date}
			events={events}
			birthdays={birthdays}
			onSelectEvent={(event) => setSelectedEventTitle(event.title)}
			createEvent={createEvent}
			onClose={onClose}
			profile={profile}
			handleCharacterLink={handleCharacterLink}
			updateCurrentDate={updateCurrentDate}
		/>
	);
	return (
		<ModalCard
			open
			style={{
				minWidth: '300px',
				maxWidth: '600px',
				maxHeight: '95%',
			}}
			onClose={closeModal}
		>
			{cardInternals}
		</ModalCard>
	);
}
