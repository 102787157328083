/**
 * Calendar related values
 * See: {@link https://starwars.fandom.com/wiki/Galactic_Standard_Calendar}
 */

// TODO: don't use BBY for years. Come up with some event earlier to be counting forward from.

/**
 * Days per year in the Galactic Standard Calendar.
 * 10 35-day months + 3 5-day festival weeks + 3 holidays.
 */
export const daysPerYear = 368;

/**
 * Days per month in the Galactic Standard Calendar.
 */
export const daysPerMonth = 35;

/**
 * Days per week in the Galactic Standard Calendar.
 */
export const daysPerWeek = 5;

/**
 * Weeks per month in the Galactic Standard Calendar.
 */
export const weeksPerMonth = daysPerMonth / daysPerWeek;

/**
 * Months in a Galactic Standard Year.
 * Does not account for the 3 festival weeks or the other 3 holidays.
 */
export const monthsPerYear = 10;

/**
 * Months and festival weeks in the Galactic Standard Year.
 */
export const monthsAndFestivalWeeksPerYear = 13;

/**
 * Days of the week. Applies to non-holiday days
 * (includes festival weeks, just not including the 3 other holidays)
 */
export const weekDays = ['Primeday', 'Centaxday', 'Taungsday', 'Zhellday', 'Benduday'];

/**
 * Festival "weeks" celebrated in the galaxy
 */
export enum Festival {
	/**
	 * 5 days - first week of the year
	 */
	FeteWeek = 'Fete Week',

	/**
	 * 5 days - falls between months 5 and 6
	 */
	FestivalOfLife = 'Festival of Life',

	/**
	 * 5 days - falls between months 8 and 9
	 */
	FestivalOfStars = 'Festival of Stars',
}

/**
 * Month names
 */
export enum Month {
	/**
	 * Month 0
	 */
	Elona = 'Elona',
	/**
	 * Month 1
	 * Includes Republic Day holiday (at end)
	 */
	Kelona = 'Kelona',
	/**
	 * Month 2
	 */
	Selona = 'Selona',
	/**
	 * Month 3
	 */
	Telona = 'Telona',
	/**
	 * Month 4
	 * Includes Productivity Day holiday (at end)
	 */
	Nelona = 'Nelona',
	/**
	 * Month 5
	 */
	Helona = 'Helona',
	/**
	 * Month 6
	 */
	Melona = 'Melona',
	/**
	 * Month 7
	 * Includes Harvest Day holiday (at end)
	 */
	Yelona = 'Yelona',
	/**
	 * Month 8
	 */
	Relona = 'Relona',
	/**
	 * Month 9
	 */
	Welona = 'Welona',
}

/**
 * Represents a Month or Festival Week in the Galactic Calendar.
 * Note: Other holidays are encapsulated within months.
 */
export interface MonthOrFestivalWeek {
	name: string;
	shorthand: string;
	isFestivalWeek: boolean;
	startDay: number;
	dayCount: number;
}

/**
 * Represents a month (or festival week) of a specific year.
 */
export interface MonthOfYear {
	monthOrFestivalWeek: MonthOrFestivalWeek;
	year: number;
}

/**
 * Ordered list of months and festival weeks
 */
export const monthsAndFestivalWeeks: MonthOrFestivalWeek[] = [
	{
		name: Festival.FeteWeek,
		shorthand: 'Fete',
		isFestivalWeek: true,
		startDay: 0,
		dayCount: 5,
	},
	{
		name: Month.Elona,
		shorthand: '1',
		isFestivalWeek: false,
		startDay: 5,
		dayCount: 35,
	},
	{
		name: Month.Kelona, // Includes Republic Day holiday (at end)
		shorthand: '2',
		isFestivalWeek: false,
		startDay: 40,
		dayCount: 36,
	},
	{
		name: Month.Selona,
		shorthand: '3',
		isFestivalWeek: false,
		startDay: 76,
		dayCount: 35,
	},
	{
		name: Month.Telona,
		shorthand: '4',
		isFestivalWeek: false,
		startDay: 111,
		dayCount: 35,
	},
	{
		name: Month.Nelona, // Includes Productivity Day holiday (at end)
		shorthand: '5',
		isFestivalWeek: false,
		startDay: 146,
		dayCount: 36,
	},
	{
		name: Month.Helona,
		shorthand: '6',
		isFestivalWeek: false,
		startDay: 182,
		dayCount: 35,
	},
	{
		name: Festival.FestivalOfLife,
		shorthand: 'Life',
		isFestivalWeek: true,
		startDay: 217,
		dayCount: 5,
	},
	{
		name: Month.Melona,
		shorthand: '7',
		isFestivalWeek: false,
		startDay: 222,
		dayCount: 35,
	},
	{
		name: Month.Yelona, // Includes Harvest Day holiday (at end)
		shorthand: '8',
		isFestivalWeek: false,
		startDay: 257,
		dayCount: 36,
	},
	{
		name: Month.Relona,
		shorthand: '9',
		isFestivalWeek: false,
		startDay: 293,
		dayCount: 35,
	},
	{
		name: Festival.FestivalOfStars,
		shorthand: 'Stars',
		isFestivalWeek: true,
		startDay: 328,
		dayCount: 5,
	},
	{
		name: Month.Welona,
		shorthand: '10',
		isFestivalWeek: false,
		startDay: 333,
		dayCount: 35,
	},
];

/**
 * Represents a Galactic Holiday day.
 * These fall outside of traditional weekday structure / naming conventions
 */
export interface Holiday {
	name: string;
	dayOfTheYear: number;
}

/**
 * List of Galactic Holidays
 */
export const holidays: Holiday[] = [
	{
		name: 'Republic Day',
		dayOfTheYear: 75,
	},
	{
		name: 'Productivity Day',
		dayOfTheYear: 181,
	},
	{
		name: 'Harvest Day',
		dayOfTheYear: 292,
	},
];

/**
 * Gets the month or festival week name for the specified index
 */
export function getMonthOrFestivalWeekFromIndex(
	monthOrFestivalWeekIndex: number,
): MonthOrFestivalWeek {
	validateMonthOrFestivalWeekIndex(monthOrFestivalWeekIndex);
	return monthsAndFestivalWeeks[monthOrFestivalWeekIndex];
}

/**
 * Throws if the provided value (0-based) is not an integer, or if it is outside the allowed
 * range: [0, {@link monthsAndFestivalWeeksPerYear} - 1].
 */
function validateMonthOrFestivalWeekIndex(monthOrFestivalWeekIndex: number): void {
	if (!Number.isInteger(monthOrFestivalWeekIndex)) {
		throw new Error(`Expected an integer, received: ${monthOrFestivalWeekIndex}`);
	}
	if (monthOrFestivalWeekIndex < 0 || monthOrFestivalWeekIndex > monthsAndFestivalWeeksPerYear) {
		throw new Error(
			`Provided month or festival week index is outside the allowed range. Expected on [0, ${
				monthsAndFestivalWeeksPerYear - 1
			}], but was: ${monthOrFestivalWeekIndex}`,
		);
	}
}

/**
 * Determines whether or not the specified day of the year falls within the specified month.
 * @remarks Note that this includes holidays, which are tacked on to the ends of certain months
 */
export function isDayInMonthOrFestivalWeek(
	dayOfTheYear: number,
	monthOrFestivalWeek: MonthOrFestivalWeek,
): boolean {
	return (
		dayOfTheYear >= monthOrFestivalWeek.startDay &&
		dayOfTheYear < monthOrFestivalWeek.startDay + monthOrFestivalWeek.dayCount
	);
}

/**
 * Gets the month or festival week name for the specified day in the year
 */
export function monthOrFestivalWeekFromDay(dayOfTheYear: number): MonthOrFestivalWeek {
	validateDayOfTheYear(dayOfTheYear);

	for (let i = 0; i < monthsAndFestivalWeeksPerYear; i++) {
		const currentMonthOrFestivalWeek = monthsAndFestivalWeeks[i];
		if (isDayInMonthOrFestivalWeek(dayOfTheYear, currentMonthOrFestivalWeek)) {
			return currentMonthOrFestivalWeek;
		}
	}
	throw new Error(
		`Specified day "${dayOfTheYear}" is out of year range. This should have been caught above.`,
	);
}

/**
 * Gets the month or festival week name for the specified date.
 */
export function monthOrFestivalWeekFromDate(date: CalendarDate): MonthOrFestivalWeek {
	return monthOrFestivalWeekFromDay(date.dayOfTheYear);
}

/**
 * Gets the next month or festival week in sequence.
 * Also increments the year if needed.
 */
export function getNextMonthOrFestivalWeek(
	monthOrFestivalWeek: MonthOrFestivalWeek,
	year: number,
): { monthOrFestivalWeek: MonthOrFestivalWeek; year: number } {
	const monthOrFestivalWeekIndex = getMonthOrFestivalWeekIndex(monthOrFestivalWeek);
	let nextIndex = monthOrFestivalWeekIndex + 1;
	if (nextIndex === monthsAndFestivalWeeksPerYear) {
		nextIndex = 0;
		year = year - 1; // Because BBY
	}
	return {
		monthOrFestivalWeek: monthsAndFestivalWeeks[nextIndex],
		year,
	};
}

/**
 * Gets the previous month or festival week in sequence.
 * Also decrements the year if needed.
 */
export function getPreviousMonthOrFestivalWeek(
	monthOrFestivalWeek: MonthOrFestivalWeek,
	year: number,
): { monthOrFestivalWeek: MonthOrFestivalWeek; year: number } {
	const monthOrFestivalWeekIndex = getMonthOrFestivalWeekIndex(monthOrFestivalWeek);
	let nextIndex = monthOrFestivalWeekIndex - 1;
	if (nextIndex === -1) {
		nextIndex = monthsAndFestivalWeeksPerYear - 1;
		year = year + 1; // Because BBY
	}
	return {
		monthOrFestivalWeek: monthsAndFestivalWeeks[nextIndex],
		year,
	};
}

/**
 * Gets the index of the specified month or festival week in {@link monthsAndFestivalWeeks}
 */
function getMonthOrFestivalWeekIndex(monthOrFestivalWeek: MonthOrFestivalWeek): number {
	const monthOrFestivalWeekIndex = monthsAndFestivalWeeks.findIndex(
		(value) => value.name === monthOrFestivalWeek.name,
	);
	if (monthOrFestivalWeekIndex < 0 || monthOrFestivalWeekIndex >= monthsAndFestivalWeeksPerYear) {
		throw new Error('Index search yielded invalid index.');
	}
	return monthOrFestivalWeekIndex;
}

/**
 * Throws if the provided day (0-based) is not an integer, or if it is outside the allowed
 * range: [0, {@link daysPerYear} - 1].
 */
function validateDayOfTheYear(dayOfTheYear: number): void {
	if (!Number.isInteger(dayOfTheYear)) {
		throw new Error(`Expected an integer, received: ${dayOfTheYear}`);
	}
	if (dayOfTheYear < 0 || dayOfTheYear >= daysPerYear) {
		throw new Error(
			`Provided day is outside the allowed range. Expected on [0, ${
				daysPerYear - 1
			}], but was: ${dayOfTheYear}`,
		);
	}
}

/**
 * Determines if the specified day of the year is one of the 3 holiday days.
 * If so, returns the holiday. Else, returns undefined.
 * Note: this does not include days in Festival Weeks.
 */
export function tryGetHolidayFromDay(dayOfTheYear: number): Holiday | undefined {
	validateDayOfTheYear(dayOfTheYear);
	for (const holiday of holidays) {
		if (dayOfTheYear === holiday.dayOfTheYear) {
			return holiday;
		}
	}
	return undefined;
}
/**
 * Determines if the specified date is one of the 3 holiday days.
 * If so, returns the holiday. Else, returns undefined.
 * Note: this does not include days in Festival Weeks.
 */
export function tryGetHolidayFromDate(date: CalendarDate): Holiday | undefined {
	return tryGetHolidayFromDay(date.dayOfTheYear);
}

export interface DayOfMonthOrFestivalWeek {
	dayNumber: number;
	weekdayOrHolidayName: string;
	isHoliday: boolean;
}

/**
 * Gets the weekday name for the provided day of the year (0-based).
 */
export function weekdayOrHolidayFromDay(dayOfTheYear: number): DayOfMonthOrFestivalWeek {
	validateDayOfTheYear(dayOfTheYear);

	const monthOrFestivalWeek = monthOrFestivalWeekFromDay(dayOfTheYear);

	if (monthOrFestivalWeek.isFestivalWeek) {
		// Festival weeks are always 5 days long and follow the traditional weekday naming conventions
		const dayOfTheWeek = dayOfTheYear - monthOrFestivalWeek.startDay;
		const weekdayName = weekDays[dayOfTheWeek % 5];
		return {
			dayNumber: dayOfTheWeek,
			weekdayOrHolidayName: weekdayName,
			isHoliday: false,
		};
	}

	// For other months, a given day is either a standard weekday, or is a holiday.
	const dayOfTheMonth = dayOfTheYear - monthOrFestivalWeek.startDay;

	const maybeHoliday = tryGetHolidayFromDay(dayOfTheYear);
	if (maybeHoliday !== undefined) {
		return {
			dayNumber: dayOfTheMonth,
			weekdayOrHolidayName: maybeHoliday.name,
			isHoliday: true,
		};
	}

	return {
		dayNumber: dayOfTheMonth,
		weekdayOrHolidayName: weekDays[dayOfTheMonth % 5],
		isHoliday: false,
	};
}

/**
 * Gets the weekday name for the provided day of the provided {@link CalendarDate}.
 */
export function weekdayOrHolidayFromDate(date: CalendarDate): DayOfMonthOrFestivalWeek {
	return weekdayOrHolidayFromDay(date.dayOfTheYear);
}

/**
 * Calendar date (BBY).
 */
export interface CalendarDate {
	/**
	 * Year BBY. Must be an integer  on [0, ∞).
	 */
	year: number;

	/**
	 * Day of the year. Must be an integer on [0, {@link daysPerYear} - 1].
	 */
	dayOfTheYear: number;
}

/**
 * The date on which the New Sith Wars began.
 * @remarks Used as a sort of anchor point in the overall setting.
 * Can be used as a "default" date.
 */
export const beginningOfNewSithWars: CalendarDate = {
	year: 2007,
	dayOfTheYear: 29,
};

/**
 * The date on which the "True Sith Empire" took control of Hutt Space.
 * @remarks Used as a sort of anchor point in the overall setting.
 * Can be used as a "default" date.
 */
export const sithEmpireClaimsHuttSpace: CalendarDate = {
	year: 1793,
	dayOfTheYear: 80,
};

/**
 * Gets the difference (in days) between `from` and `to` dates
 */
export function getDifferenceInDays(from: CalendarDate, to: CalendarDate): number {
	// Years BBY decrease as time moves forward
	return daysPerYear * (to.year - from.year) + (from.dayOfTheYear - to.dayOfTheYear);
}

/**
 * Gets the difference (in years) between `from` and `to` dates
 */
export function getDifferenceInYears(from: CalendarDate, to: CalendarDate): number {
	const daysDifference = getDifferenceInDays(from, to);
	const sign = daysDifference < 0 ? -1 : 1;
	const absDaysDifference = Math.abs(daysDifference);
	const absYearsDifference = Math.floor(absDaysDifference / daysPerYear);
	return sign * absYearsDifference * -1; // Years BBY decrease as time moves forward
}

/**
 * Adds the day count to the provided date, and returns the resulting date.
 */
export function plusOffset(date: CalendarDate, daysOffset: number): CalendarDate {
	const sign = daysOffset < 0 ? -1 : 1;
	const absDaysOffset = Math.abs(daysOffset);

	const yearsDifference = sign * Math.floor(absDaysOffset / daysPerYear) * -1; // Years BBY decrease as time moves forward
	const daysDifference = (sign * absDaysOffset) % daysPerYear;

	let newYear = date.year + yearsDifference;
	let newDay = date.dayOfTheYear + daysDifference;

	if (newDay > daysPerYear) {
		newYear--; // Years BBY decrease as time moves forward
		newDay %= daysPerYear;
	}

	return {
		year: newYear,
		dayOfTheYear: newDay,
	};
}

/**
 * Compares `a` to `b`.
 * Returns 0 if the 2 are equal.
 * Returns -1 if `a` \< `b`.
 * Returns 1 if `a` \> `b`.
 */
export function compareDates(a: CalendarDate, b: CalendarDate): number {
	// Since years are expressed in BBY, invert comparison
	const yearCompare = b.year - a.year;
	if (yearCompare !== 0) {
		return yearCompare;
	}

	return a.dayOfTheYear - b.dayOfTheYear;
}

/**
 * Returns whether or not the 2 provided dates are the same.
 */
export function areDatesEqual(a: CalendarDate, b: CalendarDate): boolean {
	return a.dayOfTheYear === b.dayOfTheYear && a.year === b.year;
}

/**
 * Returns the short-hand string representation of a date.
 *
 * ```
 * [month-or-festival-shorthand]/[day-of-the-month]/[year]
 * ```
 *
 * @privateRemarks TODO: rename this to be more globally accessible.
 */
export function toShortString(date: CalendarDate): string {
	const monthOrFestivalWeek = monthOrFestivalWeekFromDay(date.dayOfTheYear);
	const dayOfTheMonthOrFestivalWeek = weekdayOrHolidayFromDay(date.dayOfTheYear);

	// Day +1 to convert from 0-based representation
	return `${monthOrFestivalWeek.shorthand}/${dayOfTheMonthOrFestivalWeek.dayNumber + 1}/${
		date.year
	}`;
}

/**
 * Returns the long-hand string representation of a date.
 *
 * ```
 * [weekday, ][month-or-festival]/[day]/[year]BBY
 * ```
 *
 * @privateRemarks TODO: rename this to be more globally accessible.
 */
export function toLongString(date: CalendarDate): string {
	const monthOrFestivalWeek = monthOrFestivalWeekFromDay(date.dayOfTheYear);
	const dayOfTheMonthOrFestivalWeek = weekdayOrHolidayFromDay(date.dayOfTheYear);

	// Day +1 to convert from 0-based representation
	return `${dayOfTheMonthOrFestivalWeek.weekdayOrHolidayName}, ${monthOrFestivalWeek.name}/${
		dayOfTheMonthOrFestivalWeek.dayNumber + 1
	}/${date.year}`;
}
