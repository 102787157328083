import type { Player } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import { ProfileContext, type SharedPlayersMap, usePlayers } from '../../../utilities';
import PlayersApp from '../../Players';

/**
 * {@link Players} input props.
 */
export interface PlayersProps {
	playersMap: SharedPlayersMap;
}

/**
 * Contacts app wrapper that handles translation of Fluid-backed state
 */
export function Players(props: PlayersProps): React.ReactElement {
	const { playersMap } = props;

	const players = usePlayers(playersMap);

	const { profile } = assertNotUndefined(React.useContext(ProfileContext));

	if (!profile.dmMode) {
		throw new Error('Only DM may use Players app.');
	}

	/**
	 * Adds a new player to the SharedMap.
	 */
	function createPlayer(player: Player): void {
		playersMap.set(player.userName, player);
	}

	/**
	 * Updates an existing player in the SharedMap.
	 */
	function editPlayer(player: Player): void {
		// Only update state if it still exists in the map.
		// Potentially prevents re-adding a player deleted by another user.
		if (playersMap.has(player.userName)) {
			playersMap.set(player.userName, player);
		}
	}

	/**
	 * Removes the provided player from the SharedMap.
	 */
	function deletePlayer(player: Player): void {
		playersMap.delete(player.userName);
	}

	return (
		<PlayersApp
			players={players}
			createPlayer={createPlayer}
			deletePlayer={deletePlayer}
			editPlayer={editPlayer}
		/>
	);
}
