import { createTheme, responsiveFontSizes, type Theme } from '@mui/material';

const defaultBackgroundColor = 'rgba(40,40,40,1)';

export const primaryMainColor = '#039be5';

/**
 * Theme for use with all material-ui components in the app.
 * TODO: actually set things here as needed.
 */
export function createAppTheme(): Theme {
	let appTheme = createTheme({
		palette: {
			mode: 'dark',
			background: {
				default: defaultBackgroundColor,
			},
			primary: {
				main: primaryMainColor,
			},
		},
	});

	// TODO: why is this not affecting some uses of Typography? E.g. in the Calendar?
	appTheme = responsiveFontSizes(appTheme, { factor: 3 });

	return appTheme;
}
