import { Modal } from '@mui/material';
import React from 'react';
import { DialogueCard, type DialogueCardProps } from './DialogueCard.js';

/**
 * Input props for {@link ModalCard}
 */
export interface ModalCardProps extends DialogueCardProps {
	/**
	 * Whether or not the modal is open.
	 * undefined =\> false
	 */
	open?: boolean;

	/**
	 * Function to invoke when the modal should be closed.
	 */
	onClose?: () => void;
}

/**
 * Card to be displayed in a floating modal dialogue
 */
export function ModalCard(props: ModalCardProps): React.ReactElement {
	return (
		<Modal
			open={props.open ?? false}
			onClose={props.onClose}
			style={{ width: '100%', height: '100%' }}
		>
			<DialogueCard {...props} />
		</Modal>
	);
}
