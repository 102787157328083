import {
	type DataEntry,
	DropDownEntry,
	EditFormDialogue,
	ListEntry,
	NumberEntry,
	StringEntry,
} from '@datapad/form-components';
import { type CalendarEvent, eventKindFromString, eventKindOptions } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';

export enum DialogueMode {
	Creation,
	Edit,
}

interface Props {
	initialEvent: Partial<CalendarEvent>;
	onClose: () => void;
	onSubmitCalendarEvent: (event: CalendarEvent) => void;
	mode: DialogueMode;
}

/**
 * Simple timeline event creation dialogue
 */
export function EventDialogue(props: Props): React.ReactElement {
	const schemas = createSchemas(props.initialEvent, props.mode === DialogueMode.Edit);
	const formTitle = formTitleFromMode(props.mode);
	return (
		<EditFormDialogue
			title={formTitle}
			onCancel={props.onClose}
			onSubmit={(data: Map<string, unknown>) =>
				props.onSubmitCalendarEvent(eventFromValues(data))
			}
			schemas={schemas}
		/>
	);
}

/**
 * Gets the appropriate form title from the mode
 */
function formTitleFromMode(dialogueMode: DialogueMode): string {
	switch (dialogueMode) {
		case DialogueMode.Creation:
			return 'Create calendar event';
		case DialogueMode.Edit:
			return 'Edit calendar event';
		default:
			throw new Error(`Unrecognized DialogueMode value: "${dialogueMode}".`);
	}
}

/**
 * Creates input schemas for event creation dialogue
 */
function createSchemas(
	initialEvent: Partial<CalendarEvent>,
	titleLocked: boolean,
): Map<string, DataEntry> {
	return new Map<string, DataEntry>([
		[
			'title',
			new StringEntry({
				initialValue: initialEvent.title,
				label: 'Title',
				elementId: 'calendar-event-title-form',
				required: true,
				multiLine: false,
				locked: titleLocked,
			}),
		],
		[
			'year',
			new NumberEntry({
				initialValue: initialEvent.year,
				label: 'Year',
				required: true,
				elementId: 'calendar-event-year-form',
				min: 0,
				decimals: false,
			}),
		],
		[
			'day',
			new NumberEntry({
				initialValue: initialEvent.day,
				label: 'Day',
				required: true,
				elementId: 'calendar-event-day-form',
				min: 0,
				decimals: false,
			}),
		],
		[
			'location',
			new StringEntry({
				initialValue: initialEvent.location,
				label: 'Location',
				elementId: 'calendar-event-location-form',
				required: false,
				multiLine: false,
			}),
		],
		[
			'description',
			new StringEntry({
				initialValue: initialEvent.description,
				label: 'Description',
				elementId: 'calendar-event-description-form',
				required: false,
				multiLine: true,
			}),
		],
		[
			'involvedFactions',
			new ListEntry({
				initialValue: initialEvent.involvedFactions,
				label: 'Involved factions',
				elementId: 'calendar-event-involved-factions-form',
			}),
		],
		[
			'kind',
			new DropDownEntry({
				initialValue: initialEvent.kind,
				label: 'Kind',
				options: eventKindOptions,
				elementId: 'calendar-event-kind-form',
				required: true,
			}),
		],
		[
			'characters',
			new ListEntry({
				initialValue: initialEvent.characters,
				label: 'Characters',
				elementId: 'calendar-event-characters-form',
			}),
		],
	]);
}

/**
 * Converts form response data to a {@link CalendarEvent} for submission to the database
 */
function eventFromValues(data: Map<string, unknown>): CalendarEvent {
	const title = assertNotUndefined(data.get('title') as string);
	const year = assertNotUndefined(data.get('year') as number);
	const day = assertNotUndefined(data.get('day') as number);
	const kind = eventKindFromString(assertNotUndefined(data.get('kind') as string));
	const location: string | undefined = data.get('location') as string;
	const description: string | undefined = data.get('description') as string;

	const involvedFactions = data.get('involvedFactions') as string[];
	const characters = data.get('characters') as string[];

	return {
		title,
		year,
		day,
		kind,
		location,
		description,
		involvedFactions,
		characters,
	};
}
