import { cloneArray } from '@datapad/utilities';
import type { Campaign } from './Campaign.js';
import {
	type Character,
	type PlayerCharacter,
	getKnownCharacters,
	getUniversallyKnownCharacters,
	isCharacterKnown,
	isPlayerCharacter,
} from './Character.js';
import { type Player, PlayerKind, getNicknameOrUserName } from './Player.js';
import {
	type CalendarDate,
	type CalendarEvent,
	EventKind,
	getEventsKnownToCharacter,
	getGalaxyEvents,
	isEventKnown,
	sithEmpireClaimsHuttSpace,
} from './calendar/index.js';

// TODOs:
// - Consider going back to a plain interface so we can do spreads and stuff

/**
 * Represents a profile for a signed-in user.
 *
 * @remarks Note: this type is not intended to be serialized.
 * It is derived from multiple serialized types and offers helpful utilities around its data.
 */
export class Profile {
	/**
	 * The signed-in player.
	 */
	public readonly player: Player;

	/**
	 * The characters belonging to {@link Profile.player}.
	 */
	public readonly characters: PlayerCharacter[];

	/**
	 * Signed-in player character.
	 *
	 * @remarks Will not exist for guest players.
	 * Must only be undefined if the player has no characters (e.g. if they are a guest).
	 */
	public readonly signedInCharacter?: PlayerCharacter;

	/**
	 * The campaign the signed in character.
	 *
	 * @remarks Will not exist for guest players.
	 * Must only be undefined if the player has no characters.
	 */
	public readonly campaign?: Campaign;

	/**
	 * Whether or not the profile is set to "DM Mode", in which case the user is presented with
	 * all information, ignoring their {@link Profile.signedInCharacter}.
	 * Only available to the DM.
	 */
	public readonly dmMode: boolean;

	/**
	 * @param player - See {@link Profile.player}.
	 * @param characters - See {@link Profile.characters}.
	 * @param signedInCharacter - See {@link Profile.signedInCharacter}.
	 * @param campaign - See {@link Profile.campaign}.
	 * @param dmMode - See {@link Profile.dmMode}. Default value: `false`.
	 */
	public constructor(
		player: Player,
		characters: PlayerCharacter[],
		signedInCharacter?: string,
		campaign?: Campaign,
		dmMode?: boolean,
	) {
		if (
			signedInCharacter !== undefined &&
			!characters.some((character) => character.name === signedInCharacter)
		) {
			throw new Error(
				`Specified signed-in character of "${signedInCharacter}", which does not belong to profile player "${player.userName}".`,
			);
		}

		if (signedInCharacter === undefined && campaign !== undefined) {
			throw new Error(
				`Specified campaign "${campaign.name}", but provided no session character associated with that campaign.`,
			);
		}

		if (
			signedInCharacter !== undefined &&
			campaign !== undefined &&
			!campaign.characters.includes(signedInCharacter)
		) {
			throw new Error(
				`Specified signed-in character of "${signedInCharacter}", which does not belong to specified campaign "${campaign.name}".`,
			);
		}

		if (dmMode === true && player.playerKind !== PlayerKind.DungeonMaster) {
			throw new Error('Specified "DM Mode" for non-DM player.');
		}

		if (player.playerKind === PlayerKind.Guest && characters.length !== 0) {
			throw new Error('Guest profile should not have player characters');
		}

		this.player = player;
		this.characters = characters;
		this.signedInCharacter =
			signedInCharacter === undefined
				? undefined
				: characters.find((character) => character.name === signedInCharacter);
		this.campaign = campaign;
		this.dmMode = dmMode ?? false;
	}

	public get playerName(): string {
		return this.player.userName;
	}

	public get playerKind(): PlayerKind {
		return this.player.playerKind;
	}

	public get campaignDate(): CalendarDate | undefined {
		return this.campaign?.currentDate;
	}

	/**
	 * {@inheritDoc getNicknameOrUserName}
	 */
	public getUserNicknameOrName(): string {
		return getNicknameOrUserName(this.player);
	}

	private isPlayerDungeonMaster(): boolean {
		return this.player.playerKind === PlayerKind.DungeonMaster;
	}

	public isPlayerGuest(): boolean {
		return this.player.playerKind === PlayerKind.Guest;
	}

	/**
	 * Returns whether or not the specified character name belongs to the user.
	 * If the user is the DM, will always return true.
	 */
	public doesCharacterNameBelongToPlayer(characterName: string): boolean {
		return this.characters.some((playersCharacter) => playersCharacter.name === characterName);
	}

	/**
	 * Returns whether or not the specified character belongs to the user.
	 * If the user is the DM, will always return true.
	 */
	public doesCharacterBelongToPlayer(character: Character): boolean {
		if (!isPlayerCharacter(character)) {
			return false;
		} else if (this.isPlayerDungeonMaster()) {
			return true;
		} else {
			return this.doesCharacterNameBelongToPlayer(character.name);
		}
	}

	/**
	 * Whether or not the specified character is known to this profile.
	 */
	public isCharacterKnown(character: Character): boolean {
		if (this.dmMode) {
			return true;
		} else if (this.signedInCharacter === undefined) {
			return character.universallyKnown === true;
		} else {
			return isCharacterKnown(character, this.signedInCharacter);
		}
	}

	/**
	 * Filters the input list of characters to only those known to this profile.
	 * @remarks Will always return the full input list when in {@link Profile.dmMode}.
	 */
	public getKnownCharacters(characters: readonly Character[]): Character[] {
		if (this.dmMode) {
			return cloneArray(characters);
		} else if (this.signedInCharacter === undefined) {
			return getUniversallyKnownCharacters(characters);
		} else {
			return getKnownCharacters(characters, this.signedInCharacter);
		}
	}

	/**
	 * Whether or not the specified event is known to this profile.
	 */
	public isEventKnown(event: CalendarEvent): boolean {
		if (this.dmMode) {
			return true;
		} else if (this.signedInCharacter === undefined) {
			return event.kind === EventKind.Galaxy;
		} else {
			return isEventKnown(this.signedInCharacter, this.campaign, event);
		}
	}

	/**
	 * Filters the input list of events to only those known to this profile.
	 * @remarks Will always return the full input list when in {@link Profile.dmMode}.
	 */
	public getKnownEvents(events: readonly CalendarEvent[]): CalendarEvent[] {
		if (this.dmMode) {
			return cloneArray(events);
		} else if (this.signedInCharacter === undefined) {
			return getGalaxyEvents(events);
		} else {
			return getEventsKnownToCharacter(this.signedInCharacter, this.campaign, events);
		}
	}

	/**
	 * Gets the character specified by name, if they belong to the character.
	 * Else, returns `undefined`.
	 */
	public getCharacter(characterName: string): PlayerCharacter | undefined {
		return this.characters.find((character) => character.name === characterName);
	}

	/**
	 * Gets the current campaign date if the player has an active campaign.
	 * Otherwise, returns a default date: {@link sithEmpireClaimsHuttSpace}.
	 */
	public getCampaignDateOrDefault(): CalendarDate {
		return this.campaignDate ?? sithEmpireClaimsHuttSpace;
	}
}
