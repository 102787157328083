/**
 * Contains simple `string` utility functions.
 */

/**
 * Converts the provided string into a url-compatible format.
 * Note: omits all characters that aren't alphanumeric.
 */
export function urlFormat(value: string): string {
	return (
		value
			// False positive
			// eslint-disable-next-line no-useless-escape
			.replace(/[^0-9a-zA-Z_\s\-]/g, '')
			.replace(/\s+/g, '-')
			.toLocaleLowerCase()
	);
}

export function assertUndefinedOrNonEmpty<T>(value: T[] | undefined): T[] | undefined;
export function assertUndefinedOrNonEmpty(value: string | undefined): string | undefined;

/**
 * Expects the provided value to be undefined XOR a non-empty string.
 * Throws if it encounters an empty string.
 *
 * This utility is meant to catch cases where we may attempt to write a bunch of empty strings
 * in database edits, etc.
 */
export function assertUndefinedOrNonEmpty(
	value: string | Array<unknown> | undefined,
): string | Array<unknown> | undefined {
	if (value !== undefined && value.length === 0) {
		throw new Error(`Provided string was empty. Expected undefined XOR non-empty string.`);
	}
	return value;
}
