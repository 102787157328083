import { ScopedCssBaseline } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';

/**
 * Input props for {@link MarkdownTextbox}
 */
export interface MarkdownTextboxProps {
	/**
	 * The markdown-formatted text to be rendered.
	 */
	text: string;
}

/**
 * Markdown textbox
 */
export function MarkdownTextbox(props: MarkdownTextboxProps): React.ReactElement {
	return (
		<ScopedCssBaseline enableColorScheme>
			<ReactMarkdown linkTarget="_blank">{props.text}</ReactMarkdown>
		</ScopedCssBaseline>
	);
}
