import type { CanGetCharacter, HasCharacterLink, HasProfile } from '@datapad/common-props';
import { EventDetails } from '@datapad/event-components';
import { ConfirmationDialogue } from '@datapad/form-components';
import type { CalendarEvent } from '@datapad/interfaces';
import { ErrorDialogue, ModalCard, PendingDialogue } from '@datapad/utility-components';
import { ArrowBack, Close, DeleteForever, Edit } from '@mui/icons-material';
import {
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import type { CanEditEvent } from '../CommonProps.js';
import { DialogueMode, EventDialogue } from './EventDialogue.js';
import { FactionIcon } from './FactionIcon.js';

/**
 * {@link EventCardInternals} input props.
 */
export interface EventCardInternalsProps
	extends HasProfile,
		HasCharacterLink,
		CanGetCharacter,
		CanEditEvent {
	/**
	 * The event being displayed
	 */
	event: CalendarEvent;

	/**
	 * Function to invoke when the close button is pressed.
	 */
	onClose: () => void;

	/**
	 * Function to invoke when the back button is pressed.
	 */
	onBack: () => void;

	/**
	 * Deletes the event
	 */
	deleteEvent: () => void;
}

/**
 * Event insertion status
 */
enum ModalStatus {
	None,
	Deleting,
	Editing,
	Pending,
	Error,
}

/**
 * Internal guts of an Event Card.
 */
export function EventCardInternals(props: EventCardInternalsProps): React.ReactElement {
	const {
		event,
		onClose,
		onBack,
		deleteEvent,
		editEvent,
		profile,
		getCharacter,
		handleCharacterLink,
	} = props;

	const [modalStatus, setModalStatus] = React.useState(ModalStatus.None);

	// If the selected event changed, reset modal status
	React.useEffect(() => {
		setModalStatus(ModalStatus.None);
	}, [event, setModalStatus]);

	function onDeleteEvent(): void {
		deleteEvent();
		setModalStatus(ModalStatus.None);
	}

	function onEditEvent(editedEvent: CalendarEvent): void {
		editEvent(editedEvent);
		setModalStatus(ModalStatus.None);
	}

	const maybeDeleteButton = profile.dmMode ? (
		<Tooltip title="Delete event">
			<IconButton
				aria-label="Delete event"
				onClick={() => setModalStatus(ModalStatus.Deleting)}
			>
				<DeleteForever />
			</IconButton>
		</Tooltip>
	) : undefined;

	const maybeEditButton = profile.dmMode ? (
		<Tooltip title="Edit event">
			<IconButton aria-label="Edit event" onClick={() => setModalStatus(ModalStatus.Editing)}>
				<Edit />
			</IconButton>
		</Tooltip>
	) : undefined;

	return (
		<>
			<EventEditingModal
				event={event}
				modalStatus={modalStatus}
				updateModalStatus={setModalStatus}
				onDeleteEvent={onDeleteEvent}
				onEditEvent={onEditEvent}
			/>
			<CardHeader
				title={event.title}
				avatar={<FactionIcon event={event} />}
				action={
					<IconButton aria-label="Close" onClick={onClose}>
						<Close />
					</IconButton>
				}
			/>
			<CardContent>
				<EventDetails
					profile={profile}
					event={event}
					getCharacter={getCharacter}
					handleCharacterLink={handleCharacterLink}
				/>
			</CardContent>
			<CardActions>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Tooltip title="Back to date view">
						<IconButton aria-label="Back to date view" onClick={onBack}>
							<ArrowBack />
						</IconButton>
					</Tooltip>
					<div>
						{maybeEditButton}
						{maybeDeleteButton}
					</div>
				</div>
			</CardActions>
		</>
	);
}

/**
 * {@link EventEditingModal} input props.
 */
interface EventEditingModalProps {
	event: CalendarEvent;
	modalStatus: ModalStatus;
	updateModalStatus(newStatus: ModalStatus): void;
	onDeleteEvent(): void;
	onEditEvent(editedEvent: CalendarEvent): void;
}

/**
 * Editing / deleting modal dialogue for a Calendar Event.
 */
function EventEditingModal(props: EventEditingModalProps): React.ReactElement {
	const { event, modalStatus, updateModalStatus, onDeleteEvent, onEditEvent } = props;
	switch (modalStatus) {
		case ModalStatus.None:
			return <></>;
		case ModalStatus.Deleting:
			return (
				<ModalCard open={true} onClose={() => updateModalStatus(ModalStatus.None)}>
					<ConfirmationDialogue
						onConfirm={onDeleteEvent}
						onCancel={() => updateModalStatus(ModalStatus.None)}
					>
						<Typography variant="body1">
							Are you sure you wish to delete this event?
						</Typography>
						<br />

						<Typography variant="body2">This action cannot be undone.</Typography>
					</ConfirmationDialogue>
				</ModalCard>
			);
		case ModalStatus.Editing:
			return (
				<ModalCard open={true} onClose={() => updateModalStatus(ModalStatus.None)}>
					<ModalCard open={true} onClose={() => updateModalStatus(ModalStatus.None)}>
						<EventDialogue
							onClose={() => updateModalStatus(ModalStatus.None)}
							onSubmitCalendarEvent={onEditEvent}
							initialEvent={event}
							mode={DialogueMode.Edit}
						/>
					</ModalCard>
				</ModalCard>
			);
		case ModalStatus.Pending:
			return (
				<ModalCard open={true} onClose={() => updateModalStatus(ModalStatus.None)}>
					<PendingDialogue />
				</ModalCard>
			);
		case ModalStatus.Error:
			return (
				<ModalCard open={true} onClose={() => updateModalStatus(ModalStatus.None)}>
					<ErrorDialogue
						textLines={[
							'There was an error deleting the calendar event.',
							'If this persists, please contact your GM.',
						]}
						onClose={() => updateModalStatus(ModalStatus.None)}
					/>
				</ModalCard>
			);
		default:
			throw new Error(`Unrecognized ModalStatus value: "${modalStatus}".`);
	}
}
