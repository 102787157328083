import {
	BooleanEntry,
	type DataEntry,
	DropDownEntry,
	ListEntry,
	MapEntry,
	type MapFormEntry,
	type Page,
	PaginatedEditFormDialogue,
	StringEntry,
} from '@datapad/form-components';
import {
	type Character,
	CharacterStatus,
	characterStatusOptions,
	type PhysicalAttribute,
	type PlayerCharacter,
	pronounOptions as characterPronounOptions,
	type Relationship,
} from '@datapad/interfaces';
import { assertNotUndefined, assertUndefinedOrNonEmpty } from '@datapad/utilities';
import React from 'react';

/**
 * Input props for {@link CharacterCreationForm}
 */
export interface CharacterCreationFormProps {
	/**
	 * Whether or not the character being created is a player character
	 */
	playerCharacter: boolean;

	onSubmit: (character: Character) => void;
	onCancel: () => void;
}

/**
 * Form for creating a new character.
 * Intended for use in modal dialogues.
 *
 * Note: Bio, Notes, Relationships, and Physical Attributes are ommitted here.
 * They can be entered by editing the character.
 */
export function CharacterCreationForm(props: CharacterCreationFormProps): React.ReactElement {
	const pages = characterCreationPaginatedSchemas(props.playerCharacter);
	return (
		<PaginatedEditFormDialogue
			title={
				props.playerCharacter ? 'Create Player Character' : 'Create Non-Player Character'
			}
			pages={pages}
			onSubmit={(data) =>
				props.onSubmit(
					props.playerCharacter
						? playerCharacterFromFormData(data)
						: characterFromFormData(data),
				)
			}
			onCancel={props.onCancel}
		/>
	);
}

/**
 * Creates the input schemas for the character creation form.
 */
function characterCreationPaginatedSchemas(playerCharacter: boolean): Page[] {
	const pages: Page[] = [];

	// Page 1: The Basics
	// - [Player] (iff playerCharacter)
	// - name
	// - shortName
	// - species
	// - speciesUrl
	// - pronouns
	// - homeworld
	// - status
	// - imageResourceName
	// - summary
	const page1Schemas = new Map<string, DataEntry>();
	if (playerCharacter) {
		page1Schemas.set(
			'player',
			new StringEntry({
				initialValue: undefined,
				label: 'Player',
				elementId: 'player-character-creation-player-form',
				required: true,
				multiLine: false,
			}),
		);
	}
	page1Schemas.set(
		'name',
		new StringEntry({
			initialValue: undefined,
			label: 'Name',
			elementId: 'player-character-creation-name-form',
			required: true,
			multiLine: false,
		}),
	);
	page1Schemas.set(
		'shortName',
		new StringEntry({
			initialValue: undefined,
			label: 'Short Name',
			elementId: 'player-character-creation-short-name-form',
			required: false,
			multiLine: false,
		}),
	);
	page1Schemas.set(
		'species',
		new StringEntry({
			initialValue: undefined,
			label: 'Species',
			elementId: 'player-character-creation-species-form',
			required: true,
			multiLine: false,
		}),
	);
	page1Schemas.set(
		'speciesUrl',
		new StringEntry({
			initialValue: undefined,
			label: 'Species URL',
			elementId: 'player-character-creation-species-url-form',
			required: false,
			multiLine: false,
		}),
	);
	page1Schemas.set(
		'pronouns',
		new DropDownEntry({
			initialValue: undefined,
			label: 'Pronouns',
			elementId: 'player-character-creation-pronouns-form',
			required: false,
			options: characterPronounOptions,
		}),
	);
	page1Schemas.set(
		'homeworld',
		new StringEntry({
			initialValue: undefined,
			label: 'Homeworld',
			elementId: 'player-character-creation-homeworld-form',
			required: false,
			multiLine: false,
		}),
	);
	page1Schemas.set(
		'status',
		new DropDownEntry({
			initialValue: CharacterStatus.Active,
			label: 'Status',
			elementId: 'player-character-creation-status-form',
			required: true,
			options: characterStatusOptions,
		}),
	);
	page1Schemas.set(
		'imageResourceName',
		new StringEntry({
			initialValue: undefined,
			label: 'Image Resource Name',
			elementId: 'player-character-creation-image-resource-name-form',
			required: false,
			multiLine: false,
		}),
	);
	page1Schemas.set(
		'summary',
		new StringEntry({
			initialValue: undefined,
			label: 'Summary',
			elementId: 'player-character-creation-summary-form',
			required: false,
			multiLine: false,
		}),
	);
	page1Schemas.set(
		'nicknames',
		new ListEntry({
			initialValue: undefined,
			label: 'Nicknames',
			elementId: 'player-character-creation-nicknames-form',
		}),
	);

	pages.push({
		label: 'The Basics',
		formSchemas: page1Schemas,
	});

	// Page 2: Titles
	// - titles
	const page2Schemas = new Map<string, DataEntry>();
	page2Schemas.set(
		'titles',
		new ListEntry({
			initialValue: undefined,
			label: 'Titles',
			elementId: 'player-character-creation-titles-form',
		}),
	);

	pages.push({
		label: 'Titles',
		formSchemas: page2Schemas,
	});

	// Page 3: Affiliations
	// - titles
	const page3Schemas = new Map<string, DataEntry>();
	page3Schemas.set(
		'affiliations',
		new ListEntry({
			initialValue: undefined,
			label: 'Affiliations',
			elementId: 'player-character-creation-affiliations-form',
		}),
	);

	pages.push({
		label: 'Affiliations',
		formSchemas: page3Schemas,
	});

	// Page 4: KnownBy
	// - knownBy
	// - universallyKnown
	const page4Schemas = new Map<string, DataEntry>();
	page4Schemas.set(
		'knownBy',
		new ListEntry({
			initialValue: undefined,
			label: 'Known By',
			elementId: 'player-character-creation-known-by-form',
		}),
	);
	page4Schemas.set(
		'universallyKnown',
		new BooleanEntry({
			initialValue: false,
			label: 'Universally Known?',
			elementId: 'player-character-creation-universally-known-checkbox',
		}),
	);

	pages.push({
		label: 'Known By',
		formSchemas: page4Schemas,
	});

	// Page 5: Relationships
	// - relationships
	const page5Schemas = new Map<string, DataEntry>();
	page5Schemas.set(
		'relationships',
		new MapEntry({
			initialValue: undefined,
			label: 'Relationships',
			keyLabel: 'Relationship',
			valueLabel: 'Character Name',
			allowDuplicateKeys: true,
			elementId: 'player-character-creation-relationships-form',
		}),
	);

	pages.push({
		label: 'Relationships',
		formSchemas: page5Schemas,
	});

	// Page 6: Physical Attributes
	// - physicalAttributes
	const page6Schemas = new Map<string, DataEntry>();
	page6Schemas.set(
		'physicalAttributes',
		new MapEntry({
			initialValue: undefined,
			label: 'Physical Attributes',
			keyLabel: 'Attribute Name',
			valueLabel: 'Attribute Value',
			allowDuplicateKeys: false,
			elementId: 'player-character-creation-physical-attributes-form',
		}),
	);

	pages.push({
		label: 'Physical Attributes',
		formSchemas: page6Schemas,
	});

	return pages;
}

/**
 * Converts player character creation form data to a {@link PlayerCharacter} object.
 */
function playerCharacterFromFormData(data: Map<string, unknown>): PlayerCharacter {
	const characterBase: Character = characterFromFormData(data);

	const player = assertNotUndefined(data.get('player') as string);
	return {
		...characterBase,
		player,
	};
}

/**
 * Converts character creation form data to a {@link Character} object.
 */
function characterFromFormData(data: Map<string, unknown>): Character {
	const name = assertNotUndefined(data.get('name') as string);
	const shortName = assertUndefinedOrNonEmpty(data.get('shortName') as string);
	const species = assertUndefinedOrNonEmpty(data.get('species') as string);
	const speciesUrl = assertUndefinedOrNonEmpty(data.get('speciesUrl') as string);
	const pronouns = assertUndefinedOrNonEmpty(data.get('pronouns') as string);
	const homeworld = assertUndefinedOrNonEmpty(data.get('homeworld') as string);
	const affiliations = data.get('affiliations') as string[];
	const status = assertUndefinedOrNonEmpty(data.get('status') as string) as CharacterStatus;
	const knownBy = data.get('knownBy') as string[];
	const universallyKnown = data.get('universallyKnown') as boolean;
	const titles = data.get('titles') as string[];
	const imageResourceName = assertUndefinedOrNonEmpty(data.get('imageResourceName') as string);
	const summary = assertUndefinedOrNonEmpty(data.get('summary') as string);
	const nicknames = data.get('nicknames') as string[];

	const rawRelationships = assertUndefinedOrNonEmpty(data.get('relationships') as MapFormEntry[]);
	const rawPhysicalAttributes = assertUndefinedOrNonEmpty(
		data.get('physicalAttributes') as MapFormEntry[],
	);

	const relationships: Relationship[] | undefined =
		rawRelationships === undefined
			? undefined
			: rawRelationships.map((entry) => {
					return {
						kind: entry.key,
						characterName: entry.value,
					};
				});

	const physicalAttributes: PhysicalAttribute[] | undefined =
		rawPhysicalAttributes === undefined
			? undefined
			: rawPhysicalAttributes.map((entry) => {
					return {
						name: entry.key,
						value: entry.value,
					};
				});

	return {
		name,
		shortName,
		nicknames,
		species,
		speciesUrl,
		pronouns,
		homeworld,
		affiliations,
		status,
		knownBy,
		universallyKnown,
		titles,
		imageResourceName,
		summary,
		relationships,
		physicalAttributes,
	};
}
