/**
 * Gets all of the options of an enum as an array
 */
export function getEnumValues<T>(EnumType: { [s: string]: T } | ArrayLike<T>): T[] {
	const result = [];
	for (const [, value] of Object.entries(EnumType)) {
		result.push(value);
	}
	return result;
}
