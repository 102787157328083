import { Close } from '@mui/icons-material';
import { Card, CardActions, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { ErrorScreen, type ErrorScreenProps } from './ErrorScreen.js';

/**
 * {@link ErrorDialogue} input props
 */
export interface ErrorDialogueProps extends ErrorScreenProps {
	/**
	 * Function to invoke upon closing the dialogue.
	 */
	onClose: () => void;
}

/**
 * Error screen dialogue to display when an interactive edit fails to submit to the DB
 */
export function ErrorDialogue({ textLines, onClose }: ErrorDialogueProps): React.ReactElement {
	return (
		<Card
			style={{
				minWidth: '200px',
				maxHeight: '95%',
			}}
		>
			<ErrorScreen textLines={textLines} />
			<CardActions
				style={{
					display: 'flex',
					flexDirection: 'row-reverse',
				}}
			>
				<Tooltip title="Close">
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</Tooltip>
			</CardActions>
		</Card>
	);
}
