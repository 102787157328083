import { CharacterDropDownMenu, CharacterListEntry } from '@datapad/character-components';
import type { HasProfile } from '@datapad/common-props';
import { Typography } from '@mui/material';
import React from 'react';
import { menuLabelStyle, menuLabelTypographyVariant } from './Constants';

/**
 * {@link CharacterSelector} input props.
 */
export interface CharacterSelectorProps extends HasProfile {
	changeSelectedCharacter(newCharacterSelection: string | undefined): void;
}

/**
 * Datapad menu character-selection drop-down selector.
 */
export function CharacterSelector(props: CharacterSelectorProps): React.ReactElement {
	const { profile, changeSelectedCharacter } = props;

	if (profile.characters.length === 0) {
		return (
			<Typography variant={menuLabelTypographyVariant} style={menuLabelStyle}>
				Player has no characters
			</Typography>
		);
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
			}}
		>
			<Typography variant={menuLabelTypographyVariant} style={menuLabelStyle}>
				Signed in as:
			</Typography>
			{profile.characters.length === 1 ? (
				<CharacterListEntry characterName={profile.characters[0].name} button={false} />
			) : (
				<CharacterDropDownMenu
					characters={profile.characters}
					initialSelection={profile.signedInCharacter}
					changeSelection={(character) => changeSelectedCharacter(character?.name)}
					required={true}
					label="Select a character"
				/>
			)}
		</div>
	);
}
