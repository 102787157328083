import { Button, CardActions, CardContent, Tooltip } from '@mui/material';
import React from 'react';

/**
 * {@link ConfirmationDialogue} input props.
 */
export interface ConfirmationDialogueProps {
	onConfirm: () => void;
	onCancel: () => void;
}

/**
 * Simple confirmation dialogue card for use in modal windows
 */
export function ConfirmationDialogue(
	props: React.PropsWithChildren<ConfirmationDialogueProps>,
): React.ReactElement {
	return (
		<>
			<CardContent>{props.children}</CardContent>
			<CardActions>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Tooltip title="Confirm">
						<Button color="primary" fullWidth={false} onClick={props.onConfirm}>
							Confirm
						</Button>
					</Tooltip>
					<Tooltip title="Cancel">
						<Button color="secondary" fullWidth={false} onClick={props.onCancel}>
							Cancel
						</Button>
					</Tooltip>
				</div>
			</CardActions>
		</>
	);
}
