import { CharacterList } from '@datapad/character-components';
import type {
	CanDeleteCampaign,
	CanEditCampaign,
	HasCalendarLink,
	HasCampaign,
	HasCharacterLink,
} from '@datapad/common-props';
import { ConfirmationDialogue } from '@datapad/form-components';
import { type Campaign, toLongString } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import { ModalCard } from '@datapad/utility-components';
import { Delete, Edit } from '@mui/icons-material';
import {
	IconButton,
	ListItem,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { CampaignCreationForm } from './CampaignCreationForm.js';

// TODOs:
// - editable
// - character drop-down when editing active character

/**
 * {@link CampaignView} input props
 */
export interface CampaignViewProps
	extends CanDeleteCampaign,
		CanEditCampaign,
		HasCharacterLink,
		HasCalendarLink,
		HasCampaign {
	/**
	 * Whether or not editing (including deletion) is allowed.
	 */
	canEdit: boolean;
}

/**
 * Modal dialogue status
 */
enum ModalStatus {
	None,
	Editing,
	Deleting,
}

/**
 * Campaign view component. Displays {@link Campaign} data and allows editing.
 */
export function CampaignView(props: CampaignViewProps): React.ReactElement {
	const { campaign, handleCalendarLink, handleCharacterLink } = props;

	const [modalStatus, setModalStatus] = useState(ModalStatus.None);

	const maybeModal = (
		<EditingModal
			modalStatus={modalStatus}
			campaign={campaign}
			updateCampaign={(updatedCampaign) => {
				props.editCampaign(updatedCampaign);
				setModalStatus(ModalStatus.None);
			}}
			removeCampaign={() => {
				props.deleteCampaign(campaign);
				setModalStatus(ModalStatus.None);
			}}
			cancel={() => setModalStatus(ModalStatus.None)}
		/>
	);

	return (
		<>
			{maybeModal}
			<div
				style={{
					maxWidth: '600px',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Table
					style={{
						width: '100%',
					}}
				>
					<TableBody>
						<TableRow>
							<TableCell>
								<Typography variant="body1">
									<b>Name</b>
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1">{campaign.name}</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="body1">
									<b>Party</b>
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1">{campaign.party}</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="body1">
									<b>Current date</b>
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1">
									<ListItem
										button
										onClick={() => handleCalendarLink(campaign.currentDate)}
									>
										{toLongString(campaign.currentDate)}
									</ListItem>
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="body1">
									<b>Active Characters</b>
								</Typography>
							</TableCell>
							<TableCell>
								{campaign.characters === undefined ||
								campaign.characters.length === 0 ? (
									<Typography variant="body1">
										<i>The campaign has no active characters set.</i>
									</Typography>
								) : (
									<CharacterList
										characterNames={campaign.characters}
										onSelect={(character) =>
											handleCharacterLink(assertNotUndefined(character))
										}
									/>
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="body1">
									<b>Inactive Characters</b>
								</Typography>
							</TableCell>
							<TableCell>
								{campaign.inactiveCharacters === undefined ||
								campaign.inactiveCharacters.length === 0 ? (
									<Typography variant="body1">
										<i>The campaign has no inactive characters set.</i>
									</Typography>
								) : (
									<CharacterList
										characterNames={campaign.inactiveCharacters}
										onSelect={(character) =>
											handleCharacterLink(assertNotUndefined(character))
										}
									/>
								)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row-reverse',
					}}
				>
					<div
						id="profile-toolbar-buttons"
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Tooltip title="Edit campaign">
							<IconButton
								disabled={!props.canEdit}
								onClick={() => {
									setModalStatus(ModalStatus.Editing);
								}}
							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete campaign">
							<IconButton
								disabled={!props.canEdit}
								onClick={() => {
									setModalStatus(ModalStatus.Deleting);
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</div>
		</>
	);
}

interface EditingModalProps {
	modalStatus: ModalStatus;
	campaign: Campaign;
	cancel: () => void;
	updateCampaign: (updatedCampaign: Campaign) => void;
	removeCampaign: () => void;
}

/**
 * Editing modal dialogue.
 * Will be empty if the specified {@link ModalStatus} is {@link ModalStatus.None}.
 */
function EditingModal(props: EditingModalProps): React.ReactElement {
	let renderedDialogue: React.ReactElement | undefined;
	switch (props.modalStatus) {
		case ModalStatus.None:
			renderedDialogue = undefined;
			break;
		case ModalStatus.Editing:
			renderedDialogue = (
				<CampaignCreationForm
					existingCampaign={props.campaign}
					onSubmit={props.updateCampaign}
					onCancel={props.cancel}
				/>
			);
			break;
		case ModalStatus.Deleting:
			renderedDialogue = (
				<ModalCard open>
					<ConfirmationDialogue onConfirm={props.removeCampaign} onCancel={props.cancel}>
						<Typography variant="body1">
							{`Are you sure you wish to delete campaign "${props.campaign.name}"?`}
						</Typography>
						<br />
						<Typography variant="body2">
							{`Note that this action cannot be undone.`}
						</Typography>
					</ConfirmationDialogue>
				</ModalCard>
			);
			break;
		default:
			throw new Error(`Unrecognized ModalStatus value: "${props.modalStatus}".`);
	}

	if (renderedDialogue === undefined) {
		return <></>;
	} else {
		return (
			<ModalCard
				open
				onClose={props.cancel}
				style={{
					maxHeight: '95%',
				}}
			>
				{renderedDialogue}
			</ModalCard>
		);
	}
}
