import { Card, CardContent } from '@mui/material';
import React from 'react';
import { LoadingScreen } from './LoadingScreen.js';

/**
 * Loading screen dialogue displayed while waiting for edits to be submitted to the backend
 */
export function PendingDialogue(): React.ReactElement {
	return (
		<Card
			style={{
				minWidth: '200px',
				maxWidth: '400px',
				maxHeight: '95%',
			}}
		>
			<CardContent>
				<LoadingScreen />
			</CardContent>
		</Card>
	);
}
