import React from 'react';
import { BooleanForm } from '../BooleanForm.js';
import { DataEntry, type DataEntryProps } from './DataEntry.js';

/**
 * Input props for {@link BooleanEntry}
 */
export type BooleanEntryProps = DataEntryProps<boolean>;

/**
 * {@link DataEntry} for booleans.
 */
export class BooleanEntry extends DataEntry<boolean> {
	public constructor(props: DataEntryProps<boolean>) {
		super(props);
	}

	public errorMessage(): undefined {
		// No invalid states for this control
		return undefined;
	}

	public hasValueChanged(value: boolean | undefined): boolean {
		const resolvedValue = value === undefined ? false : value;
		const resolvedInitialValue = this.initialValue === undefined ? false : this.initialValue;

		return resolvedValue !== resolvedInitialValue;
	}

	/**
	 * {@inheritDoc DataEntry.renderForm}
	 */
	public renderForm(
		currentValue: boolean | undefined,
		onChange: (newValue: boolean | undefined) => void,
	): React.ReactElement {
		return (
			<BooleanForm
				value={currentValue}
				label={this.label}
				elementId={this.elementId}
				locked={this.locked}
				onChange={onChange}
			/>
		);
	}
}
