import type { Character } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import {
	ProfileContext,
	type SharedCalendarEventsMap,
	type SharedNonPlayerCharactersMap,
	type SharedPlayerCharactersMap,
	useKnownEvents,
} from '../../../utilities';
import TimelineApp from '../../Timeline';

/**
 * {@link Timeline} input props.
 */
export interface TimelineProps {
	calendarEventsMap: SharedCalendarEventsMap;
	playerCharactersMap: SharedPlayerCharactersMap;
	nonPlayerCharactersMap: SharedNonPlayerCharactersMap;
}

/**
 * Contacts app wrapper that handles translation of Fluid-backed state
 */
export function Timeline(props: TimelineProps): React.ReactElement {
	const { calendarEventsMap, playerCharactersMap, nonPlayerCharactersMap } = props;

	const { profile } = assertNotUndefined(React.useContext(ProfileContext));

	const events = useKnownEvents(profile, calendarEventsMap);

	/**
	 * Gets the character with the specified name from either the PC or NPC map.
	 * Throws if not found.
	 */
	const getCharacter = React.useCallback(
		(characterName: string): Character => {
			if (playerCharactersMap.has(characterName)) {
				return assertNotUndefined(playerCharactersMap.get(characterName));
			}
			if (nonPlayerCharactersMap.has(characterName)) {
				return assertNotUndefined(nonPlayerCharactersMap.get(characterName));
			}
			throw new Error(`No character found with name "${characterName}".`);
		},
		[playerCharactersMap, nonPlayerCharactersMap],
	);

	return <TimelineApp events={events} getCharacter={getCharacter} />;
}
