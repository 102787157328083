import type {
	CanCreateCharacter,
	CanDeleteCharacter,
	CanEditCharacter,
} from '@datapad/character-components';
import type { HasProfile } from '@datapad/common-props';
import type { NonPlayerCharacter, PlayerCharacter } from '@datapad/interfaces';
import { useWidth } from '@datapad/theming';
import React from 'react';
import type { CanChangeContactSelection, HasContacts } from '../CommonProps.js';
import { NarrowContactsView, WideContactsView } from './ContactsView.js';

/**
 * Externally specified props
 */
export interface DynamicWidthContactsProps
	extends HasProfile,
		HasContacts,
		CanCreateCharacter<NonPlayerCharacter>,
		CanEditCharacter,
		CanDeleteCharacter,
		CanChangeContactSelection {
	/**
	 * Name of the selected Contact, formatted as a URL string.
	 * @see {@link @datapad/interfaces#urlFormat}.
	 */
	selectedContactUrlString: string | undefined;

	/**
	 * Function to link to the `Profile` app with the selected player character.
	 */
	profileLink: (character: PlayerCharacter) => void;
}

/**
 * Core Contacts app component.
 */
export function DynamicWidthContacts(props: DynamicWidthContactsProps): React.ReactElement {
	const width = useWidth();

	const useNarrowView = width === 'xs' || width === 'sm';

	return useNarrowView ? <NarrowContactsView {...props} /> : <WideContactsView {...props} />;
}
