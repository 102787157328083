import type { IFluidContainer } from 'fluid-framework';

/**
 * {@link SetFluidContainerFunction} Redux code
 */

/**
 * Dispatch ID for the {@link SetFluidContainerFunction} action
 */
export const SET_FLUID_CONTAINER = 'SET_FLUID_CONTAINER';

/**
 * Typed dispatch ID for the {@link SetFluidContainerFunction} action
 */
export type SET_FLUID_CONTAINER = typeof SET_FLUID_CONTAINER;

/**
 * {@link SetFluidContainerFunction} action interface
 */
export interface SetFluidContainer {
	type: SET_FLUID_CONTAINER;
	container: IFluidContainer;
}

/**
 * {@link SetFluidContainerFunction} {@link https://redux.js.org/basics/actions#action-creators | Action Creator}
 */
export function setFluidContainer(container: IFluidContainer): SetFluidContainer {
	return {
		type: SET_FLUID_CONTAINER,
		container,
	};
}

/**
 * Sets the Datapad menu to "expanded" if true, "collapsed" if false.
 */
export type SetFluidContainerFunction = (container: IFluidContainer) => void;
