import { noopFunction } from '@datapad/utilities';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	Step,
	StepLabel,
	Stepper,
	Tooltip,
} from '@mui/material';
import React from 'react';
import type { DataEntry } from './data-types/index.js';

/**
 * Edit dialogue page
 */
export interface Page {
	label: string;
	formSchemas: Map<string, DataEntry>;
}

/**
 * Input props for {@link PaginatedEditFormDialogue}
 */
interface Props {
	title: string;
	pages: Page[];
	onSubmit: (data: Map<string, unknown>) => void;
	onCancel: () => void;
}

/**
 * {@link PaginatedEditFormDialogue} local state
 */
interface State {
	entries: Map<string, unknown>;
	currentPageIndex: number;
}

/**
 * Multi-page form widget for editing data based on an input schema
 */
export class PaginatedEditFormDialogue extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		const initialStateMap = new Map<string, unknown>();
		props.pages.forEach((page) => {
			page.formSchemas.forEach((value, key) => {
				if (value.initialValue !== undefined) {
					initialStateMap.set(key, value.initialValue);
				}
			});
		});

		this.state = {
			entries: initialStateMap,
			currentPageIndex: 0,
		};
	}

	private isPageStateValid(pageIndex: number): boolean {
		let isStateValid = true;

		this.props.pages[pageIndex].formSchemas.forEach((schema, formName) => {
			const state = this.state.entries.get(formName);
			if (!schema.isValueValid(state)) {
				isStateValid = false;
			}
		});
		return isStateValid;
	}

	private updateState(key: string, data: unknown): void {
		const stateMap = new Map<string, unknown>(this.state.entries);
		stateMap.set(key, data);
		this.setState({
			...this.state,
			entries: stateMap,
		});
	}

	private onSubmit(): void {
		this.props.onSubmit(this.state.entries);
	}

	private setCurrentPageIndex(newPageIndex: number): void {
		this.setState({
			...this.state,
			currentPageIndex: newPageIndex,
		});
	}

	/**
	 * {@inheritDoc react#React.Component.render}
	 * @override
	 */
	public override render(): React.ReactNode {
		const currentPageIndex = this.state.currentPageIndex;
		const isFirstPage = currentPageIndex === 0;
		const isFinalPage = currentPageIndex === this.props.pages.length - 1;

		const childNodes: React.ReactNode[] = [];
		this.props.pages[this.state.currentPageIndex].formSchemas.forEach((schema, formName) => {
			const child = this.renderForm(formName, schema);
			childNodes.push(
				<Grid item key={formName}>
					{child}
				</Grid>,
			);
		});

		const isPageStateValid = this.isPageStateValid(this.state.currentPageIndex);

		return (
			<Card>
				<CardHeader title={this.props.title} />
				<CardContent>
					<Grid container spacing={3}>
						{childNodes}
					</Grid>
				</CardContent>
				<Stepper activeStep={this.state.currentPageIndex}>
					{this.props.pages.map((page) => (
						<Step key={page.label}>
							<StepLabel>{page.label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						padding: '15px',
					}}
				>
					<Tooltip title="Back">
						<Button
							color="secondary"
							fullWidth={false}
							onClick={
								isFirstPage
									? noopFunction
									: () => this.setCurrentPageIndex(currentPageIndex - 1)
							}
							disabled={isFirstPage}
						>
							Back
						</Button>
					</Tooltip>
					<Tooltip title={isFinalPage ? 'Submit' : 'Next'}>
						<Button
							color="primary"
							fullWidth={false}
							onClick={
								isFinalPage
									? () => this.onSubmit()
									: () => this.setCurrentPageIndex(currentPageIndex + 1)
							}
							disabled={!isPageStateValid}
						>
							{isFinalPage ? 'Submit' : 'Next'}
						</Button>
					</Tooltip>
				</div>
			</Card>
		);
	}

	public renderForm(key: string, dataEntry: DataEntry): React.ReactNode {
		const currentValue = this.state.entries.get(key);

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		const onChangeFunction = (newValue: unknown) => this.updateState(key, newValue);

		return dataEntry.renderForm(currentValue, onChangeFunction);
	}
}
