import { MapFormDialogue, type MapFormEntry } from '@datapad/form-components';
import type { Character, PhysicalAttribute } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import { CharacterAttributeMapTable } from './CharacterAttributeMapTable.js';
import type { EditableCharacterProfileBaseProps } from './CommonProps.js';

/**
 * Simple card displaying a table of the provided {@link Character}'s
 * {@link Character.physicalAttributes}.
 */
export class CharacterPhysicalAttributes extends CharacterAttributeMapTable<EditableCharacterProfileBaseProps> {
	/**
	 * {@inheritDoc CharacterInfoCard.headerText}
	 */
	public readonly headerText = 'Physical Attributes';

	public constructor(props: EditableCharacterProfileBaseProps) {
		super(props);
	}

	/**
	 * {@inheritDoc CharacterAttributeMapTable.getEntryCount}
	 */
	protected getEntryCount(): number {
		return this.props.character.physicalAttributes?.length ?? 0;
	}

	async onSubmitAffilationChanges(
		newPhysicalAttributeEntries: MapFormEntry[] | undefined,
	): Promise<void> {
		const newPhysicalAttributes: PhysicalAttribute[] | undefined =
			newPhysicalAttributeEntries === undefined
				? undefined
				: newPhysicalAttributeEntries.map((entry) => {
						return {
							name: entry.key,
							value: entry.value,
						};
					});

		const characterUpdate: Character = {
			...this.props.character,
			physicalAttributes: newPhysicalAttributes,
		};

		await this.submitEdits(characterUpdate);
	}

	protected renderKey(entryIndex: number): React.ReactElement {
		const physicalAttributes = assertNotUndefined(this.props.character.physicalAttributes);
		return <b>{physicalAttributes[entryIndex].name}</b>;
	}

	protected renderValue(entryIndex: number): React.ReactElement {
		const physicalAttributes = assertNotUndefined(this.props.character.physicalAttributes);
		return <b>{physicalAttributes[entryIndex].value}</b>;
	}

	public renderEditForm(): React.ReactElement {
		const mapEntries: MapFormEntry[] | undefined =
			this.props.character.physicalAttributes === undefined
				? undefined
				: this.props.character.physicalAttributes.map((physicalAttribute) => {
						return { key: physicalAttribute.name, value: physicalAttribute.value };
					});
		return (
			<MapFormDialogue
				formTitle="Edit Physical Attributes"
				initialEntries={mapEntries}
				keyLabel="Attribute Name"
				valueLabel="Attribute Value"
				onSubmit={(newEntries) => this.onSubmitAffilationChanges(newEntries)}
				onCancel={() => this.cancelEdit()}
			/>
		);
	}
}
