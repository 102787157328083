import {
	BooleanEntry,
	type DataEntry,
	EditFormDialogue,
	ListEntry,
} from '@datapad/form-components';
import type { Character } from '@datapad/interfaces';
import { sortArray, urlFormat } from '@datapad/utilities';
import { List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { CharacterInfoCard } from './CharacterDataCard.js';
import { CharacterListEntry } from './CharacterList.js';
import type { CanHandleCharacterLink, EditableCharacterProfileBaseProps } from './CommonProps.js';

const divStyle = {
	width: '100%',
	padding: '10px',
};

/**
 * Input props for {@link CharacterKnownBy}
 */
export type CharacterKnownByProps = EditableCharacterProfileBaseProps & CanHandleCharacterLink;

export class CharacterKnownBy extends CharacterInfoCard<CharacterKnownByProps> {
	/**
	 * {@inheritDoc CharacterInfoCard.headerText}
	 */
	public readonly headerText = 'KnownBy';

	public constructor(props: CharacterKnownByProps) {
		super(props);
	}

	private getSortedKnownByList(): string[] | undefined {
		return this.props.character.knownBy === undefined
			? undefined
			: sortArray(this.props.character.knownBy, (a, b) => a.localeCompare(b));
	}

	private async onSubmitKnownByChanges(data: Map<string, unknown>): Promise<void> {
		const knownBy = data.get('knownBy') as string[];
		const universallyKnown = data.get('universallyKnown') as boolean;

		const characterUpdate: Character = {
			...this.props.character,
			knownBy,
			universallyKnown,
		};
		await this.submitEdits(characterUpdate);
	}

	public renderBodyContents(): React.ReactElement {
		const knownBy = this.getSortedKnownByList();
		const universallyKnown = this.props.character.universallyKnown;
		if (knownBy === undefined || knownBy.length === 0) {
			if (universallyKnown) {
				return this.renderKnownByAll();
			} else {
				return this.renderNoKnownBy();
			}
		} else {
			return (
				<div>
					{universallyKnown ? this.renderKnownByAll() : <></>}
					{this.renderKnownByList(knownBy)}
				</div>
			);
		}
	}

	private renderKnownByList(knownByList: string[]): React.ReactElement {
		return (
			<List>
				{knownByList.map((playerCharacterName) =>
					this.renderKnownByEntry(playerCharacterName),
				)}
			</List>
		);
	}

	private renderKnownByEntry(playerCharacterName: string): React.ReactElement {
		const listItemContents = <Typography variant="body2">{playerCharacterName}</Typography>;
		const listItemKey = `known-by-list-${urlFormat(playerCharacterName)}`;

		if (this.props.handleCharacterLink === undefined) {
			return <ListItem key={listItemKey}>{listItemContents}</ListItem>;
		} else {
			return (
				<CharacterListEntry
					characterName={playerCharacterName}
					onClick={() => this.props.handleCharacterLink!(playerCharacterName)}
				/>
			);
		}
	}

	private renderKnownByAll(): React.ReactElement {
		return (
			<div style={divStyle}>
				<Typography variant="body2">This character is universally known</Typography>
			</div>
		);
	}

	private renderNoKnownBy(): React.ReactElement {
		return (
			<div style={divStyle}>
				<Typography variant="body2">
					This character is not known by any of your characters
				</Typography>
			</div>
		);
	}

	public renderEditForm(): React.ReactElement {
		const schemas = this.createEditSchemas();
		return (
			<EditFormDialogue
				title="Edit Known By"
				schemas={schemas}
				onSubmit={(data) => this.onSubmitKnownByChanges(data)}
				onCancel={() => this.cancelEdit()}
			/>
		);
	}

	private createEditSchemas(): Map<string, DataEntry> {
		const character = this.props.character;
		return new Map<string, DataEntry>([
			[
				'knownBy',
				new ListEntry({
					initialValue:
						character.knownBy === undefined
							? undefined
							: sortArray(character.knownBy, (a, b) => a.localeCompare(b)),
					label: 'Known By',
					elementId: 'character-edit-form-known-by',
					locked: false,
				}),
			],
			[
				'universallyKnown',
				new BooleanEntry({
					initialValue: character.universallyKnown,
					label: 'Universally Known',
					elementId: 'character-edit-form-universally-known',
					locked: false,
				}),
			],
		]);
	}
}
