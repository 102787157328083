import { CharacterCreationForm, type CanCreateCharacter } from '@datapad/character-components';
import type { NonPlayerCharacter } from '@datapad/interfaces';
import { ModalCard } from '@datapad/utility-components';
import { Add, Close, FilterList, Refresh } from '@mui/icons-material';
import {
	AppBar,
	FormControl,
	IconButton,
	InputLabel,
	List,
	ListItem,
	MenuItem,
	Select,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { type ChangeEvent } from 'react';
import {
	type ContactsFilterActions,
	type ContactsFilterOptions,
	type ContactsFilterState,
	SortBy,
} from './SortingAndFiltering.js';

type Props = ContactsFilterState &
	ContactsFilterOptions &
	ContactsFilterActions &
	CanCreateCharacter<NonPlayerCharacter>;

/**
 * Modal dialogue status
 */
enum ModalStatus {
	None,
	ViewingFilterOptions,
	CreatingCharacter,
}

/**
 * {@link Toolbar} state
 */
interface ToolbarState {
	modalStatus: ModalStatus;
}

/**
 * Height of the toolbar component
 */
export const toolbarHeightInPixels = 55;

class ToolbarBase extends React.Component<Props, ToolbarState> {
	protected constructor(props: Props) {
		super(props);
		this.state = {
			modalStatus: ModalStatus.None,
		};
	}

	protected setModalStatus(modalStatus: ModalStatus): void {
		this.setState({
			...this.state,
			modalStatus,
		});
	}

	protected async onSubmitNewCharacter(character: NonPlayerCharacter): Promise<void> {
		this.props.createCharacter(character);
		this.setModalStatus(ModalStatus.None);
	}

	protected renderMaybeModal(): React.ReactElement {
		let renderedDialogue: React.ReactElement | undefined;

		switch (this.state.modalStatus) {
			case ModalStatus.None:
				renderedDialogue = undefined;
				break;
			case ModalStatus.ViewingFilterOptions:
				renderedDialogue = this.renderOptionsModal();
				break;
			case ModalStatus.CreatingCharacter:
				renderedDialogue = (
					<CharacterCreationForm
						playerCharacter={false}
						onSubmit={(character) =>
							this.onSubmitNewCharacter(character as NonPlayerCharacter)
						}
						onCancel={() => this.setModalStatus(ModalStatus.None)}
					/>
				);
				break;
			default:
				throw new Error(`Unrecognized ModalStatus value: "${this.state.modalStatus}".`);
		}
		if (renderedDialogue === undefined) {
			return <></>;
		} else {
			return (
				<ModalCard
					open
					onClose={() => this.setModalStatus(ModalStatus.None)}
					style={{
						maxHeight: '95%',
					}}
				>
					{renderedDialogue}
				</ModalCard>
			);
		}
	}

	private renderOptionsModal(): React.ReactElement {
		return (
			<ModalCard open onClose={() => this.setModalStatus(ModalStatus.None)}>
				<div
					style={{
						padding: '10px',
					}}
				>
					<div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									minHeight: '100%',
									padding: '5px',
								}}
							>
								<Typography variant="h5">Filters</Typography>
							</div>
							<div>
								<Tooltip title="Close">
									<IconButton
										onClick={() => this.setModalStatus(ModalStatus.None)}
									>
										<Close />
									</IconButton>
								</Tooltip>
							</div>
						</div>
						<List>
							<ListItem>
								{renderSortByDropDown(this.props.sorting, this.props.updateSorting)}
							</ListItem>
							<ListItem>
								{renderNameFilterBox(
									this.props.nameFilter,
									this.props.updateNameFilter,
								)}
							</ListItem>
							<ListItem>
								{renderFactionFilterDropDown(
									this.props.factionFilter,
									this.props.factionOptions,
									this.props.updateFactionSelection,
								)}
							</ListItem>
						</List>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row-reverse',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						{renderClearFiltersButton(this.props.clearAllFilters)}
					</div>
				</div>
			</ModalCard>
		);
	}

	protected renderCreateCharacterButton(): React.ReactElement {
		return (
			<Tooltip title="Create new NPC">
				<IconButton onClick={() => this.setModalStatus(ModalStatus.CreatingCharacter)}>
					<Add />
				</IconButton>
			</Tooltip>
		);
	}
}
/**
 * Wide variant of the toolbar
 */
export class WideToolbar extends ToolbarBase {
	public constructor(props: Props) {
		super(props);
	}

	public override render(): React.ReactNode {
		return (
			<AppBar position="static">
				<Toolbar
					id="contacts-toolbar-div"
					style={{
						height: `${toolbarHeightInPixels}px`,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{this.renderMaybeModal()}
					<div
						id="contacts-toolbar-filters"
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						{renderSortByDropDown(this.props.sorting, this.props.updateSorting)}
						{renderNameFilterBox(this.props.nameFilter, this.props.updateNameFilter)}
						{renderFactionFilterDropDown(
							this.props.factionFilter,
							this.props.factionOptions,
							this.props.updateFactionSelection,
						)}
					</div>
					<div>
						{this.renderCreateCharacterButton()}
						{renderClearFiltersButton(this.props.clearAllFilters)}
					</div>
				</Toolbar>
			</AppBar>
		);
	}
}

/**
 * Narrow variant of the toolbar
 */
export class NarrowToolbar extends ToolbarBase {
	public constructor(props: Props) {
		super(props);
	}

	public override render(): React.ReactElement {
		return (
			<AppBar position="static">
				<Toolbar
					id="contacts-toolbar-div"
					style={{
						height: `${toolbarHeightInPixels}px`,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{this.renderMaybeModal()}
					<Tooltip title="Filter options">
						<IconButton
							onClick={() => this.setModalStatus(ModalStatus.ViewingFilterOptions)}
						>
							<FilterList />
						</IconButton>
					</Tooltip>
					<div>{this.renderCreateCharacterButton()}</div>
				</Toolbar>
			</AppBar>
		);
	}
}

/**
 * Renders a drop-down selection for sorting the characters
 * @param currentSortBySelection - Currently selected sorting option
 * @param onUpdateSortBySelection - Callback to invoke when the sort-by selection changes
 */
function renderSortByDropDown(
	currentSortBySelection: SortBy,
	onUpdateSortBySelection: (newValue: SortBy) => void,
): React.ReactNode {
	return renderDropDown(
		currentSortBySelection,
		Object.values(SortBy),
		onUpdateSortBySelection,
		'Sort By',
		undefined, // No "all" option for this drop-down
		'sort-by-filter',
	);
}

/**
 * Renders a text-box based character name filter
 * @param currentNameFilter - Current value of the character name filter
 * @param onUpdateNameFilter - Callback to invoke when the filter value is updated
 */
function renderNameFilterBox(
	currentNameFilter: string,
	onUpdateNameFilter: (newValue: string) => void,
): React.ReactElement {
	return (
		<div
			style={{
				height: '100%',
				minWidth: '100px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				paddingLeft: '5px',
				paddingRight: '5px',
				textAlign: 'left',
			}}
		>
			<TextField
				type="search"
				value={currentNameFilter}
				label={`Filter Name`}
				id={`name_filter`}
				variant="outlined"
				multiline={false}
				size="small"
				onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
					onUpdateNameFilter(event.target.value?.toLocaleLowerCase())
				}
			/>
		</div>
	);
}

/**
 * Renders a drop-down selection filter for Faction Affiliations
 * @param currentFactionSelection - Currently selected faction
 * @param factionOptions - Faction options for the drop-down
 * @param onUpdateFactionSelection - Callback to invoke when the faction selection changes
 */
function renderFactionFilterDropDown(
	currentFactionSelection: string,
	factionOptions: string[],
	onUpdateFactionSelection: (newValue: string) => void,
): React.ReactElement {
	return renderDropDown(
		currentFactionSelection,
		factionOptions,
		onUpdateFactionSelection,
		'Filter Affiliation',
		'Any Factions',
		'faction-filter',
	);
}

/**
 * Renders a drop-down selection filter
 * @param currentSelection - Currently selected value
 * @param options - Options for the drop-down
 * @param onUpdateSelection - Callback to invoke when the selection changes
 * @param label - Label to display on the drop-down UI element
 * @param allOptionString - Label to display for the "all" option.
 * If list does not have an "all" option, specify undefined.
 * @param keyPreamble - Value to preface all option keys with
 */
function renderDropDown<T extends string>(
	currentSelection: T | undefined,
	options: T[],
	onUpdateSelection: (newValue: T) => void,
	label: string,
	allOptionString: string | undefined,
	keyPreamble: string,
): React.ReactElement {
	if (currentSelection && !options.includes(currentSelection)) {
		throw new Error(
			`Selected option "${currentSelection}" is not one of the specified options for dropdown "${label}".`,
		);
	}

	const filterOptions: React.ReactNode[] = [];

	if (allOptionString) {
		filterOptions.push(
			<MenuItem key={`${keyPreamble}-option-none`} value={undefined}>
				<em>{allOptionString}</em>
			</MenuItem>,
		);
	}

	options.forEach((option) => {
		filterOptions.push(
			<MenuItem key={`${keyPreamble}-option-${option}`} value={option}>
				{option}
			</MenuItem>,
		);
	});

	const labelId = `${keyPreamble}-label`;

	return (
		<div
			style={{
				height: '100%',
				minWidth: '160px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				paddingLeft: '5px',
				paddingRight: '5px',
				textAlign: 'left',
			}}
		>
			<FormControl variant="outlined" size="small">
				<InputLabel id={labelId}>{label}</InputLabel>
				<Select
					id={`${keyPreamble}-select`}
					labelId={labelId}
					label={label}
					value={currentSelection}
					onChange={(event) => onUpdateSelection(event.target.value as T)}
					variant="outlined"
				>
					{filterOptions}
				</Select>
			</FormControl>
		</div>
	);
}

/**
 * Renders the "clear filters" button with the appropriate tooltip text.
 */
function renderClearFiltersButton(clearFilters: () => void): React.ReactElement {
	return (
		<Tooltip title="Reset fliters and sorting">
			<IconButton onClick={clearFilters}>
				<Refresh />
			</IconButton>
		</Tooltip>
	);
}
