import type {
	CanCreateCharacter,
	CanDeleteCharacter,
	CanEditCharacter,
} from '@datapad/character-components';
import type { PlayerCharacter } from '@datapad/interfaces';
import { Profile as _Profile } from '@datapad/profile';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import { ProfileContext } from '../utilities';

/**
 * Externally specified props
 */
export interface ProfileProps
	extends CanCreateCharacter<PlayerCharacter>,
		CanEditCharacter<PlayerCharacter>,
		CanDeleteCharacter<PlayerCharacter> {
	/**
	 * Function to call to handle a link to the Contacts app
	 */
	onContactLink: (contactName: string) => void;
}

/**
 * Profile app view.
 *
 * @remarks Depends on {@link ProfileContext} being present.
 */
export function Profile(props: ProfileProps): React.ReactElement {
	const { profile } = assertNotUndefined(React.useContext(ProfileContext));
	return <_Profile {...props} profile={profile} />;
}
