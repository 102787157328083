import { type Breakpoint, type Theme, useMediaQuery, useTheme } from '@mui/material';

/**
 * Hook for using material breakpoints
 * @remarks
 * Be careful using this hook. It only works because the number of breakpoints in theme is static.
 * It will break once you change the number of breakpoints.
 *
 * See {@link https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level}
 *
 * Taken from {@link https://mui.com/material-ui/react-use-media-query/#migrating-from-withwidth}.
 */
export function useWidth(): Breakpoint | null {
	const theme: Theme = useTheme();
	const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();
	return (
		keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const matches = useMediaQuery(theme.breakpoints.up(key));
			return !output && matches ? key : output;
		}, null) || 'xs'
	);
}
