import { ObjectDropDownEntry } from '@datapad/form-components';
import type { Character } from '@datapad/interfaces';
import React from 'react';
import { CharacterListEntry } from './CharacterList.js';

/**
 * {@link CharacterDropDownMenu} input props.
 */
export interface CharacterDropDownMenuProps {
	characters: Character[];
	initialSelection?: Character;
	changeSelection(newSelection: Character | undefined): void;

	/**
	 * {@inheritDoc @datapad/form-components#DropDownEntry.required}
	 */
	required: boolean;

	/**
	 * {@inheritDoc @datapad/form-components#DataEntry.label}
	 */
	label: string;
}

/**
 * Character selection drop-down menu.
 */
export function CharacterDropDownMenu(props: CharacterDropDownMenuProps): React.ReactElement {
	const { characters, initialSelection, changeSelection, required, label } = props;

	const characterDropDownForm = new ObjectDropDownEntry<Character>({
		initialValue: initialSelection,
		options: characters,
		required,
		label,
		getOptionKey: (character) => character.name,
		renderOption: (character) => (
			<CharacterListEntry characterName={character.name} button={false} />
		),
	});

	return characterDropDownForm.renderForm(initialSelection, changeSelection);
}
