import {
	type DataEntry,
	DropDownEntry,
	EditFormDialogue,
	StringEntry,
} from '@datapad/form-components';
import { type Player, type PlayerKind, playerKindOptions } from '@datapad/interfaces';
import { assertNotUndefined, assertUndefinedOrNonEmpty } from '@datapad/utilities';
import React from 'react';

/**
 * Input props for {@link PlayerCreationForm}
 */
export interface Props {
	/**
	 * Existing player to update. If set, form can be treated as an edit/update form.
	 * If not, form can be treated as a creation form.
	 */
	existingPlayer?: Player;
	onSubmit: (player: Player) => void;
	onCancel: () => void;
}

/**
 * Form for creating a new {@link Player}.
 * Intended for use in modal dialogues.
 */
export function PlayerCreationForm(props: Props): React.ReactElement {
	const schemas = playerCreactionSchemas(props.existingPlayer);
	return (
		<EditFormDialogue
			title="Create New Player"
			schemas={schemas}
			onSubmit={(data) => props.onSubmit(playerFromFormData(data))}
			onCancel={props.onCancel}
		/>
	);
}

/**
 * Creates the input schemas for the player creation form.
 */
function playerCreactionSchemas(existingPlayer?: Player): Map<string, DataEntry> {
	function createElementId(formName: string): string {
		return `player-creation-${formName}-form`;
	}

	const schemas = new Map<string, DataEntry>();
	schemas.set(
		'userName',
		new StringEntry({
			initialValue: existingPlayer?.userName,
			label: 'User Name',
			elementId: createElementId('user-name'),
			required: true,
			multiLine: false,
			locked: existingPlayer !== undefined, // Cannot edit username if editing an existing user
		}),
	);

	schemas.set(
		'playerKind',
		new DropDownEntry({
			initialValue: existingPlayer?.playerKind,
			label: 'Player Kind',
			elementId: createElementId('player-kind'),
			required: true,
			options: playerKindOptions,
		}),
	);

	schemas.set(
		'defaultCharacter',
		new StringEntry({
			initialValue: existingPlayer?.defaultCharacter,
			label: 'Default Character Name',
			elementId: createElementId('default-character'),
			required: false,
			multiLine: false,
		}),
	);

	schemas.set(
		'nickname',
		new StringEntry({
			initialValue: existingPlayer?.nickname,
			label: 'User Nickname',
			elementId: createElementId('nickname'),
			required: false,
			multiLine: false,
		}),
	);

	return schemas;
}

/**
 * Converts player-creation form data to a {@link Player}.
 */
function playerFromFormData(data: Map<string, unknown>): Player {
	const userName = assertNotUndefined(data.get('userName') as string);
	const playerKind = assertNotUndefined(data.get('playerKind') as PlayerKind);
	const defaultCharacter = assertUndefinedOrNonEmpty(data.get('defaultCharacter') as string);
	const nickname = assertUndefinedOrNonEmpty(data.get('nickname') as string);

	return {
		userName,
		playerKind,
		defaultCharacter,
		nickname,
	};
}
