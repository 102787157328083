import { urlFormat } from '@datapad/utilities';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { DataEntry, type DataEntryProps } from './DataEntry.js';

/**
 * Input props for {@link DropDownEntry}
 */
export interface DropDownEntryProps extends DataEntryProps<string> {
	/**
	 * {@inheritDoc DropDownEntry.required}
	 */
	required: boolean;

	/**
	 * {@inheritDoc DropDownEntry.options}
	 */
	options: string[];
}

/**
 * DataEntry for strings.
 */
export class DropDownEntry extends DataEntry<string> {
	/**
	 * Indicates whether or not a value is required to be submitted for this entry.
	 */
	public readonly required: boolean;

	/**
	 * Valid options for the dropdown
	 */
	public readonly options: string[];

	public constructor(props: DropDownEntryProps) {
		super(props);

		if (props.initialValue && !props.options.includes(props.initialValue)) {
			throw new Error(
				`Specified initial value is not valid for drop-down options: "${props.initialValue}".`,
			);
		}
		if (props.options.length === 0) {
			throw new Error('Must specify non-empty set of drop-down options.');
		}

		this.required = props.required;
		this.options = props.options;
	}

	public errorMessage(value: string | undefined): string | undefined {
		if (this.required && value === undefined) {
			return 'Option is required';
		} else if (value !== undefined && !this.options.includes(value)) {
			return 'Invalid selection';
		}
		return undefined;
	}

	public hasValueChanged(value: string | undefined): boolean {
		return value !== this.initialValue;
	}

	/**
	 * {@inheritDoc DataEntry.renderForm}
	 */
	public renderForm(
		currentValue: string | undefined,
		onChange: (newValue: string | undefined) => void,
	): React.ReactElement {
		const error = !this.isValueValid(currentValue);
		const errorMessage = this.errorMessage(currentValue);

		const menuOptions: React.ReactNode[] = [];

		if (!this.required) {
			menuOptions.push(
				<MenuItem key={`${this.elementId}-option-none`} value={undefined}>
					<i>None</i>
				</MenuItem>,
			);
		}

		this.options.forEach((option) => {
			menuOptions.push(
				<MenuItem key={`${this.elementId}-option-${urlFormat(option)}`} value={option}>
					{option}
				</MenuItem>,
			);
		});

		return (
			<FormControl variant="outlined" size="small" disabled={this.locked}>
				<InputLabel id="character-selection-label">{this.label}</InputLabel>
				<Select
					id={this.elementId}
					labelId={`${this.elementId}-label`}
					label={this.label}
					value={currentValue}
					onChange={(event) => {
						onChange(event.target.value as string);
					}}
					error={error}
					variant="outlined"
					autoWidth
					style={{
						minWidth: '120px',
					}}
				>
					{menuOptions}
				</Select>
				<FormHelperText>{errorMessage}</FormHelperText>
			</FormControl>
		);
	}
}
