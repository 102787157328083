import { cloneArray, haveEqualContents } from '@datapad/utilities';
import { Card } from '@mui/material';
import React from 'react';
import { FormSubmissionFooter } from './FormSubmissionFooter.js';
import { ListForm } from './ListForm.js';

/**
 * Input props for {@link ListFormDialogue}
 */
export interface ListFormDialogueProps {
	formTitle: string;
	entryLabel?: string;
	initialValues: string[] | undefined;
	onSubmit: (newList: string[] | undefined) => void;
	onCancel: () => void;
}

/**
 * {@link ListFormDialogue} internal state
 */
interface ListFormDialogueState {
	values: string[] | undefined;
}

/**
 * String list form
 */
export class ListFormDialogue extends React.Component<
	ListFormDialogueProps,
	ListFormDialogueState
> {
	public constructor(props: ListFormDialogueProps) {
		super(props);
		this.state = {
			values: props.initialValues === undefined ? undefined : cloneArray(props.initialValues),
		};
	}

	isStateValidForSubmission(): boolean {
		if (this.state.values === undefined && this.props.initialValues === undefined) {
			return true;
		}
		if (this.state.values === undefined || this.props.initialValues === undefined) {
			return false;
		}
		for (const value of this.state.values) {
			if (value.length === 0) {
				return false;
			}
		}
		return (
			this.props.initialValues === undefined ||
			!haveEqualContents(this.props.initialValues, this.state.values, (a, b) => a === b)
		);
	}

	onUpdate(newValues: string[] | undefined): void {
		this.setState({
			...this.state,
			values: newValues,
		});
	}

	/**
	 * {@inheritDoc react#React.Component.render}
	 * @override
	 */
	public override render(): React.ReactElement {
		const isStateValidForSubmission = this.isStateValidForSubmission();
		return (
			<Card>
				<ListForm
					initialValues={this.props.initialValues}
					formTitle={this.props.formTitle}
					entryLabel={this.props.entryLabel}
					onChange={(newList) => this.onUpdate(newList)}
				/>
				<FormSubmissionFooter
					onSubmit={() => this.props.onSubmit(this.state.values)}
					onCancel={this.props.onCancel}
					submitDisabled={!isStateValidForSubmission}
				/>
			</Card>
		);
	}
}
