/**
 * Common state for editable components.
 */
export interface EditingState {
	/**
	 * Current editing status of the component.
	 */
	editStatus: EditStatus;
}

/**
 * Status of an edit operation.
 */
export enum EditStatus {
	None,
	Editing,
	Pending,
	Error,
}
