import { type Birthday, daysPerYear } from '@datapad/interfaces';
import { Card, FormControl, FormHelperText, Grid, InputLabel } from '@mui/material';
import React from 'react';
import { DataEntry, type DataEntryProps } from './DataEntry.js';
import { NumberEntry } from './NumberEntry.js';

/**
 * Input props for {@link BirthdayEntry}.
 */
export interface BirthdayEntryProps extends DataEntryProps<Partial<Birthday>> {
	/**
	 * {@inheritDoc NumberEntry.required}
	 */
	required: boolean;
}

/**
 * {@link DataEntry} for {@link @datapad/interfaces#Birthday}s.
 */
export class BirthdayEntry extends DataEntry<Partial<Birthday>> {
	/**
	 * Indicates whether or not a value is required to be submitted for this entry.
	 */
	public readonly required: boolean;

	private readonly yearEntry: NumberEntry;
	private readonly dayEntry: NumberEntry;

	public constructor(props: BirthdayEntryProps) {
		super(props);

		this.required = props.required;
		this.yearEntry = new NumberEntry({
			initialValue: props.initialValue?.year,
			label: 'Year (BBY)',
			elementId: props.elementId === undefined ? undefined : `${props.elementId}-year`,
			locked: props.locked,
			decimals: false,
			required: this.required,
		});

		this.dayEntry = new NumberEntry({
			initialValue: props.initialValue?.day,
			label: 'Day',
			elementId: props.elementId === undefined ? undefined : `${props.elementId}-day`,
			locked: props.locked,
			min: 0,
			max: daysPerYear - 1,
			decimals: false,
			required: this.required,
		});
	}

	/**
	 * {@inheritDoc DataEntry.errorMessage}
	 */
	public errorMessage(value: Partial<Birthday> | undefined): string | undefined {
		if (this.required && value === undefined) {
			return 'Value is required';
		}

		const yearError = this.yearEntry.errorMessage(value?.year);
		const dayError = this.dayEntry.errorMessage(value?.day);
		if (yearError !== undefined || dayError !== undefined) {
			return 'Invalid year and/or day value';
		}

		return undefined;
	}

	public hasValueChanged(value: Partial<Birthday> | undefined): boolean {
		return value !== this.initialValue;
	}

	/**
	 * {@inheritDoc DataEntry.renderForm}
	 */
	public renderForm(
		currentValue: Partial<Birthday> | undefined,
		onChange: (newValue: Partial<Birthday> | undefined) => void,
	): React.ReactElement {
		const error = !this.isValueValid(currentValue);
		const errorMessage = this.errorMessage(currentValue);

		const renderedYearForm = this.yearEntry.renderForm(currentValue?.year, (year) =>
			onChange({ ...currentValue, year }),
		);
		const renderedDayForm = this.dayEntry.renderForm(currentValue?.day, (day) =>
			onChange({ ...currentValue, day }),
		);

		return (
			<FormControl variant="outlined" id={this.elementId} error={error}>
				<InputLabel shrink>{this.label}</InputLabel>
				<Card
					variant="outlined"
					style={{
						padding: '10px',
					}}
				>
					<Grid
						container
						spacing={1}
						style={{
							padding: '10px',
						}}
					>
						{renderedYearForm}
						{renderedDayForm}
					</Grid>
				</Card>
				<FormHelperText>{errorMessage}</FormHelperText>
			</FormControl>
		);
	}
}
