import { ScopeType } from '@fluidframework/azure-service-utils/legacy';
import {
	type BackendQueryParameter,
	type BackendQueryResult,
	executeBackendFunction,
} from './NetlifyUtilities.js';

/**
 * Fetches the app's Fluid Container ID from the backend.
 * @returns The ID, if successful. Otherwise, `false`.
 */
export async function getFluidContainerId(): Promise<BackendQueryResult<string>> {
	interface QueryResult {
		containerId: string;
	}

	const result = await executeBackendFunction<QueryResult>('GetFluidContainerId');

	if (result) {
		return result.containerId;
	} else {
		return false;
	}
}

/**
 * Fetches the app's Fluid Tenant ID from the backend.
 * @returns The ID, if successful. Otherwise, `false`.
 */
export async function getFluidTenantId(): Promise<BackendQueryResult<string>> {
	interface QueryResult {
		tenantId: string;
	}

	const result = await executeBackendFunction<QueryResult>('GetFluidTenantId');

	if (result) {
		return result.tenantId;
	} else {
		return false;
	}
}

/**
 * Fetches an Azure Fluid Relay access token from the backend.
 * @param tenantId - Tenant ID to which the Fluid Container belongs.
 * @param fluidContainerId - ID of the Fluid Container the token will be used to access.
 * @param userName - Username with which the token will be associated.
 * @param tokenLifetimeInSeconds - The allowed lifetime of the token, expressed in seconds.
 * Must be \> 0 and finite.
 * @returns The token, if successful. Otherwise, `false`.
 */
export async function getAzureFluidRelayAccessToken(
	tenantId: string,
	fluidContainerId: string | undefined,
	userName: string,
	tokenLifetimeInSeconds: number,
): Promise<BackendQueryResult<string>> {
	const scopes = [ScopeType.DocRead, ScopeType.DocWrite, ScopeType.SummaryWrite];
	const queryParameters: BackendQueryParameter[] = [
		{
			name: 'userId',
			value: userName,
		},
		{
			name: 'userNickname',
			value: userName,
		},
		{
			name: 'tenantId',
			value: tenantId,
		},
		{
			name: 'tokenLifetimeInSeconds',
			value: tokenLifetimeInSeconds,
		},
		{
			name: 'scopes',
			value: JSON.stringify(scopes),
		},
	];

	if (fluidContainerId !== undefined) {
		queryParameters.push({
			name: 'fluidContainerId',
			value: fluidContainerId,
		});
	}

	interface QueryResult {
		token: string;
	}

	const result = await executeBackendFunction<QueryResult>(
		'GetAzureFluidRelayAccessToken',
		queryParameters,
	);

	if (result) {
		return result.token;
	} else {
		return false;
	}
}
