import { type PlayerCharacter, urlSearchFromCharacterName } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import {
	DatapadAppStateContext,
	ProfileContext,
	type SharedPlayerCharactersMap,
} from '../../../utilities';
import { Profile as _Profile } from '../../Profile';
import { AppId } from '../AppId';

/**
 * {@link Profile} input props.
 */
export interface ProfileProps {
	/**
	 * See {@link SharedPlayerCharactersMap}.
	 */
	playerCharactersMap: SharedPlayerCharactersMap;
}

/**
 * Contacts app wrapper that handles translation of Fluid-backed state
 */
export function Profile(props: ProfileProps): React.ReactElement {
	const { playerCharactersMap } = props;

	const { profile } = assertNotUndefined(React.useContext(ProfileContext));

	const { changeAppSelection } = assertNotUndefined(React.useContext(DatapadAppStateContext));

	/**
	 * Navigate to the specified contact's page in the Contacts app.
	 */
	function onContactLink(characterName: string): void {
		changeAppSelection(AppId.Contacts, urlSearchFromCharacterName(characterName));
	}

	/**
	 * Create a new character
	 */
	function createCharacter(character: PlayerCharacter): void {
		if (!profile.dmMode) {
			throw new Error('Only DM may create player characters.');
		}
		playerCharactersMap.set(character.name, character);
	}

	/**
	 * Delete an existing character
	 */
	function deleteCharacter(character: PlayerCharacter): void {
		if (!profile.dmMode) {
			throw new Error('Only DM may delete player characters.');
		}
		playerCharactersMap.delete(character.name);
	}

	/**
	 * Edit an existing character
	 */
	function editCharacter(character: PlayerCharacter): void {
		if (!profile.doesCharacterBelongToPlayer(character)) {
			throw new Error('Only the owner of a character may edit that character.');
		}
		if (playerCharactersMap.has(character.name)) {
			playerCharactersMap.set(character.name, character);
		}
	}

	return (
		<_Profile
			createCharacter={createCharacter}
			editCharacter={editCharacter}
			deleteCharacter={deleteCharacter}
			onContactLink={onContactLink}
		/>
	);
}
