/**
 * Typography variant used by Datapad menu labels.
 */
export const menuLabelTypographyVariant = 'h6';

/**
 * Style props used by Datapad menu labels.
 */
export const menuLabelStyle = {
	margin: '3px',
};
