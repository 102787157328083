import type { IFluidContainer } from 'fluid-framework';
import type { Reducer } from 'redux';
import { type DatapadActions, SET_DATAPAD_MENU_STATE, SET_FLUID_CONTAINER } from './Actions';

/**
 * State utilized by the Datapad app component
 */
export interface AppState {
	/**
	 * Fluid container associated with the app.
	 */
	fluidContainer?: IFluidContainer;

	/**
	 * Datapad menu state.
	 */
	menuState: 'open' | 'closed';
}

/**
 * Initial state used by the Datapad app component
 */
export const initialState: AppState = {
	fluidContainer: undefined,
	menuState: 'open',
};

/**
 * {@link https://redux.js.org/basics/reducers | Reducer} for the Datapad app component's state
 */
export const reducer: Reducer<AppState, DatapadActions> = (
	currentState: AppState | undefined,
	action: DatapadActions,
): AppState => {
	if (!currentState) {
		return initialState;
	}
	switch (action.type) {
		case SET_DATAPAD_MENU_STATE:
			return {
				...currentState,
				menuState: action.menuState,
			};
		case SET_FLUID_CONTAINER:
			return {
				...currentState,
				fluidContainer: action.container,
			};
		default:
			return currentState;
	}
};
