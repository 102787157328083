import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

/**
 * {@link BooleanForm} input props.
 */
export interface BooleanFormProps {
	/**
	 * Current value of the form.
	 */
	value: boolean | undefined;

	/**
	 * {@inheritDoc DataEntry.label}
	 */
	label: string;

	/**
	 * {@inheritDoc DataEntry.elementId}
	 */
	elementId: string;

	/**
	 * {@inheritDoc DataEntry.locked}
	 */
	locked?: boolean;

	/**
	 * When the form is clicked, change the value.
	 */
	onChange(newValue: boolean | undefined): void;
}

/**
 * Simple Boolean toggle control.
 */
export function BooleanForm(props: BooleanFormProps): React.ReactElement {
	const { value, locked, label, elementId, onChange } = props;
	return (
		<FormControlLabel
			control={<Checkbox color="primary" checked={value} disabled={locked} />}
			label={label}
			id={elementId}
			onChange={(_: React.ChangeEvent<{}>, checked: boolean) => onChange(checked)}
		/>
	);
}
