import type {
	CanCreateCharacter,
	CanDeleteCharacter,
	CanEditCharacter,
} from '@datapad/character-components';
import type { HasProfile } from '@datapad/common-props';
import { Contacts, type HasContacts } from '@datapad/contacts';
import {
	type PlayerCharacter,
	urlFormattedCharacterNameFromUrlSearch,
	urlSearchFromCharacterName,
} from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DatapadAppStateContext, ProfileContext } from '../utilities';
import { AppId } from './Datapad/AppId';

/**
 * Input props for {@link RoutedContacts}
 */
export interface RoutedContactsProps
	extends HasContacts,
		HasProfile,
		CanEditCharacter,
		CanDeleteCharacter,
		CanCreateCharacter<PlayerCharacter> {}

/**
 * Contacts component with URL routing
 */
export function RoutedContacts(props: RoutedContactsProps): React.ReactElement {
	const location = useLocation();
	const history = useHistory();

	// TODO: move the context handling to app wrapper in datapad.
	// Create context for handling character selections for child components to use
	// - it should compare the character to the session character and link to profile app if matched
	const { changeSignedInCharacter } = assertNotUndefined(React.useContext(ProfileContext));

	const { changeAppSelection, showMenu } = assertNotUndefined(
		React.useContext(DatapadAppStateContext),
	);

	const contactSelection = urlFormattedCharacterNameFromUrlSearch(
		new URLSearchParams(location.search),
	);

	return (
		<Contacts
			{...props}
			selectedContactUrlString={contactSelection}
			changeContactSelection={(contactName) => {
				const search = urlSearchFromCharacterName(contactName);
				history.push({ search });
			}}
			profileLink={(character) => {
				changeSignedInCharacter(character.name);
				changeAppSelection(AppId.Profile);
				showMenu();
			}}
		/>
	);
}

export default RoutedContacts;
