/**
 * Options for image rendering.
 */
export interface ImageOptions {
	// TODO: use Size instead of separate numbers.
	/**
	 * Max image width
	 */
	maxWidthInPixels?: number;
	/**
	 * Max image height
	 */
	maxHeightInPixels?: number;

	/**
	 * Shape of the image container
	 */
	containerShape: ImageContainerShape;

	/**
	 * Image background color.
	 * Default: transparent.
	 */
	backgroundColor?: string;
}

/**
 * Shape options for image cropping.
 */
export enum ImageContainerShape {
	Circle,
	Rectangle,
	RoundedRectangle,
}
