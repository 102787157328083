import { Button } from '@mui/material';
import React from 'react';

/**
 * {@link MenuFooter} input props.
 */
export interface MenuFooterProps {
	/**
	 * Log the session user out.
	 */
	logout(): void;
}

/**
 * Datapad menu footer.
 * Contains the logout button.
 */
export function MenuFooter(props: MenuFooterProps): React.ReactElement {
	const { logout } = props;
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'start',
				padding: '10px',
			}}
		>
			<Button variant="contained" onClick={logout}>
				Log Out
			</Button>
		</div>
	);
}
