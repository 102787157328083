/**
 * Filter state for Contacts
 */
export interface ContactsFilterState {
	/**
	 * Sort order for the contacts
	 */
	sorting: SortBy;

	/**
	 * Filter for contact names.
	 * This filter is based on sub-string matching, as it is backed by a text entry field.
	 * Any contact name including this string will be matched.
	 */
	nameFilter: string;

	/**
	 * Filter to faction.
	 * This filter is based on an exact match, as it is backed by a drop-down menu.
	 * Any contact including a faction which exactly matches this will be matched.
	 * empty string indicates that no filtering should be performed on factions.
	 */
	factionFilter: string;
}

/**
 * Options for the various kinds of Contacts filters
 */
export interface ContactsFilterOptions {
	factionOptions: string[];
}

/**
 * Filter actions for Contacts
 */
export interface ContactsFilterActions {
	updateSorting: (newValue: SortBy) => void;
	updateNameFilter: (newValue: string) => void;
	updateFactionSelection: (newFactionSelection: string) => void;
	clearAllFilters: () => void;
}

/**
 * Sorting attribute
 */
export enum SortBy {
	NameAscending = 'Name (A-Z)',
	NameDescending = 'Name (Z-A)',
}
