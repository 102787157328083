import type {
	CanCreateCampaign,
	CanDeleteCampaign,
	CanEditCampaign,
	HasCalendarLink,
	HasCampaigns,
	HasCharacterLink,
} from '@datapad/common-props';
import type { Campaign } from '@datapad/interfaces';
import { ErrorScreen } from '@datapad/utility-components';
import React from 'react';
import { MissingCampaign } from '../Constants.js';
import { CampaignView } from './CampaignView.js';
import { Toolbar } from './Toolbar.js';

/**
 * {@link Campaigns} input props.
 */
export interface CampaignsProps
	extends HasCampaigns,
		CanCreateCampaign,
		CanEditCampaign,
		CanDeleteCampaign,
		HasCharacterLink,
		HasCalendarLink {
	/**
	 * The selected Campaign to view
	 */
	selectedCampaign: Campaign | MissingCampaign | undefined;

	/**
	 * Function to invoke to change campaign selection.
	 * Undefined =\> refresh selection to default
	 */
	changeCampaignSelection: (newCampaignSelection: Campaign | undefined) => void;
}

/**
 * View that displays information about Campaigns and affords editing / creating / deleting them.
 */
export function Campaigns(props: CampaignsProps): React.ReactElement {
	const {
		campaigns,
		selectedCampaign,
		changeCampaignSelection,
		createCampaign,
		deleteCampaign,
		editCampaign,
		handleCharacterLink,
		handleCalendarLink,
	} = props;

	let innerView: React.ReactElement;
	if (selectedCampaign === MissingCampaign) {
		innerView = <MissingCampaignErrorScreen />;
	} else if (campaigns.length === 0) {
		innerView = <NoCampaignsErrorScreen />;
	} else {
		innerView = (
			<CampaignView
				campaign={selectedCampaign ?? campaigns[0]}
				canEdit
				editCampaign={editCampaign}
				deleteCampaign={deleteCampaign}
				handleCharacterLink={handleCharacterLink}
				handleCalendarLink={handleCalendarLink}
			/>
		);
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
			}}
		>
			<Toolbar
				campaigns={campaigns}
				selectedCampaign={
					selectedCampaign === MissingCampaign ? undefined : selectedCampaign
				}
				onChangeCampaignSelection={changeCampaignSelection}
				createCampaign={createCampaign}
			/>
			<div>{innerView}</div>
		</div>
	);
}

/**
 * Error page to display if no campaigns were loaded
 */
export function NoCampaignsErrorScreen(): React.ReactElement {
	return (
		<ErrorScreen
			textLines={[
				'No campaigns found.',
				'This is very unlikely and probably indicates an error.',
				'Since only the GM (you) and see this... fix it!',
			]}
		/>
	);
}

/**
 * Error page to display if the selected campaign does not exist.
 */
export function MissingCampaignErrorScreen(): React.ReactElement {
	return (
		<ErrorScreen
			textLines={[
				`The selected campaign does not exist.`,
				'This could indicate an error, or could indicate that the selected campaign has been deleted.',
			]}
		/>
	);
}
