/**
 * Asserts that the provided value is not undefined. Throws if it is. Returns it if not.
 */
export function assertNotUndefined<T>(value: T | undefined): T {
	if (value === undefined) {
		throw new Error('Expected value to not be undefined, but it was.');
	}
	return value;
}

/**
 * Asserts that the provided value is not null. Throws if it is. Returns it if not.
 */
export function assertNotNull<T>(value: T | null): T {
	if (value === null) {
		throw new Error('Expected value to not be null, but it was.');
	}
	return value;
}

/**
 * Asserts that the provided value is defined (not undefined and not null).
 * Throws if it is. Returns it if not.
 */
export function assertDefined<T>(value: T | undefined | null): T {
	if (value === undefined) {
		throw new Error('Expected value to not be undefined, but it was.');
	}
	if (value === null) {
		throw new Error('Expected value to not be null, but it was.');
	}
	return value;
}
