import { getBirthdays, type CalendarEvent, type Character } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import React from 'react';
import {
	ProfileContext,
	type SharedCalendarEventsMap,
	type SharedNonPlayerCharactersMap,
	type SharedPlayerCharactersMap,
	useKnownCharacters,
	useKnownEvents,
} from '../../../utilities';
import CalendarApp from '../../Calendar';

/**
 * {@link Calendar} input props.
 */
export interface CalendarProps {
	calendarEventsMap: SharedCalendarEventsMap;
	playerCharactersMap: SharedPlayerCharactersMap;
	nonPlayerCharactersMap: SharedNonPlayerCharactersMap;
}

/**
 * Contacts app wrapper that handles translation of Fluid-backed state
 */
export function Calendar(props: CalendarProps): React.ReactElement {
	const { calendarEventsMap, playerCharactersMap, nonPlayerCharactersMap } = props;

	const { profile, changeCampaignDate } = assertNotUndefined(React.useContext(ProfileContext));

	const events = useKnownEvents(profile, calendarEventsMap);

	const knownCharacters = useKnownCharacters(
		profile,
		playerCharactersMap,
		nonPlayerCharactersMap,
	);
	const birthdays = getBirthdays(knownCharacters);

	/**
	 * Inserts a new event into the shared map
	 */
	function insertNewEvent(event: CalendarEvent): void {
		if (calendarEventsMap.has(event.title)) {
			console.warn(`Inserting new event "${event.title}" will override existing event.`);
		}
		calendarEventsMap.set(event.title, event);
	}

	/**
	 * Edits an existing event in the shared map
	 */
	function editEvent(event: CalendarEvent): void {
		if (!calendarEventsMap.has(event.title)) {
			console.warn(`Updating non-existent "${event.title}" will insert event as new one.`);
		}
		calendarEventsMap.set(event.title, event);
	}

	/**
	 * Deletes an existing event from the shared map
	 */
	function deleteEvent(eventTitle: string): void {
		calendarEventsMap.delete(eventTitle);
	}

	/**
	 * Gets the character with the specified name from either the PC or NPC map.
	 * Throws if not found.
	 */
	const getCharacter = React.useCallback(
		(characterName: string): Character => {
			if (playerCharactersMap.has(characterName)) {
				return assertNotUndefined(playerCharactersMap.get(characterName));
			}
			if (nonPlayerCharactersMap.has(characterName)) {
				return assertNotUndefined(nonPlayerCharactersMap.get(characterName));
			}
			throw new Error(`No character found with name "${characterName}".`);
		},
		[playerCharactersMap, nonPlayerCharactersMap],
	);

	return (
		<CalendarApp
			events={events}
			updateCurrentDate={changeCampaignDate}
			createEvent={insertNewEvent}
			editEvent={editEvent}
			deleteEvent={deleteEvent}
			birthdays={birthdays}
			getCharacter={getCharacter}
		/>
	);
}
