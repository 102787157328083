import type { HasProfile } from '@datapad/common-props';
import {
	AccountCircle,
	Event,
	LocalLibrary,
	Map,
	People,
	SupervisedUserCircle,
	Timeline,
} from '@mui/icons-material';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { AppId } from '../../AppId';
import { menuLabelStyle, menuLabelTypographyVariant } from './Constants';

/**
 * {@link AppSelectionList} input props.
 */
export interface AppSelectionListProps extends HasProfile {
	currentAppSelection: AppId;
	changeAppSelection(newAppSelection: AppId): void;
}

/**
 * Datapad menu app selection list.
 */
export function AppSelectionList(props: AppSelectionListProps): React.ReactElement {
	const { profile, currentAppSelection, changeAppSelection } = props;

	const isPlayerGuest = profile.isPlayerGuest();
	const dmMode = profile.dmMode;

	/**
	 * Generates a React key for the list entry.
	 */
	function appEntryKey(appId: AppId): string {
		return `datapad-menu-app-selection-${appId}`;
	}

	return (
		<div style={{ width: '100%' }}>
			<Typography variant={menuLabelTypographyVariant} style={menuLabelStyle}>
				Apps
			</Typography>
			<List
				style={{
					width: '100%',
				}}
			>
				<AppMenuItem
					text="My Profile"
					icon={<AccountCircle />}
					appId={AppId.Profile}
					isCurrentSelection={currentAppSelection === AppId.Profile}
					disabled={isPlayerGuest}
					onSelected={() => changeAppSelection(AppId.Profile)}
					key={appEntryKey(AppId.Profile)}
				/>
				<AppMenuItem
					text="Galaxy Map"
					icon={<Map />}
					appId={AppId.GalaxyMap}
					isCurrentSelection={currentAppSelection === AppId.GalaxyMap}
					onSelected={() => changeAppSelection(AppId.GalaxyMap)}
					key={appEntryKey(AppId.GalaxyMap)}
				/>
				<AppMenuItem
					text="Contacts"
					icon={<People />}
					appId={AppId.Contacts}
					isCurrentSelection={currentAppSelection === AppId.Contacts}
					onSelected={() => changeAppSelection(AppId.Contacts)}
					key={appEntryKey(AppId.Contacts)}
				/>
				<AppMenuItem
					text="Calendar"
					icon={<Event />}
					appId={AppId.Calendar}
					isCurrentSelection={currentAppSelection === AppId.Calendar}
					onSelected={() => changeAppSelection(AppId.Calendar)}
					key={appEntryKey(AppId.Calendar)}
				/>
				<AppMenuItem
					text="Timeline"
					icon={<Timeline />}
					appId={AppId.Timeline}
					isCurrentSelection={currentAppSelection === AppId.Timeline}
					onSelected={() => changeAppSelection(AppId.Timeline)}
					key={appEntryKey(AppId.Timeline)}
				/>
			</List>
			{dmMode ? (
				// Only show GM options to the GM
				<>
					<Divider orientation="horizontal" />
					<List>
						<AppMenuItem
							text="Players"
							icon={<SupervisedUserCircle />}
							appId={AppId.Players}
							isCurrentSelection={currentAppSelection === AppId.Players}
							disabled={!dmMode}
							onSelected={() => changeAppSelection(AppId.Players)}
							key={appEntryKey(AppId.Players)}
						/>
						<AppMenuItem
							text="Campaigns"
							icon={<LocalLibrary />}
							appId={AppId.Campaigns}
							isCurrentSelection={currentAppSelection === AppId.Campaigns}
							disabled={!dmMode}
							onSelected={() => changeAppSelection(AppId.Campaigns)}
							key={appEntryKey(AppId.Campaigns)}
						/>
					</List>
				</>
			) : (
				<></>
			)}
		</div>
	);
}

/**
 * {@link AppMenuItem} input props.
 */
export interface AppMenuItemProps {
	/**
	 * Text to display.
	 */
	text: string;

	/**
	 * Icon to display.
	 */
	icon: React.ReactElement;

	/**
	 * The App ID with which this option corresponds.
	 */
	appId: AppId;

	/**
	 * Whether or not this item is the current app selection.
	 */
	isCurrentSelection: boolean;

	/**
	 * Whether or not the menu option is visible / clickible by the user.
	 *
	 * @defaultValue `false`
	 */
	disabled?: boolean;

	/**
	 * Callback invoked when the item is selected.
	 */
	onSelected: () => void;
}

/**
 * A single menu option item for the app selection list.
 */
export function AppMenuItem(props: AppMenuItemProps): React.ReactElement {
	const { text, icon, appId, isCurrentSelection, disabled, onSelected } = props;
	return (
		<ListItem
			button
			selected={isCurrentSelection}
			onClick={onSelected}
			key={appId}
			disabled={disabled}
		>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={text} />
		</ListItem>
	);
}
