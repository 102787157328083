import { CharacterListEntry } from '@datapad/character-components';
import type {
	CanDeletePlayer,
	CanEditPlayer,
	HasCharacterLink,
	HasPlayer,
} from '@datapad/common-props';
import { ConfirmationDialogue } from '@datapad/form-components';
import type { Player } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import { ModalCard } from '@datapad/utility-components';
import { Delete, Edit } from '@mui/icons-material';
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { PlayerCreationForm } from './PlayerCreationForm.js';

// TODOs:
// - editable
// - character drop-down when editing active character

/**
 * {@link PlayerView} input props
 */
export interface PlayerViewProps
	extends CanDeletePlayer,
		CanEditPlayer,
		HasCharacterLink,
		HasPlayer {
	/**
	 * Whether or not editing (including deletion) is allowed.
	 */
	canEdit: boolean;
}

/**
 * Modal dialogue status
 */
enum ModalStatus {
	None,
	Editing,
	Deleting,
}

/**
 * Player view component. Displays {@link Player} data and allows editing.
 */
export function PlayerView(props: PlayerViewProps): React.ReactElement {
	const [modalStatus, setModalStatus] = useState(ModalStatus.None);

	const { player } = props;

	const maybeModal = (
		<EditingModal
			modalStatus={modalStatus}
			player={player}
			updatePlayer={(updatedPlayer) => {
				props.editPlayer(updatedPlayer);
				setModalStatus(ModalStatus.None);
			}}
			removePlayer={() => {
				props.deletePlayer(player);
				setModalStatus(ModalStatus.None);
			}}
			cancel={() => setModalStatus(ModalStatus.None)}
		/>
	);

	return (
		<>
			{maybeModal}
			<div
				style={{
					maxWidth: '600px',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Table
					style={{
						width: '100%',
					}}
				>
					<TableBody>
						<TableRow>
							<TableCell>
								<Typography variant="body1">
									<b>User Name</b>
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1">{player.userName}</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="body1">
									<b>Nickname</b>
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1">
									{player.nickname ?? <i>User does not have a nickname set</i>}
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="body1">
									<b>Player Kind</b>
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1">
									<i>{player.playerKind}</i>
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="body1">
									<b>Active Character</b>
								</Typography>
							</TableCell>
							<TableCell>
								{player.defaultCharacter ? (
									<CharacterListEntry
										characterName={player.defaultCharacter}
										onClick={() =>
											props.handleCharacterLink(
												assertNotUndefined(player.defaultCharacter),
											)
										}
									/>
								) : (
									<Typography variant="body1">
										<i>No active character set</i>
									</Typography>
								)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row-reverse',
					}}
				>
					<div
						id="profile-toolbar-buttons"
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Tooltip title="Edit player">
							<IconButton
								disabled={!props.canEdit}
								onClick={() => {
									setModalStatus(ModalStatus.Editing);
								}}
							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete player">
							<IconButton
								disabled={!props.canEdit}
								onClick={() => {
									setModalStatus(ModalStatus.Deleting);
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</div>
		</>
	);
}

interface EditingModalProps {
	modalStatus: ModalStatus;
	player: Player;
	cancel: () => void;
	updatePlayer: (updatedPlayer: Player) => void;
	removePlayer: () => void;
}

/**
 * Editing modal dialogue.
 * Will be empty if the specified {@link ModalStatus} is {@link ModalStatus.None}.
 */
function EditingModal(props: EditingModalProps): React.ReactElement {
	let renderedDialogue: React.ReactElement | undefined;
	switch (props.modalStatus) {
		case ModalStatus.None:
			renderedDialogue = undefined;
			break;
		case ModalStatus.Editing:
			renderedDialogue = (
				<PlayerCreationForm
					existingPlayer={props.player}
					onSubmit={props.updatePlayer}
					onCancel={props.cancel}
				/>
			);
			break;
		case ModalStatus.Deleting:
			renderedDialogue = (
				<ModalCard open>
					<ConfirmationDialogue onConfirm={props.removePlayer} onCancel={props.cancel}>
						<Typography variant="body1">
							{`Are you sure you wish to delete player "${props.player.userName}"?`}
						</Typography>
						<br />
						<Typography variant="body2">
							{`Note that this action cannot be undone.`}
						</Typography>
					</ConfirmationDialogue>
				</ModalCard>
			);
			break;
		default:
			throw new Error(`Unrecognized ModalStatus value: "${props.modalStatus}".`);
	}

	if (renderedDialogue === undefined) {
		return <></>;
	} else {
		return (
			<ModalCard
				open
				onClose={props.cancel}
				style={{
					maxHeight: '95%',
				}}
			>
				{renderedDialogue}
			</ModalCard>
		);
	}
}
