import type {
	CanGetCharacter,
	CanModifyCurrentDate,
	HasBirthdayEvents,
	HasCalendarEvents,
	HasCharacterLink,
	HasProfile,
} from '@datapad/common-props';
import {
	type BirthdayEvent,
	type CalendarDate,
	type CalendarEvent,
	type MonthOrFestivalWeek,
	areDatesEqual,
	getNextMonthOrFestivalWeek,
	getPreviousMonthOrFestivalWeek,
	isDayInMonthOrFestivalWeek,
	monthOrFestivalWeekFromDate,
	weekDays,
} from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
	AppBar,
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import type { CanCreateEvent, CanDeleteEvent, CanEditEvent } from '../CommonProps.js';
import { CalendarCell } from './CalendarCell.js';
import { DayCardModal } from './DayCardModal.js';

// TODOs:
// - Dropdown for month and year
// - Correctly handle cells with lots of events

/**
 * Input props for {@link CalendarGrid}
 */
export interface Props
	extends HasProfile,
		HasCharacterLink,
		HasCalendarEvents,
		HasBirthdayEvents,
		CanGetCharacter,
		CanCreateEvent,
		CanEditEvent,
		CanDeleteEvent,
		CanModifyCurrentDate {
	/**
	 * Month or festival week to display.
	 */
	monthOrFestivalWeek: MonthOrFestivalWeek;

	/**
	 * The year (BBY)
	 */
	year: number;

	/**
	 * Function to invoke when the month changes
	 */
	onChangeMonth: (monthOrFestivalWeek: MonthOrFestivalWeek, year: number) => void;
}

/**
 * Local state for {@link CalendarGrid}
 */
interface State {
	/**
	 * Selected cell date, if one has been selected (clicked).
	 * Displays a modal card with information about the day and its events.
	 */
	selectedDate?: CalendarDate;
}

/**
 * Calendar grid component.
 * Renders a series of grid cells for the days of the current month
 */
export class CalendarGrid extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			selectedDate: undefined,
		};
	}

	private getCampaignDate(): CalendarDate {
		return this.props.profile.getCampaignDateOrDefault();
	}

	private getEventsOnDate(date: CalendarDate): CalendarEvent[] {
		const eventsOnDate: CalendarEvent[] = [];
		for (const event of this.props.events) {
			if (event.year === date.year && event.day === date.dayOfTheYear) {
				eventsOnDate.push(event);
			}
		}
		return eventsOnDate;
	}

	private getBirthdaysOnDate(date: CalendarDate): BirthdayEvent[] {
		const birthdaysOnDate: BirthdayEvent[] = [];
		for (const birthday of this.props.birthdays) {
			if (birthday.date.dayOfTheYear === date.dayOfTheYear) {
				birthdaysOnDate.push(birthday);
			}
		}
		return birthdaysOnDate;
	}

	private decrementMonthOrFestivalWeek(): void {
		const { monthOrFestivalWeek, year } = getPreviousMonthOrFestivalWeek(
			this.props.monthOrFestivalWeek,
			this.props.year,
		);
		this.props.onChangeMonth(monthOrFestivalWeek, year);
	}

	private incrementMonthOrFestivalWeek(): void {
		const { monthOrFestivalWeek, year } = getNextMonthOrFestivalWeek(
			this.props.monthOrFestivalWeek,
			this.props.year,
		);
		this.props.onChangeMonth(monthOrFestivalWeek, year);
	}

	private goToToday(): void {
		const campaignDate = assertNotUndefined(this.getCampaignDate());
		const monthOrFestivalWeek = monthOrFestivalWeekFromDate(campaignDate);
		this.props.onChangeMonth(monthOrFestivalWeek, campaignDate.year);
	}

	/**
	 * Select or deselect (by passing undefined) a calendar date
	 */
	private selectDate(date: CalendarDate | undefined): void {
		this.setState({
			...this.state,
			selectedDate: date,
		});
	}

	public override render(): React.ReactNode {
		const maybeModalDialogue = this.renderDayCardModalIfSelection();
		return (
			<div
				style={{
					width: '100%',
					height: '100%',
					overflowY: 'auto',
				}}
			>
				{maybeModalDialogue}
				<TableContainer>
					{this.renderCalendarHeader()}
					{this.renderCalendarBody()}
				</TableContainer>
			</div>
		);
	}

	private renderCalendarHeader(): React.ReactElement {
		const campaignDate = this.getCampaignDate();

		const monthOrFestivalWeek = this.props.monthOrFestivalWeek;
		const year = this.props.year;
		const monthContainsTodaysDate =
			campaignDate !== undefined &&
			year === campaignDate.year &&
			isDayInMonthOrFestivalWeek(campaignDate.dayOfTheYear, monthOrFestivalWeek);

		return (
			<AppBar
				id="profile-toolbar"
				position="static"
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: '5px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						paddingRight: '10px',
					}}
				>
					<div
						style={{
							paddingRight: '5px',
						}}
					>
						<Tooltip title="Go to today">
							<Button
								disabled={campaignDate === undefined || monthContainsTodaysDate}
								onClick={() => this.goToToday()}
							>
								Today
							</Button>
						</Tooltip>
					</div>
					<div
						style={{
							paddingLeft: '5px',
							paddingRight: '5px',
						}}
					>
						<Tooltip title="Previous month or festival week">
							<IconButton
								size="small"
								onClick={() => this.decrementMonthOrFestivalWeek()}
							>
								<ArrowLeft />
							</IconButton>
						</Tooltip>
						<Tooltip title="Next month or festival week">
							<IconButton
								size="small"
								onClick={() => this.incrementMonthOrFestivalWeek()}
							>
								<ArrowRight />
							</IconButton>
						</Tooltip>
					</div>
					<div
						style={{
							paddingLeft: '5px',
						}}
					>
						<Typography variant="subtitle1" noWrap>
							{`${monthOrFestivalWeek.name}, ${year} BBY`}
						</Typography>
					</div>
				</div>
			</AppBar>
		);
	}

	private renderCalendarBody(): React.ReactElement {
		return (
			<Table stickyHeader>
				<TableHead>
					<TableRow hover={false} selected={false}>
						{weekDays.map((weekday) => (
							<TableCell
								key={weekday}
								variant="head"
								style={{
									width: '20%',
									maxWidth: '0px', // Required to make contents trim correctly? Doesn't even seem to matter what the width actually is...
									minWidth: '0px',
									padding: '1px',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-around',
										minWidth: '0px',
									}}
								>
									<Typography variant="subtitle1" noWrap>
										{weekday}
									</Typography>
								</div>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				{this.renderTableBodyForMonthOrFestivalWeek()}
			</Table>
		);
	}

	private renderTableBodyForMonthOrFestivalWeek(): React.ReactElement {
		const campaignDate = this.getCampaignDate();

		const year = this.props.year;
		const monthOrFestivalWeek = this.props.monthOrFestivalWeek;

		const startDay = monthOrFestivalWeek.startDay;
		const dayCount = monthOrFestivalWeek.dayCount;
		const rowCount = Math.ceil(dayCount / 5);

		const cellArray: React.ReactElement[][] = [];

		for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
			cellArray.push([]);
			for (let columnIndex = 0; columnIndex < 5; columnIndex++) {
				const dayOfMonth = rowIndex * 5 + columnIndex;
				if (dayOfMonth < dayCount) {
					const date = { year, dayOfTheYear: startDay + dayOfMonth };
					const eventsOnDate = this.getEventsOnDate(date);
					const birthdaysOnDate = this.getBirthdaysOnDate(date);
					const isTodaysDate =
						campaignDate !== undefined && areDatesEqual(campaignDate, date);

					const cellRender = (
						<CalendarCell
							date={date}
							isToday={isTodaysDate}
							todaysEvents={eventsOnDate}
							todaysBirthdays={birthdaysOnDate}
							handleCharacterLink={this.props.handleCharacterLink}
							onSelect={() => this.selectDate(date)}
						/>
					);
					cellArray[rowIndex][columnIndex] = cellRender;
				}
			}
		}

		const rowArray: React.ReactElement[] = [];
		for (let i = 0; i < cellArray.length; i++) {
			const rowRenders = cellArray[i];
			const rowRender = (
				<TableRow key={`week-${i}`} hover={false}>
					{rowRenders}
				</TableRow>
			);
			rowArray.push(rowRender);
		}

		return <TableBody>{rowArray}</TableBody>;
	}

	private renderDayCardModalIfSelection(): React.ReactElement {
		const { profile } = this.props;

		const maybeSelectedDay = this.state.selectedDate;
		if (!maybeSelectedDay) {
			return <></>;
		}

		const onClose: () => void = () => this.selectDate(undefined);
		const events = this.getEventsOnDate(maybeSelectedDay);
		const birthdays = this.getBirthdaysOnDate(maybeSelectedDay);

		return (
			<DayCardModal
				profile={profile}
				date={maybeSelectedDay}
				closeModal={onClose}
				events={events}
				birthdays={birthdays}
				getCharacter={this.props.getCharacter}
				handleCharacterLink={this.props.handleCharacterLink}
				createEvent={this.props.createEvent}
				editEvent={this.props.editEvent}
				deleteEvent={this.props.deleteEvent}
				updateCurrentDate={() => this.props.updateCurrentDate(maybeSelectedDay)}
			/>
		);
	}
}
