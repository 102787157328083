import {
	BirthdayEntry,
	type DataEntry,
	DropDownEntry,
	EditFormDialogue,
	ListEntry,
	StringEntry,
} from '@datapad/form-components';
import {
	type Birthday,
	type Character,
	type CharacterStatus,
	type Profile,
	characterStatusOptions,
	characterStatusToString,
	getDifferenceInYears,
	isDroid,
	pronounOptions,
} from '@datapad/interfaces';
import { assertNotUndefined, assertUndefinedOrNonEmpty, getOrDefault } from '@datapad/utilities';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { CharacterInfoCard } from './CharacterDataCard.js';
import { renderHomeworldLink, renderSpeciesLink } from './CharacterUtilities.js';
import type { EditableCharacterProfileBaseProps } from './CommonProps.js';

/**
 * {@link CharacterBasics} input props
 */
export interface Props extends EditableCharacterProfileBaseProps {
	/**
	 * Current in-world date
	 */
	profile: Profile;
}

/**
 * A small card containing basic character info. Specifically:
 * - {@link Character.species}
 * - {@link Character.pronouns}
 * - {@link Character.homeworld}
 * - {@link Character.status}
 * - {@link Character.nicknames}
 *
 * @remarks Note that the `summary` and `shortName` properties are editable through this component,
 * but is not displayed here
 */
export class CharacterBasics extends CharacterInfoCard<Props> {
	/**
	 * {@inheritDoc CharacterInfoCard.headerText}
	 */
	public readonly headerText = 'The Basics';

	public constructor(props: Props) {
		super(props);
	}

	async submitBasicsUpdates(data: Map<string, unknown>): Promise<void> {
		const shortName = assertUndefinedOrNonEmpty(data.get('shortName') as string);
		const species = assertUndefinedOrNonEmpty(data.get('species') as string);
		const pronouns = assertUndefinedOrNonEmpty(data.get('pronouns') as string);
		const homeworld = assertUndefinedOrNonEmpty(data.get('homeworld') as string);
		const birthday = assertNotUndefined(data.get('birthday') as Birthday);
		const status = assertUndefinedOrNonEmpty(data.get('status') as string) as CharacterStatus;
		const summary = assertUndefinedOrNonEmpty(data.get('summary') as string);
		const nicknames = assertUndefinedOrNonEmpty(data.get('nicknames') as string[]);
		const aliases = assertUndefinedOrNonEmpty(data.get('aliases') as string[]);

		const characterUpdate: Character = {
			...this.props.character,
			shortName,
			nicknames,
			aliases,
			species,
			pronouns,
			homeworld,
			status,
			summary,
			birthday,
		};
		await this.submitEdits(characterUpdate);
	}

	public renderBodyContents(): React.ReactElement {
		return (
			<Table>
				<TableBody>
					{this.renderSpecies(this.props.character)}
					{this.renderPronouns(this.props.character)}
					{this.renderHomeworld(this.props.character)}
					{this.renderAge(this.props.character)}
					{this.renderStatus(this.props.character)}
					{this.renderNicknames(this.props.character)}
					{this.renderAliases(this.props.character)}
				</TableBody>
			</Table>
		);
	}

	private renderSpecies(character: Character): React.ReactElement {
		return (
			<TableRow>
				<TableCell>
					<b>Species: </b>
				</TableCell>
				<TableCell>{renderSpeciesLink(character)}</TableCell>
			</TableRow>
		);
	}

	private renderPronouns(character: Character): React.ReactElement {
		// If the contact is a droid, then it does not display gender information
		if (isDroid(character)) {
			return <></>;
		}
		return (
			<TableRow>
				<TableCell>
					<b>Pronouns: </b>
				</TableCell>
				<TableCell>{stringOrUnknown(character.pronouns)}</TableCell>
			</TableRow>
		);
	}

	private renderHomeworld(character: Character): React.ReactElement {
		return (
			<TableRow>
				<TableCell>
					<b>Homeworld: </b>
				</TableCell>
				<TableCell>{renderHomeworldLink(character)}</TableCell>
			</TableRow>
		);
	}

	private renderAge(character: Character): React.ReactElement {
		const campaignDate = this.props.profile.campaignDate;

		// Can't display character's age if there isn't a current date to compare to.
		if (campaignDate === undefined) {
			return <></>;
		}

		let age: number | undefined = undefined;
		if (character.birthday !== undefined) {
			const birthdate = {
				year: character.birthday.year,
				dayOfTheYear: character.birthday.day,
			};
			age = getDifferenceInYears(campaignDate, birthdate) * -1; // Years BBY decrease as time moves forward
		}

		const ageString = age === undefined ? 'Unknown' : `${age} years`;

		return (
			<TableRow>
				<TableCell>
					<b>Age: </b>
				</TableCell>
				<TableCell>{ageString}</TableCell>
			</TableRow>
		);
	}

	private renderStatus(character: Character): React.ReactElement {
		return (
			<TableRow>
				<TableCell>
					<b>Status: </b>
				</TableCell>
				<TableCell>{characterStatusToString(character.status)}</TableCell>
			</TableRow>
		);
	}

	private renderNicknames(character: Character): React.ReactElement {
		return renderQuotedStringListTableRow('Nicknames', character.nicknames);
	}

	private renderAliases(character: Character): React.ReactElement {
		return renderQuotedStringListTableRow('Known Aliases', character.aliases);
	}

	public renderEditForm(): React.ReactElement {
		const schemas = this.createEditSchemas();

		return (
			<EditFormDialogue
				title={'Edit basic details'}
				schemas={schemas}
				onSubmit={(data) => this.submitBasicsUpdates(data)}
				onCancel={() => this.cancelEdit()}
			/>
		);
	}

	private createEditSchemas(): Map<string, DataEntry> {
		const character = this.props.character;
		return new Map<string, DataEntry>([
			[
				'shortName',
				new StringEntry({
					initialValue: character.shortName,
					label: 'Short Name',
					elementId: 'short-name-edit-form',
					locked: false,
					multiLine: false,
					required: false,
				}),
			],
			[
				'species',
				new StringEntry({
					initialValue: character.species,
					label: 'Species',
					elementId: 'species-edit-form',
					locked: false,
					multiLine: false,
					required: false,
				}),
			],
			[
				'pronouns',
				new DropDownEntry({
					initialValue: character.pronouns,
					label: 'Pronouns',
					elementId: 'pronouns-edit-form',
					locked: false,
					options: pronounOptions,
					required: false,
				}),
			],
			[
				'homeworld',
				new StringEntry({
					initialValue: character.homeworld,
					label: 'Homeworld',
					elementId: 'homeworld-edit-form',
					locked: false,
					multiLine: false,
					required: false,
				}),
			],
			[
				'birthday',
				new BirthdayEntry({
					initialValue: character.birthday,
					label: 'Birthday',
					elementId: 'birthday-edit-form',
					locked: false,
					required: true,
				}),
			],
			[
				'status',
				new DropDownEntry({
					initialValue: character.status,
					label: 'Status',
					elementId: 'status-edit-form',
					locked: false,
					options: characterStatusOptions,
					required: false,
				}),
			],
			[
				'summary',
				new StringEntry({
					initialValue: character.summary,
					label: 'Summary',
					elementId: 'summary-edit-form',
					locked: false,
					multiLine: false,
					required: false,
				}),
			],
			[
				'nicknames',
				new ListEntry({
					initialValue: undefined,
					label: 'Nicknames',
					elementId: 'player-character-creation-nicknames-form',
				}),
			],
			[
				'aliases',
				new ListEntry({
					initialValue: undefined,
					label: 'Aliases',
					elementId: 'player-character-creation-aliases-form',
				}),
			],
		]);
	}
}

/**
 * Return the provided string if defined. Else, returns "Unknown".
 */
function stringOrUnknown(value: string | undefined): string {
	return getOrDefault(value, 'Unknown');
}

/**
 * Returns an empty element if `maybeList` is undefined or empty.
 * Otherwise, enders a table row of 2 cells.
 * The first contains the provided `label`.
 * The second contains a comma-separated list based on the provided `maybeList`, where each entry
 * has been wrapped in quotation marks.
 */
function renderQuotedStringListTableRow(
	label: string,
	maybeList: string[] | undefined,
): React.ReactElement {
	if (maybeList === undefined || maybeList.length === 0) {
		return <></>;
	} else {
		const quotedEntries = maybeList.map((entry) => `"${entry}"`);
		const commaSeparatedString = quotedEntries.join(', ');
		return (
			<TableRow>
				<TableCell>
					<b>{label}: </b>
				</TableCell>
				<TableCell>{commaSeparatedString}</TableCell>
			</TableRow>
		);
	}
}
