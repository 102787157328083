import {
	type CalendarEvent,
	type Campaign,
	type Character,
	compareDates,
	createGuestPlayer,
	dateFromEvent,
	getShortNameOrName,
	type NonPlayerCharacter,
	type Player,
	type PlayerCharacter,
	PlayerKind,
	Profile,
} from '@datapad/interfaces';
import { sortArray } from '@datapad/utilities';
import type { IFluidContainer, IFluidHandle } from 'fluid-framework';
import type { SharedMap } from 'fluid-framework/legacy';

import React from 'react';

// #region fluid map type aliases

export type SharedPlayerCharactersMap = SharedMap & 'PlayerCharacters';
export type SharedNonPlayerCharactersMap = SharedMap & 'NonPlayerCharacters';
export type SharedPlayersMap = SharedMap & 'Players';
export type SharedCalendarEventsMap = SharedMap & 'CalendarEvents';
export type SharedCampaignsMap = SharedMap & 'Campaigns';

// #endregion

// #region fluid root map keys

// Note: Shops app map used to exist under key "shops"

/**
 * Root datapad map key for the `players` table.
 */
export const playersMapKey = 'players';

/**
 * Root datapad map key for the `player-characters` table.
 */
export const playerCharactersMapKey = 'player-characters';

/**
 * Root datapad map key for the `non-player-characters` table.
 */
export const nonPlayerCharactersMapKey = 'non-player-characters';

/**
 * Root datapad map key for the `campaigns` table.
 */
export const campaignsMapKey = 'campaigns';

/**
 * Root datapad map key for the `calendar events` table.
 */
export const calendarEventsMapKey = 'calendar-events';

// #endregion

/**
 * Represents a React Context that includes a Fluid container.
 */
export const FluidContainerContext = React.createContext<IFluidContainer | undefined>(undefined);

/**
 * Pre-fetches sub-maps of the root datapad map
 */
export async function preFetchMaps(datapadMap: SharedMap): Promise<void> {
	const mapKeys: string[] = [
		playersMapKey,
		playerCharactersMapKey,
		nonPlayerCharactersMapKey,
		campaignsMapKey,
		calendarEventsMapKey,
	];

	await Promise.all(
		mapKeys.map((key) => {
			const handle: IFluidHandle<SharedMap> | undefined = datapadMap.get(key);
			return handle === undefined ? undefined : handle.get();
		}),
	);
}

/**
 * Inserts a new player character into the shared map.
 */
export function insertNewPlayerCharacter(
	newCharacter: PlayerCharacter,
	sharedMap: SharedPlayerCharactersMap,
): void {
	if (sharedMap.has(newCharacter.name)) {
		console.warn(
			`Inserting new character "${newCharacter.name}" will override existing player character.`,
		);
	}
	sharedMap.set(newCharacter.name, newCharacter);
}

/**
 * Updates an existing player character in the shared map.
 */
export function updatePlayerCharacter(
	character: PlayerCharacter,
	sharedMap: SharedPlayerCharactersMap,
): void {
	if (!sharedMap.has(character.name)) {
		console.warn(
			`Updating non-existent "${character.name}" will insert player character as new one.`,
		);
	}
	sharedMap.set(character.name, character);
}

/**
 * Deletes the player character from the shared map if it already exists there.
 */
export function deletePlayerCharacter(
	character: PlayerCharacter,
	sharedMap: SharedPlayerCharactersMap,
): void {
	sharedMap.delete(character.name);
}

/**
 * Inserts a new non-player character into the shared map.
 */
export function insertNewNonPlayerCharacter(
	newCharacter: NonPlayerCharacter,
	sharedMap: SharedNonPlayerCharactersMap,
): void {
	if (sharedMap.has(newCharacter.name)) {
		console.warn(`Inserting new character "${newCharacter.name}" will override existing NPC.`);
	}
	sharedMap.set(newCharacter.name, newCharacter);
}

/**
 * Updates an existing non-player character in the shared map.
 */
export function updateNonPlayerCharacter(
	character: NonPlayerCharacter,
	sharedMap: SharedNonPlayerCharactersMap,
): void {
	if (!sharedMap.has(character.name)) {
		console.warn(`Updating non-existent "${character.name}" will insert NPC as new one.`);
	}
	sharedMap.set(character.name, character);
}

/**
 * Deletes the non-player character from the shared map if it already exists there.
 */
export function deleteNonPlayerCharacter(
	character: NonPlayerCharacter,
	sharedMap: SharedNonPlayerCharactersMap,
): void {
	sharedMap.delete(character.name);
}

/**
 * Gets all values from the provided {@link SharedMap} and returns them as a list.
 */
export function getSharedMapValuesAsList<TValue>(sharedMap: SharedMap): TValue[] {
	const result: TValue[] = [];
	for (const [, value] of sharedMap.entries()) {
		result.push(value as TValue);
	}
	return result;
}

/**
 * React hook for using array content state derived from a SharedMap.
 */
function useSharedMapContentsAsArray<T>(map: SharedMap): T[] {
	const [values, setValues] = React.useState<T[]>(getSharedMapValuesAsList(map));

	// Register to receive root map updates, and update local state accordingly.
	React.useEffect(() => {
		/**
		 * Sync changes to map
		 */
		function syncChanges(): void {
			setValues(getSharedMapValuesAsList<T>(map));
		}

		// Update state each time our map changes
		map.on('valueChanged', syncChanges);

		// Turn off listener when component is unmounted
		return () => {
			map.off('valueChanged', syncChanges);
		};
	}, [map, setValues]);

	return values;
}

function sortCharacters<TCharacter extends Character>(characters: TCharacter[]): TCharacter[] {
	return sortArray(characters, (a, b) =>
		getShortNameOrName(a).localeCompare(getShortNameOrName(b)),
	);
}

/**
 * React hook for getting the list of {@link PlayerCharacter}s from the PC shared map.
 */
export function usePlayerCharacters(map: SharedPlayerCharactersMap): PlayerCharacter[] {
	return sortCharacters(useSharedMapContentsAsArray<PlayerCharacter>(map));
}

/**
 * React hook for getting the list of {@link NonPlayerCharacter}s from the NPC shared map.
 */
export function useNonPlayerCharacters(map: SharedNonPlayerCharactersMap): NonPlayerCharacter[] {
	return sortCharacters(useSharedMapContentsAsArray<NonPlayerCharacter>(map));
}

/**
 * React hook for getting the list of {@link Character}s from the two character shared maps.
 */
export function useCharacters(
	pcMap: SharedPlayerCharactersMap,
	npcMap: SharedNonPlayerCharactersMap,
): Character[] {
	const playerCharacters = usePlayerCharacters(pcMap);
	const nonPlayerCharacters = useNonPlayerCharacters(npcMap);
	return sortCharacters([...playerCharacters, ...nonPlayerCharacters]);
}

/**
 * React hook for getting the list of all {@link Character}s owned by the provided player.
 */
export function usePlayersCharacters(
	player: Player,
	pcMap: SharedPlayerCharactersMap,
): PlayerCharacter[] {
	const allPlayerCharacters = usePlayerCharacters(pcMap);
	return getPlayersCharacters(player, allPlayerCharacters);
}

/**
 * React hook for getting the list of all {@link Character}s known by the provided player's
 * characters.
 */
export function useKnownCharacters(
	profile: Profile,
	pcMap: SharedPlayerCharactersMap,
	npcMap: SharedNonPlayerCharactersMap,
): Character[] {
	const allCharacters = useCharacters(pcMap, npcMap);
	return profile.getKnownCharacters(allCharacters);
}

/**
 * React hook for getting the list of {@link PlayerCharacter}s from the PC shared map.
 *
 * @param playersMap - See {@link SharedPlayersMap}.
 */
export function usePlayers(playersMap: SharedPlayersMap): Player[] {
	return sortArray(useSharedMapContentsAsArray<Player>(playersMap), (a, b) =>
		a.userName.localeCompare(b.userName),
	);
}

/**
 * React hook for getting the specified user's {@link Player} data.
 *
 * @param userName - The name of the signed-in session user.
 * @param playersMap - See {@link SharedPlayersMap}.
 *
 * @returns The Player data associated with the user, if any. Otherwise, returns guest Player data.
 */
export function usePlayer(userName: string, playersMap: SharedPlayersMap): Player {
	const players = usePlayers(playersMap);
	return players.find((player) => player.userName === userName) ?? createGuestPlayer(userName);
}

/**
 * React hook for getting the list of {@link PlayerCharacter}s from the PC shared map.
 */
export function useCampaigns(campaignsMap: SharedCampaignsMap): Campaign[] {
	return sortArray(useSharedMapContentsAsArray<Campaign>(campaignsMap), (a, b) =>
		a.name.localeCompare(b.name),
	);
}

/**
 * React hook for getting the list of {@link PlayerCharacter}s from the PC shared map.
 *
 * @remarks This function assumes that an character can appear in at most 1 session.
 * If this invariant changes, this logic will need to be updated.
 */
export function useCampaign(
	sessionCharacter: string | undefined,
	campaignsMap: SharedCampaignsMap,
): Campaign | undefined {
	const campaigns = useCampaigns(campaignsMap);
	return sessionCharacter === undefined
		? undefined
		: campaigns.find((campaign) => campaign.characters.includes(sessionCharacter));
}

/**
 * Returns whether or not the specified character belongs to the current player.
 * If the user is the DM, will always return true.
 */
export function characterBelongsToPlayer(character: PlayerCharacter, player: Player): boolean {
	return player.playerKind === PlayerKind.DungeonMaster
		? true
		: character.player.toLocaleLowerCase() === player.userName.toLocaleLowerCase();
}

/**
 * Gets any characters from the provided list that belong to the provided player.
 */
function getPlayersCharacters(player: Player, characters: PlayerCharacter[]): PlayerCharacter[] {
	return characters.filter((character) => characterBelongsToPlayer(character, player));
}

/**
 * Gets the default character for the specified player if there is one specified.
 * Otherwise, returns the first character in their list (if non-empty).
 * Otherwise, returns `undefined`.
 */
function getPlayersDefaultCharacter(
	player: Player,
	characters: PlayerCharacter[],
): PlayerCharacter | undefined {
	const playersCharacters = getPlayersCharacters(player, characters);
	if (playersCharacters.length === 0) {
		return undefined;
	}
	if (player.defaultCharacter === undefined) {
		return playersCharacters[0];
	}
	return (
		playersCharacters.find((character) => character.name === player.defaultCharacter) ??
		playersCharacters[0]
	);
}

/**
 * React hook for getting the appropriate player {@link Profile}
 *
 * @param player - The signed-in user.
 * @param signedInCharacter - The user's session character. If `undefined`, will use default
 * character.
 */
export function useProfile(
	player: Player,
	signedInCharacter: string | undefined,
	campaignsMap: SharedCampaignsMap,
	pcMap: SharedPlayerCharactersMap,
	dmMode: boolean,
): Profile {
	const playersCharacters = usePlayersCharacters(player, pcMap);
	const sessionCharacter =
		signedInCharacter ?? getPlayersDefaultCharacter(player, playersCharacters)?.name;
	const campaign = useCampaign(sessionCharacter, campaignsMap);
	return new Profile(player, playersCharacters, sessionCharacter, campaign, dmMode);
}

function sortCalendarEvents(events: CalendarEvent[]): CalendarEvent[] {
	return sortArray(events, (a, b) => compareDates(dateFromEvent(a), dateFromEvent(b)));
}

/**
 * React hook for getting the list of {@link CalendarEvent}s from the events shared map.
 */
export function useEvents(eventsMap: SharedCalendarEventsMap): CalendarEvent[] {
	return sortCalendarEvents(useSharedMapContentsAsArray<CalendarEvent>(eventsMap));
}

/**
 * React hook for getting the list of {@link CalendarEvent}s from the events shared map, filtered
 * down to only those known to the provided profile.
 */
export function useKnownEvents(
	profile: Profile,
	eventsMap: SharedCalendarEventsMap,
): CalendarEvent[] {
	const allEvents = useEvents(eventsMap);
	return profile.getKnownEvents(allEvents);
}
