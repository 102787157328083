import { type Character, getHomeworldLinkUrl, getSpeciesLinkUrl } from '@datapad/interfaces';
import { Link } from '@mui/material';
import React from 'react';

const unknownString = 'Unknown';

/**
 * Renders a Wookieepedia link for the specified character's species if one was provided
 */
export function renderSpeciesLink(character: Character): React.ReactElement | string {
	const speciesLink = getSpeciesLinkUrl(character);

	return character.species ? (
		<Link href={speciesLink} target="_blank" rel="noopener noreferrer">
			{character.species}
		</Link>
	) : (
		unknownString
	);
}

/**
 * Renders a Wookieepedia link for the character's homeworld if one was specified
 */
export function renderHomeworldLink(character: Character): React.ReactElement | string {
	const homeworldLink = getHomeworldLinkUrl(character);

	return character.homeworld ? (
		<Link href={homeworldLink} target="_blank" rel="noopener noreferrer">
			{character.homeworld}
		</Link>
	) : (
		unknownString
	);
}
