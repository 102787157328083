import { BooleanForm } from '@datapad/form-components';
import { PlayerKind } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import { Close } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { ProfileContext } from '../../../../utilities';
import { menuLabelStyle, menuLabelTypographyVariant } from './Constants';

/**
 * {@link MenuHeader} input props.
 */
export interface MenuHeaderProps {
	/**
	 * Hides (collapses) the menu view.
	 */
	hideMenu(): void;
}

/**
 * Datapad menu header.
 */
export function MenuHeader(props: MenuHeaderProps): React.ReactElement {
	const { hideMenu } = props;

	const { profile, toggleDMMode } = assertNotUndefined(React.useContext(ProfileContext));

	let maybeDMModeFooter: React.ReactElement = <></>;

	// This UI needs to appear for the DM always, regardless of whether or not DM mode is active,
	// since this is the mechanism for toggling that state.
	if (profile.playerKind === PlayerKind.DungeonMaster) {
		maybeDMModeFooter = (
			<div>
				<BooleanForm
					value={profile.dmMode}
					label="DM Mode"
					elementId="datapad-menu-toggle-dm-mode"
					onChange={(newValue) => toggleDMMode?.(newValue ?? false)}
				/>
			</div>
		);
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'space-around',
					width: '100%',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Typography variant={menuLabelTypographyVariant} style={menuLabelStyle}>
						{profile.getUserNicknameOrName()}
					</Typography>
					<Typography variant="body1" style={menuLabelStyle}>
						{'Role: '}
						<i>{profile.playerKind}</i>
					</Typography>
				</div>
				<div>
					<Tooltip title="Close menu">
						<IconButton onClick={hideMenu}>
							<Close />
						</IconButton>
					</Tooltip>
				</div>
			</div>
			{maybeDMModeFooter}
		</div>
	);
}
