/**
 * {@link SetDatapadMenuStateFunction} Redux code
 */

/**
 * Dispatch ID for the {@link SetDatapadMenuStateFunction} action
 */
export const SET_DATAPAD_MENU_STATE = 'SET_DATAPAD_MENU_STATE';

/**
 * Typed dispatch ID for the {@link SetDatapadMenuStateFunction} action
 */
export type SET_DATAPAD_MENU_STATE = typeof SET_DATAPAD_MENU_STATE;

/**
 * {@link SetDatapadMenuStateFunction} action interface
 */
export interface SetDatapadMenuState {
	type: SET_DATAPAD_MENU_STATE;
	menuState: 'open' | 'closed';
}

/**
 * {@link SetDatapadMenuStateFunction} {@link https://redux.js.org/basics/actions#action-creators | Action Creator}
 */
export function setDatapadMenuState(menuState: 'open' | 'closed'): SetDatapadMenuState {
	return {
		type: SET_DATAPAD_MENU_STATE,
		menuState,
	};
}

/**
 * Change menu state.
 */
export type SetDatapadMenuStateFunction = (menuState: 'open' | 'closed') => void;
