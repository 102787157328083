import { cloneMap } from '@datapad/utilities';
import { Add, Delete } from '@mui/icons-material';
import { Card, CardActions, CardHeader, IconButton, List, ListItem, Tooltip } from '@mui/material';
import React from 'react';
import { renderSingleLineTextBox } from './Utilities.js';

/**
 * Map form entry
 */
export interface MapFormEntry {
	key: string;
	value: string;
}

/**
 * Input props for {@link MapForm}
 */
export interface MapFormProps {
	formTitle: string;
	keyLabel?: string;
	valueLabel?: string;
	initialEntries: readonly MapFormEntry[] | undefined;
	/**
	 * Whether or not duplicate keys are allowed in the "map".
	 * @defaultValue `false`.
	 */
	allowDuplicateKeys?: boolean;
	onChange: (newEntries: MapFormEntry[] | undefined) => void;
}

/**
 * {@link MapForm} internal state.
 */
export interface MapFormState {
	entries: Map<number, MapFormEntry>;

	/**
	 * The next key to associate with entries.
	 *
	 * @remarks
	 *
	 * Monotonically increasing.
	 *
	 * Used as a stable React key for list entries.
	 */
	nextKey: number;
}

/**
 * String map form
 */
export class MapForm extends React.Component<MapFormProps, MapFormState> {
	public constructor(props: MapFormProps) {
		super(props);

		const entries = new Map<number, MapFormEntry>();
		if (props.initialEntries !== undefined) {
			props.initialEntries.forEach((value, index) => {
				entries.set(index, value);
			});
		}

		this.state = {
			entries,
			nextKey: entries.size,
		};
	}

	isKeyDuplicated(key: string): boolean {
		const entries = this.state.entries;
		let matchCount = 0;
		for (const entry of entries.values()) {
			if (key.toLocaleLowerCase() === entry.key.toLocaleLowerCase()) {
				matchCount++;
			}
		}

		return matchCount > 1;
	}

	/**
	 * Function to call to update the value of an existing list entry.
	 * @param key - Key of the entry to update.
	 * @param newEntry - New value for the list entry. Will override the existing value.
	 */
	onUpdateEntry(key: number, newEntry: MapFormEntry): void {
		const newEntriesMap = cloneMap(this.state.entries);
		newEntriesMap.set(key, newEntry);
		this.setState({
			...this.state,
			entries: newEntriesMap,
		});

		this.props.onChange([...newEntriesMap.values()]);
	}

	/**
	 * Function to call to delete the list entry at the provided index.
	 * @param key - Key of the entry to delete.
	 */
	onDeleteEntry(key: number): void {
		const newEntriesMap = cloneMap(this.state.entries);
		newEntriesMap.delete(key);
		this.setState({
			...this.state,
			entries: newEntriesMap,
		});

		this.props.onChange([...newEntriesMap.values()]);
	}

	/**
	 * Function to call when a new list entry is to be added.
	 * Pushes an empty string to the end of the list.
	 */
	onAddNewEntry(): void {
		const newEntriesMap = cloneMap(this.state.entries);
		newEntriesMap.set(this.state.nextKey, { key: '', value: '' });
		this.setState({
			...this.state,
			nextKey: this.state.nextKey + 1,
			entries: newEntriesMap,
		});

		this.props.onChange([...newEntriesMap.values()]);
	}

	/**
	 * {@inheritDoc react#React.Component.render}
	 * @override
	 */
	public override render(): React.ReactElement {
		const renderedEditList = this.renderList();
		return (
			<Card>
				<CardHeader title={this.props.formTitle}></CardHeader>
				{renderedEditList}
				<CardActions>{this.renderAddNewButton()}</CardActions>
			</Card>
		);
	}

	private renderAddNewButton(): React.ReactElement {
		return (
			<Tooltip title="Add new entry">
				<IconButton size="small" onClick={() => this.onAddNewEntry()}>
					<Add />
				</IconButton>
			</Tooltip>
		);
	}

	private renderList(): React.ReactElement {
		if (this.state.entries.size === 0) {
			return <></>;
		}

		const renderedChildForms: React.ReactElement[] = [];
		this.state.entries.forEach((entry, key) => {
			renderedChildForms.push(this.renderMapEntryForm(entry, key));
		});

		return <List>{renderedChildForms}</List>;
	}

	private renderMapEntryForm(entry: MapFormEntry, key: number): React.ReactElement {
		const keyIsDuplicated = this.isKeyDuplicated(entry.key);
		const maybeErrorMessage =
			keyIsDuplicated && this.props.allowDuplicateKeys !== true
				? `Duplicate ${this.props.keyLabel}`
				: undefined;
		return (
			<ListItem
				key={`map-form-entry-${key}`}
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					padding: '5px',
				}}
			>
				<div>
					{renderSingleLineTextBox(
						entry.key,
						this.props.keyLabel,
						(newKey) => this.onUpdateEntry(key, { key: newKey, value: entry.value }),

						maybeErrorMessage,
					)}
					{renderSingleLineTextBox(entry.value, this.props.valueLabel, (newValue) =>
						this.onUpdateEntry(key, { key: entry.key, value: newValue }),
					)}
				</div>
				<Tooltip title="Delete">
					<IconButton size="small" onClick={() => this.onDeleteEntry(key)}>
						<Delete />
					</IconButton>
				</Tooltip>
			</ListItem>
		);
	}
}

function hasDuplicatedKeys(entries: MapFormEntry[]): boolean {
	const keySet = new Set<string>();
	for (const entry of entries) {
		if (keySet.has(entry.key.toLocaleLowerCase())) {
			return true;
		} else {
			keySet.add(entry.key.toLocaleLowerCase());
		}
	}
	return false;
}

function hasEmptyKeys(entries: MapFormEntry[]): boolean {
	return entries.some((entry) => entry.key.length === 0);
}

function hasEmptyValues(entries: MapFormEntry[]): boolean {
	return entries.some((entry) => entry.value.length === 0);
}

/**
 * Determines if a list of {@link MapFormEntry}s are valid.
 * I.e.: the list is either undefined or empty,
 * XOR: all keys and values are non-empty, and keys do not collide.
 */
export function areMapFormEntriesValid(
	entries: MapFormEntry[] | undefined,
	allowDuplicateKeys: boolean,
): boolean {
	if (entries === undefined || entries.length === 0) {
		return true;
	}

	if (hasEmptyKeys(entries)) {
		return false;
	}

	if (hasEmptyValues(entries)) {
		return false;
	}

	if (!allowDuplicateKeys && hasDuplicatedKeys(entries)) {
		return false;
	}

	return true;
}
