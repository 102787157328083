import type { CanCreatePlayer, HasPlayers } from '@datapad/common-props';
import type { Player } from '@datapad/interfaces';
import { assertNotUndefined } from '@datapad/utilities';
import { ModalCard } from '@datapad/utility-components';
import { Add } from '@mui/icons-material';
import {
	AppBar,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tooltip,
} from '@mui/material';
import React from 'react';
import { PlayerCreationForm } from './PlayerCreationForm.js';

/**
 * Input props for {@link Toolbar}
 */
interface ToolbarProps extends HasPlayers, CanCreatePlayer {
	/**
	 * The currently selected Player.
	 * If undefined, will select the first player in the list.
	 */
	selectedPlayer: Player | undefined;

	/**
	 * Function to invoke to change player selection.
	 * Undefined =\> refresh selection to default
	 */
	onChangePlayerSelection: (newPlayerSelection: Player | undefined) => void;
}

/**
 * Modal dialogue status
 */
enum ModalStatus {
	None,
	Creating,
}

/**
 * {@link Toolbar} state
 */
interface ToolbarState {
	modalStatus: ModalStatus;
}

/**
 * Profile app toolbar
 */
export class Toolbar extends React.Component<ToolbarProps, ToolbarState> {
	public constructor(props: ToolbarProps) {
		super(props);
		this.state = {
			modalStatus: ModalStatus.None,
		};
	}

	private setModalStatus(modalStatus: ModalStatus): void {
		this.setState({
			...this.state,
			modalStatus,
		});
	}

	public override render(): React.ReactElement {
		const maybeModal = (
			<EditingModal
				modalStatus={this.state.modalStatus}
				onAddNewPlayer={(player) => {
					this.props.createPlayer(player);
					this.setModalStatus(ModalStatus.None);
				}}
				onCancel={() => this.setModalStatus(ModalStatus.None)}
			/>
		);
		return (
			<>
				{maybeModal}
				<AppBar
					id="profile-toolbar-div"
					position="static"
					color="default"
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						paddingTop: '7px',
						paddingBottom: '2px',
					}}
				>
					<div
						id="profile-toolbar-filters"
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<PlayerSelectionDropDown {...this.props} />
					</div>
					<div
						id="profile-toolbar-buttons"
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Tooltip title="Add new player">
							<IconButton
								onClick={() => {
									this.setModalStatus(ModalStatus.Creating);
								}}
							>
								<Add />
							</IconButton>
						</Tooltip>
					</div>
				</AppBar>
			</>
		);
	}
}

/**
 * Player selection drop-down for the toolbar
 */
function PlayerSelectionDropDown(props: ToolbarProps): React.ReactElement {
	const { players, selectedPlayer, onChangePlayerSelection } = props;

	if (players.length === 0) {
		return <></>;
	}

	const playerSelectionOptions: React.ReactNode[] = [];
	players.forEach((player) => {
		playerSelectionOptions.push(
			<MenuItem key={`player-selection-option-${player.userName}`} value={player.userName}>
				{player.userName}
			</MenuItem>,
		);
	});

	/**
	 * Gets the matching {@link Player} from the players list based on its {@link Player.userName}.
	 * Should never fail to find a match since the form the string is coming from is a drop-down
	 */
	function getPlayerFromUserName(playerUserName: string): Player {
		return assertNotUndefined(players.find((player) => player.userName === playerUserName));
	}

	return (
		<div
			style={{
				height: '100%',
				minWidth: '170px', // For the little carrot
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				paddingLeft: '5px',
				paddingRight: '5px',
				textAlign: 'left',
			}}
		>
			<FormControl variant="outlined" size="small">
				<InputLabel id="player-selection-label">Character Selection</InputLabel>
				<Select
					id="player-selection-select"
					labelId="player-selection-label"
					label="Player Selection"
					value={selectedPlayer?.userName}
					onChange={(event) => {
						const player = getPlayerFromUserName(event.target.value as string);
						onChangePlayerSelection(player);
					}}
					variant="outlined"
				>
					{playerSelectionOptions}
				</Select>
			</FormControl>
		</div>
	);
}

interface EditingModalProps {
	modalStatus: ModalStatus;
	onCancel: () => void;
	onAddNewPlayer: (player: Player) => void;
}

/**
 * Editing modal dialogue.
 * Will be empty if the specified {@link ModalStatus} is {@link ModalStatus.None}.
 */
function EditingModal(props: EditingModalProps): React.ReactElement {
	let renderedDialogue: React.ReactElement | undefined;

	switch (props.modalStatus) {
		case ModalStatus.None:
			renderedDialogue = undefined;
			break;
		case ModalStatus.Creating:
			renderedDialogue = (
				<PlayerCreationForm onSubmit={props.onAddNewPlayer} onCancel={props.onCancel} />
			);
			break;
		default:
			throw new Error(`Unrecognized ModalStatus value: "${props.modalStatus}".`);
	}

	if (renderedDialogue === undefined) {
		return <></>;
	} else {
		return (
			<ModalCard
				open
				onClose={props.onCancel}
				style={{
					maxHeight: '95%',
				}}
			>
				{renderedDialogue}
			</ModalCard>
		);
	}
}
