import type {
	CanManageCampaigns,
	HasCalendarLink,
	HasCampaigns,
	HasCharacterLink,
} from '@datapad/common-props';
import type { Campaign } from '@datapad/interfaces';
import { urlFormat } from '@datapad/utilities';
import React from 'react';
import { Campaigns as _Campaigns } from './Components/Campaigns.js';
import { MissingCampaign } from './Constants.js';

// TODOs:
// - Track selection as state

/**
 * External input props for {@link Campaigns}
 */
interface CampaignsProps
	extends HasCampaigns,
		CanManageCampaigns,
		HasCharacterLink,
		HasCalendarLink {
	/**
	 * Current {@link @datapad/interfaces#Campaign} selection (if any).
	 * URL formatted {@link @datapad/interfaces#Campaign.name}.
	 */
	campaignSelection: string | undefined;

	/**
	 * Changes the {@link @datapad/interfaces#Campaign} selection to the one specified.
	 * `undefined` =\> default selection.
	 */
	changeCampaignSelection(campaign: Campaign | undefined): void;
}

/**
 * Campaigns app
 */
export function Campaigns(props: CampaignsProps): React.ReactElement {
	const {
		campaigns,
		campaignSelection,
		changeCampaignSelection,
		createCampaign,
		deleteCampaign,
		editCampaign,
		handleCharacterLink,
		handleCalendarLink,
	} = props;

	let selectedCampaign: Campaign | MissingCampaign | undefined;
	if (campaignSelection === undefined) {
		selectedCampaign = campaigns.length === 0 ? undefined : campaigns[0];
	} else {
		selectedCampaign =
			campaigns.find((campaign) => urlFormat(campaign.name) === campaignSelection) ??
			MissingCampaign;
	}

	/**
	 * Adds the new campaign and selects it
	 */
	function onAddCampaign(campaign: Campaign): void {
		createCampaign(campaign);
		changeCampaignSelection(campaign);
	}

	/**
	 * Deletes an existing campaign
	 */
	function onDeleteCampaign(campaign: Campaign): void {
		deleteCampaign(campaign);

		if (
			selectedCampaign === undefined ||
			selectedCampaign === MissingCampaign ||
			selectedCampaign.name === campaign.name
		) {
			changeCampaignSelection(undefined);
		}
	}

	return (
		<_Campaigns
			campaigns={campaigns}
			selectedCampaign={selectedCampaign}
			changeCampaignSelection={changeCampaignSelection}
			handleCharacterLink={handleCharacterLink}
			handleCalendarLink={handleCalendarLink}
			createCampaign={onAddCampaign}
			deleteCampaign={onDeleteCampaign}
			editCampaign={editCampaign}
		/>
	);
}
