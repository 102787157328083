import { assertNotUndefined } from '@datapad/utilities';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { DataEntry, type DataEntryProps } from './DataEntry.js';

/**
 * Input props for {@link ObjectDropDownEntry}
 */
export interface ObjectDropDownEntryProps<T> extends DataEntryProps<T> {
	/**
	 * {@inheritDoc ObjectDropDownEntry.required}
	 */
	required: boolean;

	/**
	 * {@inheritDoc ObjectDropDownEntry.options}
	 */
	options: T[];

	/**
	 * {@inheritDoc ObjectDropDownEntry.getOptionKey}
	 */
	getOptionKey(option: T): string;

	/**
	 * {@inheritDoc ObjectDropDownEntry.renderOption}
	 */
	renderOption(option: T): React.ReactElement;
}

/**
 * Drop-down {@link DataEntry} for arbitrary objects.
 */
export class ObjectDropDownEntry<T> extends DataEntry<T> {
	/**
	 * Indicates whether or not a value is required to be submitted for this entry.
	 */
	public readonly required: boolean;

	/**
	 * Valid options for the dropdown
	 */
	public readonly options: T[];

	/**
	 * Gets a unique selection key for each drop-down option.
	 */
	public readonly getOptionKey: (option: T) => string;

	/**
	 * Defines how each drop-down option will be rendered in the form.
	 */
	public readonly renderOption: (option: T) => React.ReactElement;

	public constructor(props: ObjectDropDownEntryProps<T>) {
		super(props);

		if (props.initialValue && !props.options.includes(props.initialValue)) {
			throw new Error(
				`Specified initial value is not valid for drop-down options: "${props.initialValue}".`,
			);
		}
		if (props.options.length === 0) {
			throw new Error('Must specify non-empty set of drop-down options.');
		}

		this.required = props.required;
		this.options = props.options;
		this.getOptionKey = props.getOptionKey;
		this.renderOption = props.renderOption;
	}

	public errorMessage(value: T | undefined): string | undefined {
		if (this.required && value === undefined) {
			return 'Option is required';
		} else if (value !== undefined && !this.options.includes(value)) {
			return 'Invalid selection';
		}
		return undefined;
	}

	public hasValueChanged(value: T | undefined): boolean {
		return value !== this.initialValue;
	}

	/**
	 * {@inheritDoc DataEntry.renderForm}
	 */
	public renderForm(
		currentValue: T | undefined,
		onChange: (newValue: T | undefined) => void,
	): React.ReactElement {
		const error = !this.isValueValid(currentValue);
		const errorMessage = this.errorMessage(currentValue);

		const objectMap = new Map<string, T>();
		this.options.forEach((option) => {
			objectMap.set(this.getOptionKey(option), option);
		});

		const menuOptions: React.ReactNode[] = [];

		if (!this.required) {
			menuOptions.push(
				<MenuItem key={`${this.elementId}-option-none`} value={undefined}>
					<i>None</i>
				</MenuItem>,
			);
		}

		this.options.forEach((option, index) => {
			menuOptions.push(
				<MenuItem
					key={`${this.elementId}-option-${index}`}
					value={this.getOptionKey(option)}
				>
					{this.renderOption(option)}
				</MenuItem>,
			);
		});

		const labelId = `${this.elementId}-label`;

		return (
			<FormControl variant="outlined" size="small" fullWidth disabled={this.locked}>
				<InputLabel id={labelId}>{this.label}</InputLabel>
				<Select
					id={this.elementId}
					labelId={labelId}
					label={this.label}
					value={currentValue === undefined ? undefined : this.getOptionKey(currentValue)}
					onChange={(event) => {
						const key = event.target.value as string;
						const value = assertNotUndefined(objectMap.get(key));
						onChange(value);
					}}
					error={error}
					variant="outlined"
					fullWidth
					style={{
						minWidth: '120px',
					}}
				>
					{menuOptions}
				</Select>
				<FormHelperText>{errorMessage}</FormHelperText>
			</FormControl>
		);
	}
}
