import { TextField } from '@mui/material';
import React, { type ChangeEvent } from 'react';
import { DataEntry, type DataEntryProps } from './DataEntry.js';

/**
 * Input props for {@link StringEntry}
 */
export interface StringEntryProps extends DataEntryProps<string> {
	/**
	 * {@inheritDoc StringEntry.multiLine}
	 */
	multiLine: boolean;

	/**
	 * {@inheritDoc StringEntry.required}
	 */
	required: boolean;

	/**
	 * {@inheritDoc StringEntry.placeholder}
	 */
	placeholder?: string;
}

/**
 * DataEntry for strings.
 */
export class StringEntry extends DataEntry<string> {
	/**
	 * Indicates whether or not a value is required to be submitted for this entry.
	 */
	public readonly required: boolean;

	/**
	 * Indicates that the entry form should allow for multiple lines.
	 */
	public readonly multiLine: boolean;

	/**
	 * Placeholder text to display in the textbox.
	 * Optional.
	 */
	public readonly placeholder?: string;

	public constructor(props: StringEntryProps) {
		super(props);
		this.required = props.required;
		this.multiLine = props.multiLine;
		this.placeholder = props.placeholder;
	}

	public errorMessage(value: string | undefined): string | undefined {
		if (this.required && (value === undefined || value.length === 0)) {
			return 'Value is required';
		}
		return undefined;
	}

	public hasValueChanged(value: string | undefined): boolean {
		return value !== this.initialValue;
	}

	/**
	 * {@inheritDoc DataEntry.renderForm}
	 */
	public renderForm(
		currentValue: string | undefined,
		onChange: (newValue: string | undefined) => void,
	): React.ReactElement {
		const error = !this.isValueValid(currentValue);
		const errorMessage = this.errorMessage(currentValue);
		return (
			<TextField
				defaultValue={currentValue}
				label={this.label}
				id={this.elementId}
				variant="outlined"
				multiline={this.multiLine}
				error={error}
				helperText={errorMessage}
				placeholder={this.placeholder}
				onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
					// Set value back to undefined if we get an empty string as the new value
					const newValue =
						event.target.value.length === 0 ? undefined : event.target.value;
					onChange(newValue);
				}}
				fullWidth={this.multiLine}
				disabled={this.locked}
			/>
		);
	}
}
